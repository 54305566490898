import React, { useEffect, useState } from 'react';
import {
  TextField,
  IconButton,
  Menu,
  Button,
  Box,
  Grid,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';


const SearchBarWithDropdown = ({
                                 searchValue,
                                 setSearchValue,
                                 villageName,
                                 setVillageName,
                                 food,
                                 setFood,
                                 attire,
                                 setAttire,
                                 ornaments,
                                 setOrnaments,
                                 festivals,
                                 setFestivals,
                               }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleSearchChange = (event) => {
    let filters = JSON.parse(localStorage.getItem("filters"));
    filters = {
      ...filters,
      searchValue: event.target.value
    }
    localStorage.setItem("filters", JSON.stringify(filters))
    setSearchValue(event.target.value);
  };

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCheckboxChange = (setter) => (event) => {
    setter(event.target.checked);
  };

  return (
    <div className="row col-12 g-0 mt-3">
      <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
        <input
          type="search"
          onChange={handleSearchChange}
          value={searchValue}
          className="search-bar shadow-sm"
          placeholder="Search..."
          // disabled={isSearchDisabled}
        />
        {/* <TextField
          variant="outlined"
          placeholder="Search..."
          value={searchValue}
          onChange={handleSearchChange}
          fullWidth
          InputProps={{
            endAdornment: (
              <IconButton type="submit" disabled={isSearchDisabled}>
                <SearchIcon />
              </IconButton>
            ),
          }}
        /> */}

        <IconButton onClick={handleFilterClick}>
          <FilterListIcon />
        </IconButton>
      </div>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: "80vh",
            width: "400px",
          },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={villageName}
                    onChange={handleCheckboxChange(setVillageName)}
                    // disabled={isSearchDisabled}
                  />
                }
                label="Search by Village Name"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={food}
                    onChange={handleCheckboxChange(setFood)}
                    // disabled={isSearchDisabled}
                  />
                }
                label="Search by Food"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={attire}
                    onChange={handleCheckboxChange(setAttire)}
                    // disabled={isSearchDisabled}
                  />
                }
                label="Search by Dresses"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={ornaments}
                    onChange={handleCheckboxChange(setOrnaments)}
                    // disabled={isSearchDisabled}
                  />
                }
                label="Search by Ornaments"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={festivals}
                    onChange={handleCheckboxChange(setFestivals)}
                    // disabled={isSearchDisabled}
                  />
                }
                label="Search by Festivals"
              />
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Button variant="text" onClick={handleClose}>
                  Close
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Menu>
    </div>
  );
};

export default SearchBarWithDropdown;