import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import MyDesktop from "../../Components/MyComponents/MyDesktop";
import IndibadgesImg from "../../Assets/img/General/indibadges.svg";
import ExploreImg from "../../Assets/img/General/explore.svg";
import MyLayout from "../../Components/MyLayout";
import MyMobile from "../../Components/MyComponents/MyMobile";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import LockImg from "./svgList/lock.svg";
import firebase from "../../../firebase";
import "./index.css";
import "react-placeholder/lib/reactPlaceholder.css";
import axios from "axios";

const IndibageCard = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  background-size: cover;
  background-position: center;
`;

const Index = () => {
  useEffect(() => {
    getuserVillageBadges();
  }, []);

  const [userCollectBadge, setUserCollectBadge] = useState();
  const [userRecentBadge, setUserRecentBadge] = useState();
  const [loading, setLoading] = useState(false);
  const [recentLoading, setRecentLoading] = useState(true);
  const [ornamentBadges, setOrnamentBadges] = useState([]);
  const [artBadges, setArtBadges] = useState([]);

  const [ornamentBadgesUser, setOrnamentBadgesUser] = useState([]);
  const [artBadgesUser, setArtBadgesUser] = useState([]);
  const [badgesToShow, setBadgesToShow] = useState();
  const [allUser, setAllUser] = useState();
  const [lockArt, setLockArt] = useState();
  const [LockOrna, setLockOrna] = useState();

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 3,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 767, min: 350 },
      items: 4,
      slidesToSlide: 1,
    },
    small: {
      breakpoint: { max: 350, min: 280 },
      items: 3,
      slidesToSlide: 1,
    },
  };

  useEffect(() => {
    getAllIndiBadges();
  }, [ornamentBadgesUser, artBadgesUser, userRecentBadge]);

  const getuserVillageBadges = async () => {
    let userId = JSON.parse(localStorage.getItem("user")).uid;
    console.log(userId);
    firebase
      .firestore()
      .collection("user_data")
      .doc(userId)
      .get()
      .then((u_data) => {
        if (u_data.exists) {
          if (
            u_data?.data()?.recentBadges &&
            u_data?.data()?.recentBadges?.length
          ) {
            const promisesArrayOrna = [];
            const promisesArrayArt = [];
            let temp = u_data?.data()?.recentBadges;
            let all = u_data?.data()?.recentBadges;
            let temps = [];
            let orna = [];
            let art = [];
            setAllUser(temp);
            temp.filter((item) => {
              if (item.category == "ornaments") {
                orna.push(item.imageID);
              }
              if (item.category == "traditional_art") {
                art.push(item.imageID);
              }
            });
            //fetch all IDs
            for (let counter = 0; counter < orna?.length; counter++) {
              promisesArrayOrna.push(getData(orna[counter]));
            }
            for (let counter = 0; counter < art?.length; counter++) {
              promisesArrayArt.push(getData(art[counter]));
            }
            for (let counter = 0; counter < all?.length; counter++) {
              temps.push(
                getData(u_data.data()?.recentBadges[counter]?.imageID)
              );
            }
            Promise.allSettled(temps).then((rece) => {
              const recent = [];
              rece.map((item) => {
                recent.push({
                  image: item.value,
                });
              });
              setBadgesToShow(recent);
              setUserRecentBadge(recent);
            });

            Promise.allSettled(promisesArrayArt).then((artResponse) => {
              const tempArt = [];
              artResponse?.map((item) => {
                tempArt.push({
                  name: item?.name,
                  isShown: true,
                  image: item.value,
                });
              });
              setLoading(true); //using !loading when showing data
              setArtBadgesUser(tempArt);
            });
            Promise.allSettled(promisesArrayOrna).then((ornamentsResponse) => {
              const tempOrnaments = [];
              ornamentsResponse?.map((item) => {
                tempOrnaments.push({
                  name: item?.name,
                  isShown: true,
                  image: item.value,
                });
              });
              setLoading(true); //using !loading when showing data

              setOrnamentBadgesUser(tempOrnaments);
            });
          } else {
            setBadgesToShow([]);
            getAllIndiBadges();
          }
        } else {
          setRecentLoading(true);
        }
      });
  };

  const getData = async (id, type) => {
    const url = `https://asia-south1-mgmd-356213.cloudfunctions.net/getSignedURLFromCF?url=https://d296j4bbogbdom.cloudfront.net/images/${id}`;
    let data = "";
    await axios.get(url).then(function (response) {
      data = response?.data?.data;
    });
    return data;
  };

  const getAllIndiBadges = () => {
    firebase
      .firestore()
      .collection("village_badges")
      // .limit(100) //there will have to be an eventual limit here if the number of entries increase a lot
      .limit(270)
      .get()
      .then((snapshot) => {
        const promiseArrayOrnaments = []; // array to store getData promises
        const promiseArrayArt = []; // array to store getData promises

        snapshot.forEach((doc) => {
          const currentDoc = doc.data();
          //get all the values from ornaments
          for (
            let counter = 0;
            counter < currentDoc.category.ornaments.length;
            counter++
          ) {
            promiseArrayOrnaments.push(
              getData(currentDoc.category.ornaments[counter].media_name)
            );
          }
          //get all the values from traditional_art
          for (
            let counter = 0;
            counter < currentDoc.category.traditional_art.length;
            counter++
          ) {
            console.log(currentDoc);
            promiseArrayArt.push(
              getData(currentDoc.category.traditional_art[counter].media_name)
            );
          }
        });

        Promise.allSettled(promiseArrayArt).then((artResponse) => {
          Promise.allSettled(promiseArrayOrnaments).then(
            (ornamentsResponse) => {
              const tempOrnaments = [];
              const tempArt = [];

              artResponse.map((item) => {
                tempArt.push({
                  name: item.name,
                  image: item.value,
                });
              });

              ornamentsResponse.map((item) => {
                tempOrnaments.push({
                  name: item.name,
                  image: item.value,
                });
              });
              setLoading(true); //using !loading when showing data
              const data = [...ornamentBadgesUser, ...tempOrnaments];
              const dataart = [...artBadgesUser, ...tempArt];
              setOrnamentBadges(data);
              setArtBadges(dataart);
            }
          );
        });
      })
      .catch((err) => console.log("err in fetching all indibadges: ", err));
  };
  useEffect(() => {
    if (badgesToShow && badgesToShow.length > 0) {
      setRecentLoading(false);
    } else {
      if (badgesToShow && Array.isArray(badgesToShow)) {
        setRecentLoading(false);
      }
    }
  }, [badgesToShow]);
  useEffect(() => {
    // recednt badge form user table
    const ArrayArtLock = [];
    const ArrayOrnaLock = [];

    allUser?.forEach((i) => {
      // ArrayArtLock.push(i?.imageID)
      if (i?.category == "ornaments") {
        ArrayOrnaLock.push(i?.imageD);
      } else {
        ArrayArtLock?.push(i?.imageID);
      }
    });
    setLockOrna(ArrayOrnaLock);
    setLockArt(ArrayArtLock);
  }, [allUser]);

  let showCount = 15; // show first 15 badges of art by default

  if (artBadges.length > 15 && artBadges.length <= 30) {
    showCount = 30; // show first 30 badges if there are more than 15 but less than or equal to 30 badges
  } else if (artBadges.length > 30) {
    showCount = artBadges.length; // show all badges if there are more than 30 badges
  }
  let showCountorna = 15; // show first 15 badges of ornament by default
  if (showCountorna.length > 15 && showCountorna.length <= 30) {
    showCountorna = 30; // show first 30 badges if there are more than 15 but less than or equal to 30 badges
  } else if (showCountorna.length > 30) {
    showCountorna = showCountorna.length; // show all badges if there are more than 30 badges
  }
  console.log(recentLoading);
  return (
    <>
      <MyLayout>
        <MyDesktop>
          <div className="d-flex pb-3">
            <>
              <>
                <div className="col-md-6 _dialog_overlay">
                  <div className="p-3">
                    <h5>My MGMD Smriti</h5>
                  </div>
                  {recentLoading ? (
                    <div className="recentLoading">Loading....</div>
                  ) : (
                    <>
                      {badgesToShow && badgesToShow.length > 0 ? (
                        <div className="bg-white th-br-16 p-3 scrollbar">
                          <div className="row px-2">
                            {badgesToShow?.map((e) => (
                              <div className="col-4 px-2">
                                <div className="pb-3">
                                  <IndibageCard
                                    className={`th-br-12`}
                                    style={{
                                      backgroundImage: `url(${e?.image})`,
                                    }}
                                  ></IndibageCard>
                                </div>
                                <div></div>
                              </div>
                            ))}
                          </div>
                        </div>
                      ) : (
                        <div className="row d-flex justify-content-center">
                          <div className="bg-white th-br-16 p-3">
                            <div className="col-md-12 col-lg-12 p-md-5 p-3 align-self-center">
                              <div className="text-center">
                                <div className="w-50 mx-auto">
                                  <IndibageCard
                                    className="th-br-12"
                                    style={{
                                      backgroundImage: `url(${IndibadgesImg})`,
                                    }}
                                  />
                                </div>

                                <h4 className="pt-3 th-14">
                                  No MGMDBadges won yet.
                                </h4>
                                <p className="th-10">
                                  Visit villages to earn KMs and win badges.
                                </p>
                                <Link to="/explore">
                                  <div className="mt-3 th-12 pt-3">
                                    <span className="th-btn-green th-pointer border-0 th-br-3 px-3 py-2">
                                      <span>
                                        <img
                                          src={ExploreImg}
                                          width="12px"
                                          alt="..."
                                        />
                                      </span>
                                      <span className="pl-2">Explore</span>
                                    </span>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
                {!loading ? (
                  <div className="col-md-6">
                    <div className="recentLoading badges">Loading....</div>
                  </div>
                ) : (
                  <div className="col-md-6 web-part-two ml-5">
                    <div className="p-2">
                      <h5>
                        <b>Unlock more</b>
                      </h5>
                    </div>
                    <div className=" scrollbar back">
                      <div className="bg-white "></div>

                      {/* ornma */}
                      <div className="pt-2 ">
                        <div className="pb-1 px-2 h-20 fw-100 heading">
                          Ornament
                        </div>
                        {ornamentBadges && (
                          <Carousel
                            swipeable={false}
                            responsive={responsive}
                            infinite={true}
                            keyBoardControl={true}
                            customTransition="all .5"
                            transitionDuration={500}
                            containerClass="carousel-container"
                            dotListClass="custom-dot-list-style"
                            itemClass="carousel-item-padding-0"
                          >
                            {ornamentBadges
                              .slice(0, showCountorna)
                              .map((item) => {
                                return (
                                  <div className="px-2 col-3" key={item}>
                                    <img
                                      src={item.image}
                                      alt=""
                                      className={`${
                                        item?.isShown && "isvisible"
                                      }  ${
                                        userCollectBadge?.category?.ornaments?.name.includes(
                                          item.name
                                        )
                                          ? "artimage mobile"
                                          : "artimage"
                                      }`}
                                      style={{
                                        height: "150px",
                                        width: "150px",
                                        borderRadius: "10px",
                                        display: "flex",
                                      }}
                                    />
                                    {userCollectBadge?.category?.ornaments?.name.includes(
                                      item.name
                                    ) ? (
                                      ""
                                    ) : "artimage" && !item?.isShown ? (
                                      <img
                                        src={LockImg}
                                        alt="..."
                                        className="lock-img"
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                );
                              })}
                          </Carousel>
                        )}
                      </div>

                      <div className="pt-2 ">
                        <div className="pb-1 px-2 h-20 fw-100 heading">
                          Art And Craft
                        </div>
                        {artBadges && (
                          <Carousel
                            swipeable={false}
                            responsive={responsive}
                            infinite={true}
                            keyBoardControl={true}
                            customTransition="all .5"
                            transitionDuration={500}
                            containerClass="carousel-container"
                            dotListClass="custom-dot-list-style"
                            itemClass="carousel-item-padding-0"
                          >
                            {artBadges.slice(0, showCount).map((item) => {
                              return (
                                <div className="px-2 col-3" key={item}>
                                  <img
                                    src={item.image}
                                    alt=""
                                    className={`${
                                      item?.isShown && "isvisible"
                                    } ${
                                      userCollectBadge?.category?.traditional_art?.name.includes(
                                        item.name
                                      )
                                        ? "artimage mobile"
                                        : "artimage"
                                    }`}
                                    style={{
                                      height: "150px",
                                      width: "150px",
                                      borderRadius: "10px",
                                      display: "flex",
                                    }}
                                  />
                                  {userCollectBadge?.category?.traditional_art?.name.includes(
                                    item.name
                                  ) ? (
                                    ""
                                  ) : "artimage" && !item?.isShown ? (
                                    <img
                                      src={LockImg}
                                      alt="..."
                                      className="lock-img"
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              );
                            })}
                          </Carousel>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </>
            </>
          </div>
        </MyDesktop>

        <MyMobile>
          <div className="badges scroll">
            <>
              <div className="col-md-12 first-part override-first-part">
                <div className="p-3">
                  <h5>My MGMD Smriti</h5>
                </div>

                {badgesToShow && badgesToShow.length > 0 ? (
                  <div className="bg-white th-br-16 p-3 pt-4 pb-2 scrollbar">
                    <div className="row px-2">
                      {badgesToShow?.map((e) => (
                        <div className="col-4 px-2">
                          <div className="pb-3">
                            <IndibageCard
                              className={` th-br-10`}
                              style={{
                                backgroundImage: `url(${e?.image})`,
                              }}
                            ></IndibageCard>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div
                    className="row d-flex justify-content-center"
                    style={{ width: "100%", margin: "auto" }}
                  >
                    <div className="bg-white th-br-16 p-3">
                      <div className="col-md-12 col-lg-12 p-md-5 p-3 align-self-center">
                        <div className="text-center">
                          <div className="w-50 mx-auto">
                            <IndibageCard
                              className="th-br-12"
                              style={{
                                backgroundImage: `url(${IndibadgesImg})`,
                              }}
                            />
                          </div>

                          <h4 className="pt-3 th-14">No Indibadges won yet.</h4>
                          <p className="th-10">
                            Visit villages to earn KMs and win badges.
                          </p>
                          <Link to="/explore">
                            <div className="mt-3 th-12 pt-3">
                              <span className="th-btn-green th-pointer border-0 th-br-3 px-3 py-2">
                                <span>
                                  <img
                                    src={ExploreImg}
                                    width="12px"
                                    alt="..."
                                  />
                                </span>
                                <span className="pl-2">Explore</span>
                              </span>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="col-md-12 second-part bg-white th-br-16 my-3 p-1">
                <div className="p-2">
                  <h5>
                    <b>Unlock more</b>
                  </h5>
                </div>
                <div>
                  <div className="pt-2 ">
                    <div className="pb-1 px-2 h-20 fw-100 heading">
                      Ornament
                    </div>
                    {ornamentBadges && (
                      <Carousel
                        swipeable={false}
                        responsive={responsive}
                        infinite={true}
                        keyBoardControl={true}
                        customTransition="all .5"
                        transitionDuration={500}
                        containerClass="carousel-container"
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-0"
                      >
                        {ornamentBadges.slice(0, showCountorna).map((item) => {
                          return (
                            <div className="px-2 col-3">
                              <img
                                src={item.image}
                                alt=""
                                className={`${item?.isShown && "isvisible"}  ${
                                  userCollectBadge?.category?.ornaments?.name.includes(
                                    item.name
                                  )
                                    ? "artimage mobile"
                                    : "artimage"
                                }`}
                                style={{
                                  height: "150px",
                                  width: "150px",
                                  borderRadius: "10px",
                                  display: "flex",
                                }}
                              />
                              {userCollectBadge?.category?.ornaments?.name.includes(
                                item.name
                              ) ? (
                                ""
                              ) : "artimage" && !item?.isShown ? (
                                <img
                                  src={LockImg}
                                  alt="..."
                                  className="lock-img"
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          );
                        })}
                      </Carousel>
                    )}
                  </div>

                  <div className="pt-2 ">
                    <div className="pb-1 px-2 h-20 fw-100 heading">
                      Art And Craft
                    </div>
                    {artBadges && (
                      <Carousel
                        swipeable={false}
                        responsive={responsive}
                        infinite={true}
                        keyBoardControl={true}
                        customTransition="all .5"
                        transitionDuration={500}
                        containerClass="carousel-container"
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-0"
                      >
                        {artBadges.slice(0, showCount).map((item) => {
                          return (
                            <div className="px-2 col-3">
                              <img
                                src={item.image}
                                alt=""
                                className={`${item?.isShown && "isvisible"} ${
                                  userCollectBadge?.category?.traditional_art?.name.includes(
                                    item.name
                                  )
                                    ? "artimage mobile"
                                    : "artimage"
                                }`}
                                style={{
                                  height: "150px",
                                  width: "150px",
                                  borderRadius: "10px",
                                  display: "flex",
                                }}
                              />
                              {userCollectBadge?.category?.traditional_art?.name.includes(
                                item.name
                              ) ? (
                                ""
                              ) : "artimage" && !item?.isShown ? (
                                <img
                                  src={LockImg}
                                  alt="..."
                                  className="lock-img"
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          );
                        })}
                      </Carousel>
                    )}
                  </div>
                </div>
              </div>
            </>
          </div>
        </MyMobile>
      </MyLayout>
    </>
  );
};

export default Index;
