import { useState, useRef, useEffect } from "react";
import { v4 as uuid } from "uuid";
import styled from "styled-components";
import { Link, withRouter } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import firebase from "../../../firebase";
import MyDesktop from "../../Components/MyComponents/MyDesktop";
import MyMobile from "../../Components/MyComponents/MyMobile";
import MyLayout from "../../Components/MyLayout/index";
import { Alert, Offcanvas } from "react-bootstrap";
import SuggestEdit from "../SuggestEdit";
import MenuSelectAddVillage from "./MenuSelectAddVillage";
import AWS from "aws-sdk";
import MinusIcon from "../../Assets/img/General/MinusIcon.svg";
import PlusIcon from "../../Assets/img/General/plus-icon-custom.svg";
import MenuIcon from "../../Assets/img/icons/menu.svg";
import "react-image-lightbox/style.css";
import "./index.scss";
import "./AddVillage.scss";
import "./index.css";

const VillageDetails = (props) => {
  const s3 = new AWS.S3();
  AWS.config.update({
    accessKeyId: "AKIA6JXYGZ42T6UQGT4S",
    secretAccessKey: "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",
    region: "ap-south-1",
  });
  const [allStates, setAllStates] = useState([
    {
      label: "Andhra Pradesh",
      value: "Andhra Pradesh",
    },
    {
      label: "Arunachal Pradesh",
      value: "Arunachal Pradesh",
    },
    {
      label: "Assam",
      value: "Assam",
    },
    {
      label: "Bihar",
      value: "Bihar",
    },
    {
      label: "Chhattisgarh",
      value: "Chhattisgarh",
    },
    {
      label: "Goa",
      value: "Goa",
    },
    {
      label: "Gujarat",
      value: "Gujarat",
    },
    {
      label: "Haryana",
      value: "Haryana",
    },
    {
      label: "Himachal Pradesh",
      value: "Himachal Pradesh",
    },
    {
      label: "Jharkhand",
      value: "Jharkhand",
    },
    {
      label: "Karnataka",
      value: "Karnataka",
    },
    {
      label: "Kerala",
      value: "Kerala",
    },
    {
      label: "Madhya Pradesh",
      value: "Madhya Pradesh",
    },
    {
      label: "Maharashtra",
      value: "Maharashtra",
    },
    {
      label: "Manipur",
      value: "Manipur",
    },
    {
      label: "Meghalaya",
      value: "Meghalaya",
    },
    {
      label: "Mizoram",
      value: "Mizoram",
    },
    {
      label: "Nagaland",
      value: "Nagaland",
    },
    {
      label: "Odisha",
      value: "Odisha",
    },
    {
      label: "Punjab",
      value: "Punjab",
    },
    {
      label: "Rajasthan",
      value: "Rajasthan",
    },
    {
      label: "Sikkim",
      value: "Sikkim",
    },
    {
      label: "Tamil Nadu",
      value: "Tamil Nadu",
    },
    {
      label: "Telangana",
      value: "Telangana",
    },
    {
      label: "Tripura",
      value: "Tripura",
    },
    {
      label: "Uttar Pradesh",
      value: "Uttar Pradesh",
    },
    {
      label: "Uttarakhand",
      value: "Uttarakhand",
    },
    {
      label: "West Bengal",
      value: "West Bengal",
    },
    {
      label: "Andaman and Nicobar Islands",
      value: "Andaman and Nicobar Islands",
    },
    {
      label: "Chandigarh",
      value: "Chandigarh",
    },
    {
      label: "Dadra and Nagar Haveli and Daman and Diu",
      value: "Dadra and Nagar Haveli and Daman and Diu",
    },
    {
      label: "Delhi",
      value: "Delhi",
    },
    {
      label: "Lakshadweep",
      value: "Lakshadweep",
    },
    {
      label: "Puducherry",
      value: "Puducherry",
    },
    {
      label: "Ladakh",
      value: "Ladakh",
    },
    {
      label: "Jammu and Kashmir",
      value: "Jammu and Kashmir",
    },
  ]);
  const [allDistricts, setallDistricts] = useState();
  const [allVillage, setallVillage] = useState();

  const [allStatesStore, setAllStatesStore] = useState();
  const [allDistrictsStore, setallDistrictsStore] = useState();
  const [allVillageStore, setallVillageStore] = useState();
  const [selectedValueForStateFilter, setSelectedValueForStateFilter] =
    useState();
  const [selectedValueForDistrictFilter, setSelectedValueForDistrictFilter] =
    useState();
  const [selectedValueForVillageFilter, setSelectedValueForVillageFilter] =
    useState();

  // useEffect(() => {
  //   if (
  //     selectedValueForStateFilter &&
  //     !selectedValueForDistrictFilter &&
  //     !selectedValueForVillageFilter
  //   ) {
  //     getOneVal(selectedValueForStateFilter, "state_name");
  //   }
  //   if (
  //     selectedValueForDistrictFilter &&
  //     !selectedValueForStateFilter &&
  //     !selectedValueForVillageFilter
  //   ) {
  //     getOneVal(selectedValueForDistrictFilter, "district_name");
  //   }
  //   if (
  //     selectedValueForVillageFilter &&
  //     !selectedValueForDistrictFilter &&
  //     !selectedValueForStateFilter
  //   ) {
  //     getOneVal(selectedValueForVillageFilter, "village_name");
  //   }

  //   if (selectedValueForStateFilter && selectedValueForDistrictFilter) {
  //     getTwoVal(
  //       selectedValueForStateFilter,
  //       selectedValueForDistrictFilter,
  //       "state_name",
  //       "district_name"
  //     );
  //   }
  //   if (selectedValueForVillageFilter && selectedValueForDistrictFilter) {
  //     getTwoVal(
  //       selectedValueForVillageFilter,
  //       selectedValueForDistrictFilter,
  //       "village_name",
  //       "district_name"
  //     );
  //   }
  //   if (selectedValueForVillageFilter && selectedValueForStateFilter) {
  //     getTwoVal(
  //       selectedValueForVillageFilter,
  //       selectedValueForStateFilter,
  //       "village_name",
  //       "state_name"
  //     );
  //   }

  //   if (
  //     selectedValueForStateFilter &&
  //     selectedValueForDistrictFilter &&
  //     selectedValueForVillageFilter
  //   ) {
  //     getThreeVal(
  //       selectedValueForStateFilter,
  //       selectedValueForDistrictFilter,
  //       selectedValueForVillageFilter,
  //       "state_name",
  //       "district_name",
  //       "village_name"
  //     );
  //   }

  //   if (
  //     !selectedValueForStateFilter &&
  //     !selectedValueForDistrictFilter &&
  //     !selectedValueForVillageFilter
  //   ) {
  //     getAllVal();
  //   }
  // }, [
  //   selectedValueForStateFilter,
  //   selectedValueForDistrictFilter,
  //   selectedValueForVillageFilter,
  // ]);

  useEffect(async () => {
    if (selectedValueForStateFilter && selectedValueForStateFilter.length > 0) {
      console.log(selectedValueForStateFilter);
      let districts = [];
      await firebase
        .firestore()
        .collection("state_district_detail")
        .where("state_name", "==", selectedValueForStateFilter)
        .get()
        .then(async (docs) => {
          console.log("kkk", docs.size);
          docs.forEach((doc) => {
            districts.push({
              value: doc.data().district_name,
              label: doc.data().district_name,
            });
          });
        });

      setallDistricts(districts);
      setallVillage([]);
    }
  }, [selectedValueForStateFilter]);

  useEffect(async () => {
    if (
      selectedValueForDistrictFilter &&
      selectedValueForDistrictFilter.length > 0
    ) {
      let villages = [];
      await firebase
        .firestore()
        .collection("large_village_list")
        .where("state_name", "==", selectedValueForStateFilter)
        .where("district_name", "==", selectedValueForDistrictFilter)
        .get()
        .then(async (docs) => {
          console.log("kkk", docs.size);
          docs.forEach((doc) => {
            villages.push({
              value: doc.data().village_name,
              label: doc.data().village_name,
            });
          });
        });

      setallVillage(villages);
    }
  }, [selectedValueForDistrictFilter]);

  const getAllVal = () => {
    const db = firebase.firestore().collection("large_village_list");
    db.limit(1500)
      .get()
      .then((docs) => {
        CommonSet(docs);
      });
  };

  const getThreeVal = (
    _valueOne,
    _valueTwo,
    _valueThree,
    _nameOne,
    _nameTwo,
    _nameThree
  ) => {
    const db = firebase.firestore().collection("large_village_list");
    db.where(_nameOne, "==", _valueOne)
      .where(_nameTwo, "==", _valueTwo)
      .where(_nameThree, "==", _valueThree)
      .get()
      .then((docs) => {
        CommonSet(docs);
      });
  };

  const getTwoVal = (_valueOne, _valueTwo, _nameOne, _nameTwo) => {
    const db = firebase.firestore().collection("large_village_list");
    db.where(_nameOne, "==", _valueOne)
      .where(_nameTwo, "==", _valueTwo)
      .get()
      .then((docs) => {
        CommonSet(docs);
      });
  };

  const getOneVal = (_value, _name) => {
    const db = firebase.firestore().collection("large_village_list");
    db.where(_name, "==", _value)
      .get()
      .then((docs) => {
        CommonSet(docs);
      });
  };

  const CommonSet = (docs) => {
    let states = [];
    let districts = [];
    let villages = [];

    docs.forEach((doc) => {
      states.push({
        value: doc.data().state_name,
        label: doc.data().state_name,
      });
      villages.push({
        value: doc.data().village_name,
        label: doc.data().village_name,
      });
      districts.push({
        value: doc.data().district_name,
        label: doc.data().district_name,
      });
    });

    states = commonUniq(states);
    villages = commonUniq(villages);
    districts = commonUniq(districts);

    // setAllStates(states);
    setallDistricts(districts);
    setallVillage(villages);
  };

  const commonUniq = (commonData) => {
    commonData = commonData.filter(
      (v, i, a) => a.findIndex((v2) => v2.value == v.value) === i
    );
    return commonData;
  };

  const [modalShow, setModalShow] = useState(false);
  const [isDisabledMenuSelect, setIsDisbledMenuSelect] = useState(false);
  const [isUpdate, setisUpdate] = useState(false);
  const [imageItem, setImageItem] = useState();
  const [selectedTopic, setSelectedTopic] = useState("culture_profile");
  const [uploadingToFirebase, setUploadingToFirebase] = useState(false);
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [finalSubmit, setFinalSubmit] = useState(false);
  const [villageValid, setVillageValid] = useState(false);
  const [villageAlready, setVillageAlready] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [topicsData, setTopicsData] = useState(false);
  const [isValidName, setIsValidName] = useState(false);
  const [isValidDistrict, setIsValidDistrict] = useState(false);
  const [isValidState, setIsValidState] = useState(false);
  const [submitFirst, setSubmitFirst] = useState(false);
  const temporaryTopiclist = [
    "culture_profile",
    "geographical",
    "developmental",
    "traditional_food",
    "traditional_dress",
    "ornaments",
    "traditional_art",
    "festivals",
    "belief",
    "heritage_places",
    "prominent_artists",
    "famous_personalities",
  ];
  var keyy = props.match.params.ind;

  //STATES FOR VILLAGE DATA
  const filepick = useRef(null);
  const [undefineValue, setUndefineValue] = useState([
    null,
    undefined,
    "NA",
    "",
    "-",
  ]);

  //cultural states
  //cultural data
  const [culData, setCulData] = useState({
    your_village_famous_for: "",
    file: "",
    village_unique_from_other_village: "",
    historic_incident: "",
    State: "",
    District: "",
    village_name: "",
  });

  //cultural index for upload media(Only JPG/PNG)/maybe for all medias
  const [mediaIndex, setMediaIndex] = useState(0);
  const [mediaSubIndex, setMediaSubIndex] = useState(0);
  //cultural media
  //using media object as a separate entity for ease
  const [culMedia, setCulMedia] = useState([
    {
      caption: "",
      cdn_path: "",
      id: Date.now(),
      media_name: "",
    },
  ]);

  const [geoData, setGeoData] = useState({
    locate: "",
    search: " ",
    population: "",
    station: "",
    bus: "",
    media: "",
    file: "",
    femalePopulation: "",
    malePopulation: "",
    latitude_id: "",
    longitude_id: "",
  });
  const [developData, setDevelopData] = useState({
    livelihood: "",
    details: "",
    toilets: "",
    post: "",
    telephone: "",
    health: "",
    college: "",
    other: "",
    gas: "",
    school: "",
    water: "",
    bank: "",
    drainageSystem: "",
    tabWater: "",
  });

  //array based field states
  //wrting new ones and not messing with the ones above
  //fairs & festivals
  const [fairsAndFestivals, setFairAndFestivals] = useState([
    {
      title: "",
      description: "",
      any_specific_cusine_prepared: "",
      type_of_festival: "",
      who_organise_festival: "",
      who_take_part_in_festival: "",
    },
  ]);

  //using media object as a separate entity for ease
  //array inside an object which initself is inside an array
  //first array is objects of main field
  //sub-array is the objects of media
  const [festivalsMedia, setFestivalsMedia] = useState([
    {
      media: [
        {
          caption: "",
          cdn_path: "",
          id: Date.now(),
          media_name: "",
        },
      ],
    },
  ]);

  //traditional food state
  const [traditionalFood, setTraditionalFood] = useState([
    {
      title: "",
      description: "",
      media: "",
    },
  ]);

  //media state
  const [traditionalFoodMedia, setTraditionalFoodMedia] = useState([
    {
      media: [
        {
          caption: "",
          cdn_path: "",
          id: Date.now(),
          media_name: "",
        },
      ],
    },
  ]);

  //heritage palce state
  const [heritagePlaces, setHeritagePlaces] = useState([
    {
      title: "",
      description: "",
      media: "",
      file: "",
      deities_related_to_heritage: "",
      ritual_worship_related_to_heritage: "",
      story_associate_with_heritage: "",
      type: "",
    },
  ]);

  //media state
  const [heritagePlacesMedia, setHeritagePlacesMedia] = useState([
    {
      media: [
        {
          caption: "",
          cdn_path: "",
          id: Date.now(),
          media_name: "",
        },
      ],
    },
  ]);

  //belief palce state
  const [belief, setBelief] = useState([
    {
      title: "",
      description: "",
      media: "",
      file: "",
      relatedTo: "",
      type: "",
    },
  ]);
  //media state
  const [beliefMedia, setBeliefMedia] = useState([
    {
      media: [
        {
          caption: "",
          cdn_path: "",
          id: Date.now(),
          media_name: "",
        },
      ],
    },
  ]);

  //ornaments state
  const [ornament, setOrnament] = useState([
    {
      title: "",
      description: "",
      media: "",
    },
  ]);

  //media state
  const [ornamentsMedia, setOrnamentsMedia] = useState([
    {
      media: [
        {
          caption: "",
          cdn_path: "",
          id: Date.now(),
          media_name: "",
        },
      ],
    },
  ]);

  //prominent artists state
  const [prominentArtists, setProminentArtists] = useState([
    {
      name: "",
      description: "",
      media: "",
      file: "",
      expertise_of_skills: "",
      expertise_of_category: "",
      id_card_number: "",
      address: "",
      age: "",
      bank_account_number: "",
      bank_account_holder_name: "",
      branch_name: "",
      ifsc_code: "",
      gender: "",
      mobile: "",
    },
  ]);
  //media state
  const [prominentArtistsMedia, setProminentArtistsMedia] = useState([
    {
      media: [
        {
          caption: "",
          cdn_path: "",
          id: Date.now(),
          media_name: "",
        },
      ],
    },
  ]);

  //belief palce state
  const [traditionalDress, setTraditionalDress] = useState([
    {
      title: "",
      description: "",
      media: "",
    },
  ]);

  //media state
  const [traditionalDressMedia, setTraditionalDressMedia] = useState([
    {
      media: [
        {
          caption: "",
          cdn_path: "",
          id: Date.now(),
          media_name: "",
        },
      ],
    },
  ]);

  //famous personalities
  const [famousPersonalities, setFamousPersonalities] = useState([
    {
      name_of_local_hero: "",
      description: "",
      media: "",
      file: "",
      family_related_to: "",
      legend_stories: "",
      personal_details: "",
    },
  ]);

  //media state
  const [famousPersonalitiesMedia, setFamousPersonalitiesMedia] = useState([
    {
      media: [
        {
          caption: "",
          cdn_path: "",
          id: Date.now(),
          media_name: "",
        },
      ],
    },
  ]);

  // traditional art and craft state
  const [traditionalArtAndCraft, setTraditionalArtAndCraft] = useState([
    {
      name_of_art: "",
      description: "",
      media: "",
      file: "",
      art_from_pratice_in_village: "",
      classification_of_art_form: "",
      color_used: "",
      design_and_motifs: "",
      implement_tools: "",
      individual_or_group_activity: "",
      material_used: "",
      occasion_display_and_sale: "",
      practicing_communities: "",
      present_status_of_art_form: "",
      relationship_art_craft: "",
      specific_communities_associated_with: "",
      stories_songs: "",
      story_on_origin_of_art: "",
      sub_type_of_art_craft: "",
      suggestion_for_revival_and_economic: "",
      technique_used: "",
      type_of_art_craft: "",
      village_self_reliant: "",
    },
  ]);

  //media state
  const [traditionalArtAndCraftMedia, setTraditionalArtAndCraftMedia] =
    useState([
      {
        media: [
          {
            caption: "",
            cdn_path: "",
            id: Date.now(),
            media_name: "",
          },
        ],
      },
    ]);

  //->array based field handlers
  //not using the currently defined ones

  //------------------------------>>>>>fair and festival handlers
  const handleFairAndFestivals = (e, key) => {
    setFinalSubmit(false);
    let newFormValues = [...fairsAndFestivals];
    newFormValues[key][e.target.name] = e.target.value;
    setFairAndFestivals(newFormValues);
  };

  // fair and festivals adding a new obj - handler
  const handleAddFairAndFestivals = () => {
    const temp = [...fairsAndFestivals];
    temp.push({
      title: "",
      description: "",
      any_specific_cusine_prepared: "",
      type_of_festival: "",
      who_organise_festival: "",
      who_take_part_in_festival: "",
    });
    setFairAndFestivals(temp);

    //for media
    const tempForAssigningState = [...festivalsMedia];
    tempForAssigningState.push({
      media: [
        {
          caption: "",
          cdn_path: "",
          id: Date.now(),
          media_name: "",
        },
      ],
    });
    setFestivalsMedia(tempForAssigningState);
  };

  // fair and festivals removing an obj - handler
  let handleRemoveFairAndFestivals = (key) => {
    let newFormValues = [...fairsAndFestivals];
    newFormValues.splice(key, 1);
    setFairAndFestivals(newFormValues);

    //for media
    // cleaing up when the field gets deleted
    const tempForAssigningState = [...festivalsMedia];
    tempForAssigningState.splice(key, 1);
    setFestivalsMedia(tempForAssigningState);
  };

  //------------------------------>>>>>tradiditional food handlers
  const handleTraditionalFood = (e, key) => {
    setFinalSubmit(false);
    let newFormValues = [...traditionalFood];
    newFormValues[key][e.target.name] = e.target.value;
    setTraditionalFood(newFormValues);
  };

  // adding a new obj - handler
  const handleAddTraditionalFood = () => {
    const temp = [...traditionalFood];
    temp.push({
      title: "",
      description: "",
      media: "",
    });
    setTraditionalFood(temp);

    //for media
    const tempForAssigningState = [...traditionalFoodMedia];
    tempForAssigningState.push({
      media: [
        {
          caption: "",
          cdn_path: "",
          id: Date.now(),
          media_name: "",
        },
      ],
    });
    setTraditionalFoodMedia(tempForAssigningState);
  };

  //state for checking when to close popup in custom search box
  const [closePopup, setClosePopup] = useState(false);
  const fetchedElement = useRef(null);
  //useEffect
  useEffect(() => {
    //letting the elem mount
    setTimeout(() => {
      document
        .getElementsByClassName("custom-class-for-adding-listener")[0]
        .addEventListener("click", () => {
          setClosePopup(true);
        });
      return () => {
        document
          .getElementsByClassName("custom-class-for-adding-listener")[0]
          .removeEventListener("click", () => {
            setClosePopup(false);
          });
      };
    }, 500); //keeping it smaller for next cycle of clicks
  }, []);

  // removing an obj - handler
  let handleRemoveTraditionalFood = (key) => {
    let newFormValues = [...traditionalFood];
    newFormValues.splice(key, 1);
    setTraditionalFood(newFormValues);

    //for media
    // cleaing up when the field gets deleted
    const tempForAssigningState = [...traditionalFoodMedia];
    tempForAssigningState.splice(key, 1);
    setTraditionalFoodMedia(tempForAssigningState);
  };

  //----------------------------->heritage handelrs
  const handleHeritagePlaces = (e, key) => {
    setFinalSubmit(false);

    let newFormValues = [...heritagePlaces];
    newFormValues[key][e.target.name] = e.target.value;
    setHeritagePlaces(newFormValues);
  };

  // adding a new obj - handler
  const handleAddHeritagePlaces = () => {
    const temp = [...heritagePlaces];
    temp.push({
      title: "",
      description: "",
      media: "",
      file: "",
      deities_related_to_heritage: "",
      ritual_worship_related_to_heritage: "",
      story_associate_with_heritage: "",
      type: "",
    });
    setHeritagePlaces(temp);

    //for media
    const tempForAssigningState = [...heritagePlacesMedia];
    tempForAssigningState.push({
      media: [
        {
          caption: "",
          cdn_path: "",
          id: Date.now(),
          media_name: "",
        },
      ],
    });
    setHeritagePlacesMedia(tempForAssigningState);
  };

  // removing an obj - handler
  let handleRemoveHeritagePlaces = (key) => {
    let newFormValues = [...heritagePlaces];
    newFormValues.splice(key, 1);
    setHeritagePlaces(newFormValues);

    //for media
    // cleaing up when the field gets deleted
    const tempForAssigningState = [...heritagePlacesMedia];
    tempForAssigningState.splice(key, 1);
    setHeritagePlacesMedia(tempForAssigningState);
  };

  //------------------------------>>>>>belief handlers
  const handleBelief = (e, key) => {
    setFinalSubmit(false);

    let newFormValues = [...belief];
    newFormValues[key][e.target.name] = e.target.value;
    setBelief(newFormValues);
  };

  // adding a new obj - handler
  const handleAddBelief = () => {
    const temp = [...belief];
    temp.push({
      title: "",
      description: "",
      media: "",
      file: "",
      relatedTo: "",
      type: "",
    });
    setBelief(temp);

    //for media
    const tempForAssigningState = [...beliefMedia];
    tempForAssigningState.push({
      media: [
        {
          caption: "",
          cdn_path: "",
          id: Date.now(),
          media_name: "",
        },
      ],
    });
    setBeliefMedia(tempForAssigningState);
  };

  // removing an obj - handler
  let handleRemoveBelief = (key) => {
    let newFormValues = [...belief];
    newFormValues.splice(key, 1);
    setBelief(newFormValues);

    //for media
    // cleaing up when the field gets deleted
    const tempForAssigningState = [...beliefMedia];
    tempForAssigningState.splice(key, 1);
    setBeliefMedia(tempForAssigningState);
  };

  //------------------------------>>>>>ornaments handlers
  const handleOrnament = (e, key) => {
    setFinalSubmit(false);

    let newFormValues = [...ornament];
    newFormValues[key][e.target.name] = e.target.value;
    setOrnament(newFormValues);
  };

  // adding a new obj - handler
  const handleAddOrnament = () => {
    const temp = [...ornament];
    temp.push({
      title: "",
      description: "",
      media: "",
    });
    setOrnament(temp);

    //for media
    const tempForAssigningState = [...ornamentsMedia];
    tempForAssigningState.push({
      media: [
        {
          caption: "",
          cdn_path: "",
          id: Date.now(),
          media_name: "",
        },
      ],
    });
    setOrnamentsMedia(tempForAssigningState);
  };

  // removing an obj - handler
  let handleRemoveOrnament = (key) => {
    let newFormValues = [...ornament];
    newFormValues.splice(key, 1);
    setOrnament(newFormValues);
    // removing an obj - handler

    //for media
    // cleaing up when the field gets deleted
    const tempForAssigningState = [...ornamentsMedia];
    tempForAssigningState.splice(key, 1);
    setOrnamentsMedia(tempForAssigningState);
  };

  //------------------------------>>>>>prominent Artists handlers
  const handleProminentArtists = (e, key) => {
    setFinalSubmit(false);
    let newFormValues = [...prominentArtists];
    newFormValues[key][e.target.name] = e.target.value;
    setProminentArtists(newFormValues);
  };

  // adding a new obj - handler
  const handleAddProminentArtists = () => {
    const temp = [...prominentArtists];
    temp.push({
      name: "",
      description: "",
      media: "",
      file: "",
      expertise_of_skills: "",
      expertise_of_category: "",
      id_card_number: "",
      address: "",
      age: "",
      bank_account_number: "",
      bank_account_holder_name: "",
      branch_name: "",
      ifsc_code: "",
      gender: "",
      mobile: "",
    });
    setProminentArtists(temp);

    //for media
    const tempForAssigningState = [...prominentArtistsMedia];
    tempForAssigningState.push({
      media: [
        {
          caption: "",
          cdn_path: "",
          id: Date.now(),
          media_name: "",
        },
      ],
    });
    setProminentArtistsMedia(tempForAssigningState);
  };

  // removing an obj - handler
  let handleRemoveProminentArtists = (key) => {
    let newFormValues = [...prominentArtists];
    newFormValues.splice(key, 1);
    setProminentArtists(newFormValues);

    //for media
    // cleaing up when the field gets deleted
    const tempForAssigningState = [...prominentArtistsMedia];
    tempForAssigningState.splice(key, 1);
    setProminentArtistsMedia(tempForAssigningState);
  };

  //------------------------------>>>>>traditional dress handlers
  const handleTraditionalDress = (e, key) => {
    setFinalSubmit(false);

    let newFormValues = [...traditionalDress];
    newFormValues[key][e.target.name] = e.target.value;
    setTraditionalDress(newFormValues);
  };

  // adding a new obj - handler
  const handleAddTraditionalDress = () => {
    const temp = [...traditionalDress];
    temp.push({
      title: "",
      description: "",
      media: "",
    });
    setTraditionalDress(temp);

    //for media
    const tempForAssigningState = [...traditionalDressMedia];
    tempForAssigningState.push({
      media: [
        {
          caption: "",
          cdn_path: "",
          id: Date.now(),
          media_name: "",
        },
      ],
    });
    setTraditionalDressMedia(tempForAssigningState);
  };

  // removing an obj - handler
  let handleRemoveTraditionalDress = (key) => {
    let newFormValues = [...traditionalDress];
    newFormValues.splice(key, 1);
    setTraditionalDress(newFormValues);
    //for media
    // cleaing up when the field gets deleted
    const tempForAssigningState = [...traditionalDressMedia];
    tempForAssigningState.splice(key, 1);
    setTraditionalDressMedia(tempForAssigningState);
  };

  //------------------------------>>>>>famous personalities handlers
  const handleFamousPersonalities = (e, key) => {
    setFinalSubmit(false);

    let newFormValues = [...famousPersonalities];
    newFormValues[key][e.target.name] = e.target.value;
    setFamousPersonalities(newFormValues);
  };

  // adding a new obj - handler
  const handleAddFamousPersonalities = () => {
    const temp = [...famousPersonalities];
    temp.push({
      name_of_local_hero: "",
      description: "",
      media: "",
      file: "",
      family_related_to: "",
      legend_stories: "",
      personal_details: "",
    });
    setFamousPersonalities(temp);

    //for media
    const tempForAssigningState = [...famousPersonalitiesMedia];
    tempForAssigningState.push({
      media: [
        {
          caption: "",
          cdn_path: "",
          id: Date.now(),
          media_name: "",
        },
      ],
    });
    setFamousPersonalitiesMedia(tempForAssigningState);
  };

  // removing an obj - handler
  let handleRemoveFamousPersonalities = (key) => {
    let newFormValues = [...famousPersonalities];
    newFormValues.splice(key, 1);
    setFamousPersonalities(newFormValues);

    //for media
    // cleaing up when the field gets deleted
    const tempForAssigningState = [...famousPersonalitiesMedia];
    tempForAssigningState.splice(key, 1);
    setFamousPersonalitiesMedia(tempForAssigningState);
  };

  //------------------------------>>>>>traditional Art and Craft handlers
  const handleTraditionalArtAndCraft = (e, key) => {
    setFinalSubmit(false);

    let newFormValues = [...traditionalArtAndCraft];
    newFormValues[key][e.target.name] = e.target.value;
    setTraditionalArtAndCraft(newFormValues);
  };

  // adding a new obj - handler
  const handleAddTraditionalArtAndCraft = () => {
    const temp = [...traditionalArtAndCraft];
    temp.push({
      name_of_art: "",
      description: "",
      media: "",
      file: "",
      art_from_pratice_in_village: "",
      classification_of_art_form: "",
      color_used: "",
      design_and_motifs: "",
      implement_tools: "",
      individual_or_group_activity: "",
      material_used: "",
      occasion_display_and_sale: "",
      practicing_communities: "",
      present_status_of_art_form: "",
      relationship_art_craft: "",
      specific_communities_associated_with: "",
      stories_songs: "",
      story_on_origin_of_art: "",
      sub_type_of_art_craft: "",
      suggestion_for_revival_and_economic: "",
      technique_used: "",
      type_of_art_craft: "",
      village_self_reliant: "",
    });
    setTraditionalArtAndCraft(temp);

    //for media
    const tempForAssigningState = [...traditionalArtAndCraftMedia];
    tempForAssigningState.push({
      media: [
        {
          caption: "",
          cdn_path: "",
          id: Date.now(),
          media_name: "",
        },
      ],
    });
    setTraditionalArtAndCraftMedia(tempForAssigningState);
  };

  // removing an obj - handler
  let handleRemoveTraditionalArtAndCraft = (key) => {
    let newFormValues = [...traditionalArtAndCraft];
    newFormValues.splice(key, 1);
    setTraditionalArtAndCraft(newFormValues);

    //for media
    // cleaing up when the field gets deleted
    const tempForAssigningState = [...traditionalArtAndCraftMedia];
    tempForAssigningState.splice(key, 1);
    setTraditionalArtAndCraftMedia(tempForAssigningState);
  };

  //------------------------->>>>handle dynamic image handling
  //handle change in caption value
  let handleCaptionChange = (key, e, subKey, fieldName) => {
    //checking if it's not culture field
    if (subKey != undefined) {
      //if statements for all the field go here
      if (fieldName == "festivals") {
        let newFormValues = [...festivalsMedia];
        newFormValues[key].media[subKey][e.target.name] = e.target.value;
        setFestivalsMedia(newFormValues);
      }

      if (fieldName == "traditionalFood") {
        let newFormValues = [...traditionalFoodMedia];
        newFormValues[key].media[subKey][e.target.name] = e.target.value;
        setTraditionalFoodMedia(newFormValues);
      }
      if (fieldName == "heritagePlaces") {
        let newFormValues = [...heritagePlacesMedia];
        newFormValues[key].media[subKey][e.target.name] = e.target.value;
        setHeritagePlacesMedia(newFormValues);
      }
      if (fieldName == "belief") {
        let newFormValues = [...beliefMedia];
        newFormValues[key].media[subKey][e.target.name] = e.target.value;
        setBeliefMedia(newFormValues);
      }
      if (fieldName == "ornaments") {
        let newFormValues = [...ornamentsMedia];
        newFormValues[key].media[subKey][e.target.name] = e.target.value;
        setOrnamentsMedia(newFormValues);
      }
      if (fieldName == "prominentArtists") {
        let newFormValues = [...prominentArtistsMedia];
        newFormValues[key].media[subKey][e.target.name] = e.target.value;
        setProminentArtistsMedia(newFormValues);
      }
      if (fieldName == "traditionalDress") {
        let newFormValues = [...traditionalDressMedia];
        newFormValues[key].media[subKey][e.target.name] = e.target.value;
        setTraditionalDressMedia(newFormValues);
      }
      if (fieldName == "famousPersonalities") {
        let newFormValues = [...famousPersonalitiesMedia];
        newFormValues[key].media[subKey][e.target.name] = e.target.value;
        setFamousPersonalitiesMedia(newFormValues);
      }
      if (fieldName == "traditionalArtAndCraft") {
        let newFormValues = [...traditionalArtAndCraftMedia];
        newFormValues[key].media[subKey][e.target.name] = e.target.value;
        setTraditionalArtAndCraftMedia(newFormValues);
      }
    } else {
      // culture profile
      let newFormValues = [...culMedia];
      newFormValues[key][e.target.name] = e.target.value;
      setCulMedia(newFormValues);
    }
  };

  //add a new field - image cul
  let addFormFieldsMedia = (key, fieldName) => {
    //if there isn't a key then its culture media
    if (key == undefined)
      setCulMedia([
        ...culMedia,
        { caption: "", cdn_path: "", id: Date.now(), media_name: "" },
      ]);
    else {
      if (fieldName == "festivals") {
        const temp = [...festivalsMedia];

        temp[key].media.push({
          caption: "",
          cdn_path: "",
          id: Date.now(),
          media_name: "",
        });
        setFestivalsMedia(temp);
      } else if (fieldName == "traditionalFood") {
        const temp = [...traditionalFoodMedia];

        temp[key].media.push({
          caption: "",
          cdn_path: "",
          id: Date.now(),
          media_name: "",
        });
        setTraditionalFoodMedia(temp);
      } else if (fieldName == "heritagePlaces") {
        const temp = [...heritagePlacesMedia];

        temp[key].media.push({
          caption: "",
          cdn_path: "",
          id: Date.now(),
          media_name: "",
        });
        setHeritagePlacesMedia(temp);
      } else if (fieldName == "belief") {
        const temp = [...beliefMedia];

        temp[key].media.push({
          caption: "",
          cdn_path: "",
          id: Date.now(),
          media_name: "",
        });
        setBeliefMedia(temp);
      } else if (fieldName == "ornaments") {
        const temp = [...ornamentsMedia];

        temp[key].media.push({
          caption: "",
          cdn_path: "",
          id: Date.now(),
          media_name: "",
        });
        setOrnamentsMedia(temp);
      } else if (fieldName == "prominentArtists") {
        const temp = [...prominentArtistsMedia];

        temp[key].media.push({
          caption: "",
          cdn_path: "",
          id: Date.now(),
          media_name: "",
        });
        setProminentArtistsMedia(temp);
      } else if (fieldName == "traditionalDress") {
        const temp = [...traditionalDressMedia];

        temp[key].media.push({
          caption: "",
          cdn_path: "",
          id: Date.now(),
          media_name: "",
        });
        setTraditionalDressMedia(temp);
      } else if (fieldName == "famousPersonalities") {
        const temp = [...famousPersonalitiesMedia];

        temp[key].media.push({
          caption: "",
          cdn_path: "",
          id: Date.now(),
          media_name: "",
        });
        setFamousPersonalitiesMedia(temp);
      } else if (fieldName == "traditionalArtAndCraft") {
        const temp = [...traditionalArtAndCraftMedia];

        temp[key].media.push({
          caption: "",
          cdn_path: "",
          id: Date.now(),
          media_name: "",
        });
        setTraditionalArtAndCraftMedia(temp);
      }
    }
  };

  //remove a field - image cul
  let removeFormFieldsMedia = (key, subKey, fieldName) => {
    //if there isn't a key then its culture media
    if (subKey == undefined) {
      let newFormValues = [...culMedia];
      newFormValues.splice(key, 1);
      setCulMedia(newFormValues);
    } else {
      if (fieldName == "festivals") {
        const temp = [...festivalsMedia];
        temp[key].media.splice(subKey, 1);
        setFestivalsMedia(temp);
      } else if (fieldName == "traditionalFood") {
        const temp = [...traditionalFoodMedia];
        temp[key].media.splice(subKey, 1);
        setTraditionalFoodMedia(temp);
      } else if (fieldName == "heritagePlaces") {
        const temp = [...heritagePlacesMedia];
        temp[key].media.splice(subKey, 1);
        setHeritagePlacesMedia(temp);
      } else if (fieldName == "ornaments") {
        const temp = [...ornamentsMedia];
        temp[key].media.splice(subKey, 1);
        setOrnamentsMedia(temp);
      } else if (fieldName == "prominentArtists") {
        const temp = [...prominentArtistsMedia];
        temp[key].media.splice(subKey, 1);
        setProminentArtistsMedia(temp);
      } else if (fieldName == "traditionalDress") {
        const temp = [...traditionalDressMedia];
        temp[key].media.splice(subKey, 1);
        setTraditionalDressMedia(temp);
      } else if (fieldName == "famousPersonalities") {
        const temp = [...famousPersonalitiesMedia];
        temp[key].media.splice(subKey, 1);
        setFamousPersonalitiesMedia(temp);
      } else if (fieldName == "traditionalArtAndCraft") {
        const temp = [...traditionalArtAndCraftMedia];
        temp[key].media.splice(subKey, 1);
        setTraditionalArtAndCraftMedia(temp);
      } else if (fieldName == "traditionalArtAndCraft") {
        const temp = [...beliefMedia];
        temp[key].media.splice(subKey, 1);
        setBeliefMedia(temp);
      }
    }
  };

  const removeFileMedia = (key, subKey, fieldName) => {
    if (subKey == undefined) {
      let newFormValues = [...culMedia];
      newFormValues.splice(key, 1);
      setCulMedia(newFormValues);
    } else if (fieldName == "festivals") {
      const temp = [...festivalsMedia];
      temp[key].media.splice(subKey, 1);
      setFestivalsMedia(temp);
    } else if (fieldName == "traditionalFood") {
      const temp = [...traditionalFoodMedia];
      temp[key].media.splice(subKey, 1);
      setTraditionalFoodMedia(temp);
    } else if (fieldName == "heritagePlaces") {
      const temp = [...heritagePlacesMedia];
      temp[key].media.splice(subKey, 1);
      setHeritagePlacesMedia(temp);
    } else if (fieldName == "ornaments") {
      const temp = [...ornamentsMedia];
      temp[key].media.splice(subKey, 1);
      setOrnamentsMedia(temp);
    } else if (fieldName == "traditionalDress") {
      const temp = [...traditionalDressMedia];
      temp[key].media.splice(subKey, 1);
      setTraditionalDressMedia(temp);
    } else if (fieldName == "prominentArtists") {
      const temp = [...prominentArtistsMedia];
      temp[key].media.splice(subKey, 1);
      setProminentArtistsMedia(temp);
    } else if (fieldName == "famousPersonalities") {
      const temp = [...famousPersonalitiesMedia];
      temp[key].media.splice(subKey, 1);
      setFamousPersonalitiesMedia(temp);
    } else if (fieldName == "traditionalArtAndCraft") {
      const temp = [...traditionalArtAndCraftMedia];
      temp[key].media.splice(subKey, 1);
      setTraditionalArtAndCraftMedia(temp);
    } else if (fieldName == "belief") {
      const temp = [...beliefMedia];
      temp[key].media.splice(subKey, 1);
      setHeritagePlacesMedia(temp);
    }
  };
  //modal handlers - image cul
  const handleModalShow = () => {
    setisUpdate(false);
    setModalShow(true);
    setImageItem();
  };

  const handleModalClose = () => {
    setModalShow(false);
  };

  const newValue = async (value) => {
    if (!culData.District && !culData.State) {
      if (value) {
        const db = firebase.firestore().collection("large_village_list");
        if (value.length >= 3) {
          let data = [];
          await db
            .orderBy("village_name")
            .where("village_name", ">=", value.toUpperCase())
            .limit(100)
            .get()
            .then((querySnapShot) => {
              let tempVillage = [];
              querySnapShot.forEach((doc) => {
                data.push({ name: doc.data().village_name });
                const obj = {
                  value: doc.data().village_name,
                  label: doc.data().village_name,
                };
                tempVillage.push(obj);
              });

              tempVillage = tempVillage.filter(
                (a, i) =>
                  tempVillage.findIndex((s) => a.value === s.value) === i
              );
              setallVillage(tempVillage);
            });
        }
      }
    }
  };
  //village state change handlers
  //geo state handlers
  const geoChange = (e) => {
    setFinalSubmit(false);

    setGeoData({ ...geoData, [e.target.name]: e.target.value });
  };

  const culChange = (e, _name = undefined) => {
    //making the error go away

    setVillageValid(false);

    //for select feild with algolia
    if (_name != undefined) {
      setCulData({ ...culData, [_name]: e }); // e will act as value itself
    }
    //this will run when any other culture field is called except select
    else {
      setCulData({ ...culData, [e.target.name]: e.target.value });
    }

    setFinalSubmit(false);
  };
  const developChange = (e) => {
    setDevelopData({ ...developData, [e.target.name]: e.target.value });
    setFinalSubmit(false);
  };

  //when the next button is clicked
  const routeChange = () => {
    if (selectedTopic == "culture_profile") {
      setSelectedTopic("geographical");
    }
    if (selectedTopic == "geographical") {
      setSelectedTopic("developmental");
    }
    if (selectedTopic == "developmental") {
      setSelectedTopic("traditional_food");
    }
    if (selectedTopic == "traditional_food") {
      setSelectedTopic("traditional_dress");
    }
    if (selectedTopic == "traditional_dress") {
      setSelectedTopic("ornaments");
    }
    if (selectedTopic == "ornaments") {
      setSelectedTopic("traditional_art");
    }
    if (selectedTopic == "traditional_art") {
      setSelectedTopic("festivals");
    }
    if (selectedTopic == "festivals") {
      setSelectedTopic("belief");
    }
    if (selectedTopic == "belief") {
      setSelectedTopic("heritage_places");
    }
    if (selectedTopic == "heritage_places") {
      setSelectedTopic("prominent_artists");
    }
    if (selectedTopic == "prominent_artists") {
      setSelectedTopic("famous_personalities");
    }
    // scroll to top functionality
    const elementDiv = document.querySelector(".th-90-height-scroll");
    const moblie = document.querySelector("#offCanv");
    if (window.innerWidth >= 767) {
      elementDiv.scrollTo(0, 0);
    } else {
      moblie.scrollIntoView();
    }
    // elementDiv.scrollIntoView();
  };

  //image handlers

  const onFileChange = async (e, val) => {
    if (
      culData?.village_name != "" &&
      culData?.village_name != "see_all_categories" &&
      culData.hasOwnProperty("village_name")
    ) {
      setIsValidName(false);

      const file = filepick?.current?.files[0];
      setFinalSubmit(false);

      //loader
      setUploadingToFirebase(true);

      //uploading to firebase
      const uploadedFile = await saveFirebase(e.target.files[0]);
      console.log(uploadedFile);
      if (val == "culture") {
        let newFormValues = [...culMedia];
        newFormValues[mediaIndex].media_name = uploadedFile;
        setCulMedia(newFormValues);
        setUploadingToFirebase(false);
      }

      if (val == "festivals") {
        let newFormValues = [...festivalsMedia];
        newFormValues[mediaIndex].media[mediaSubIndex].media_name =
          uploadedFile;

        setFestivalsMedia(newFormValues);
        setUploadingToFirebase(false);
      }
      if (val == "traditionalFood") {
        let newFormValues = [...traditionalFoodMedia];
        newFormValues[mediaIndex].media[mediaSubIndex].media_name =
          uploadedFile;

        setTraditionalFoodMedia(newFormValues);
        setUploadingToFirebase(false);
      }
      if (val == "heritagePlaces") {
        let newFormValues = [...heritagePlacesMedia];
        newFormValues[mediaIndex].media[mediaSubIndex].media_name =
          uploadedFile;

        setHeritagePlacesMedia(newFormValues);
        setUploadingToFirebase(false);
      }
      if (val == "belief") {
        let newFormValues = [...beliefMedia];
        newFormValues[mediaIndex].media[mediaSubIndex].media_name =
          uploadedFile;

        setBeliefMedia(newFormValues);
        setUploadingToFirebase(false);
      }
      if (val == "ornaments") {
        let newFormValues = [...ornamentsMedia];
        newFormValues[mediaIndex].media[mediaSubIndex].media_name =
          uploadedFile;

        setOrnamentsMedia(newFormValues);
        setUploadingToFirebase(false);
      }
      if (val == "prominentArtists") {
        let newFormValues = [...prominentArtistsMedia];
        newFormValues[mediaIndex].media[mediaSubIndex].media_name =
          uploadedFile;

        setProminentArtistsMedia(newFormValues);
        setUploadingToFirebase(false);
      }
      if (val == "traditionalDress") {
        let newFormValues = [...traditionalDressMedia];
        newFormValues[mediaIndex].media[mediaSubIndex].media_name =
          uploadedFile;

        setTraditionalDressMedia(newFormValues);
      }
      if (val == "famousPersonalities") {
        let newFormValues = [...famousPersonalitiesMedia];
        newFormValues[mediaIndex].media[mediaSubIndex].media_name =
          uploadedFile;

        setFamousPersonalitiesMedia(newFormValues);
      }
      if (val == "traditionalArtAndCraft") {
        let newFormValues = [...traditionalArtAndCraftMedia];
        newFormValues[mediaIndex].media[mediaSubIndex].media_name =
          uploadedFile;

        setTraditionalArtAndCraftMedia(newFormValues);
      }
    } else {
      // raise an error on the UI
      setIsValidName(true);
    }
  };

  //upload file to firebase
  //returns link from the storage bucket
  const saveFirebase = async (file) => {
    const fileName = `${Date.now()}.${file.name.split(".").pop()}`;

    const params = {
      Bucket: "static.mgmd.gov.in",
      Key: `images/${fileName}`,
      Body: file,
      ContentType: "image/jpg",
    };

    return new Promise((resolve, reject) => {
      s3.upload(params, (err, data) => {
        if (err) {
          console.error(err);
          reject(err);
        } else {
          console.log("Image uploaded successfully:", data);
          resolve(fileName);
        }
      });
    });
  };

  //form handler when submitted
  const handleSubmit = (e, val) => {
    e.preventDefault();
    setLoader(true);

    setTimeout(() => {
      setLoader(false);
    }, 2000);

    if (val == "final_submit") {
      if (
        ["see_all_categories", "", " ", null, undefined].includes(
          culData?.village_name
        )
      ) {
        setIsValidName(true);
      } else {
        setIsValidName(false);
      }

      if (
        ["see_all_categories", "", " ", null, undefined].includes(
          culData?.District
        )
      ) {
        setIsValidDistrict(true);
      } else {
        setIsValidDistrict(false);
      }

      if (
        ["see_all_categories", "", " ", null, undefined].includes(
          culData?.State
        )
      ) {
        setIsValidState(true);
      } else {
        setIsValidState(false);
      }

      setTimeout(() => {
        setIsValidName(false);
        setIsValidState(false);
        setIsValidDistrict(false);
      }, 3000);

      if (culData?.village_name && culData?.District && culData?.State) {
        firebase
          .firestore()
          .collection("large_village_detail")
          .where("village_name", "==", culData?.village_name)
          .where("district_name", "==", culData?.District)
          .where("state_name", "==", culData?.State)
          .get()
          .then(async (data) => {
            console.log(data.size);
            if (data.docs && data.docs?.length > 0) {
              setVillageAlready(true);
              setTimeout(() => {
                setVillageAlready(false);
                setLoader(false);
              }, 2500);
            } else {
              let id = uuid();

              //sttaes for assiging values before uploading the data
              let culture_profile = culData;
              let developmental_profile = developData;
              let geographic_profile = geoData;
              let festival_profile = fairsAndFestivals;
              let traditional_food_profile = traditionalFood;
              let heritage_places_profile = heritagePlaces;
              let belief_profile = belief;
              let traditional_ornaments_profile = ornament;
              let prominent_artists_profile = prominentArtists;
              let traditional_dress_profile = traditionalDress;
              let famous_personalities_profile = famousPersonalities;
              let traditional_art_and_craft_profile = traditionalArtAndCraft;

              //assigning media obj to culdata
              culture_profile = culData;
              culture_profile.media = culMedia;

              //loop for array-fields media
              for (let count = 0; count < festival_profile.length; count++) {
                festival_profile[count].media = festivalsMedia[count].media;
              }

              for (
                let count = 0;
                count < traditional_food_profile.length;
                count++
              ) {
                traditional_food_profile[count].media =
                  traditionalFoodMedia[count].media;
              }

              for (
                let count = 0;
                count < heritage_places_profile.length;
                count++
              ) {
                heritage_places_profile[count].media =
                  heritagePlacesMedia[count].media;
              }

              for (let count = 0; count < belief_profile.length; count++) {
                belief_profile[count].media = beliefMedia[count].media;
              }

              for (
                let count = 0;
                count < traditional_ornaments_profile.length;
                count++
              ) {
                traditional_ornaments_profile[count].media =
                  ornamentsMedia[count].media;
              }

              for (
                let count = 0;
                count < prominent_artists_profile.length;
                count++
              ) {
                prominent_artists_profile[count].media =
                  prominentArtistsMedia[count].media;
              }
              for (
                let count = 0;
                count < traditional_dress_profile.length;
                count++
              ) {
                traditional_dress_profile[count].media =
                  traditionalDressMedia[count].media;
              }
              for (
                let count = 0;
                count < famous_personalities_profile.length;
                count++
              ) {
                famous_personalities_profile[count].media =
                  famousPersonalitiesMedia[count].media;
              }
              for (
                let count = 0;
                count < traditional_art_and_craft_profile.length;
                count++
              ) {
                traditional_art_and_craft_profile[count].media =
                  traditionalArtAndCraftMedia[count].media;
              }
              const address = `${culData.village_name},${culData.District},${culData.State},India`;
              //getting the lat and long for map
              fetch(
                "https://maps.googleapis.com/maps/api/geocode/json?address=" +
                  address +
                  "&key=" +
                  "AIzaSyA93xR8JywwU_tDrKPfpSjqM5TDDQ8i_FE"
              )
                .then((response) => response.json())
                .then((data) => {
                  console.log(data);
                  geographic_profile.latitude_id =
                    data.results[0].geometry.location.lat;
                  geographic_profile.longitude_id =
                    data.results[0].geometry.location.lng;

                  //do the rest of stuff in here
                })
                .catch((err) => {
                  console.log(err);
                });

              const data = {
                village_name: culData?.village_name,
                district_name: culData?.District,
                state_name: culData?.State,
                new_village: true,
                status: "CSC Approved",
                data: {
                  timestamp: Date.now(),
                  culture_profile: { ...culData },
                  geographical: { ...geoData },
                  developmental: { ...developData },
                  festival: festival_profile,
                  traditional_food: traditional_food_profile,
                  belief: belief_profile,
                  heritage_places: heritage_places_profile,
                  ornaments: traditional_ornaments_profile,
                  traditional_dress: traditional_dress_profile,
                  prominent_artists: prominent_artists_profile,
                  famous_personalities: famous_personalities_profile,
                  traditional_art: traditional_art_and_craft_profile,
                },
              };

              console.log(data);
              firebase
                .firestore()
                .collection("large_village_detail")
                .add(data)
                .then(() => {
                  setFinalSubmit(true);
                  setLoader(false);
                  //show a message that its been submitted
                })
                .catch((err) => {
                  //show message that its failed
                  console.log(err);
                });

              setTimeout(() => {
                setFinalSubmit(false);
              }, 2000);
            }
          });
      } else {
        setVillageValid(true);
        //re-route to culture page
        setSelectedTopic("culture_profile");
        //scroll to the top for message to be shown

        document.getElementById("error-id")?.scrollIntoView();

        setTimeout(() => {
          setVillageValid(false);
          setLoader(false);
        }, 2000);
      }
      // if (culData?.village_name && culData?.District && culData?.State) {
      //   setSubmitFirst(true);

      //   setCulData({
      //     your_village_famous_for: "",
      //     file: "",
      //     village_unique_from_other_village: "",
      //     historic_incident: "",
      //     State: "",
      //     District: "",
      //     village_name: "",
      //   });
      //   setSelectedValueForDistrictFilter();
      //   setSelectedValueForStateFilter();
      //   setSelectedValueForVillageFilter();
      // }
      // setTimeout(() => {
      //   setSubmitFirst(false);
      // }, 2000);
    }
  };

  const handleChangeFilter = async (_value, _filterToBeUpdated, value) => {
    if (_filterToBeUpdated == "State") {
      setSelectedValueForStateFilter(_value);
      // setAllStates(allStatesStore);
    }
    if (_filterToBeUpdated == "District") {
      setSelectedValueForDistrictFilter(_value);
    }
    if (_filterToBeUpdated == "village_name") {
      setSelectedValueForVillageFilter(_value);
    }
  };

  return (
    <>
      <MyDesktop className="add_village_page_css">
        <MyLayout footer={false} className="add_village_page_css">
          <SuggestEdit
            show={modalShow}
            handleClose={handleModalClose}
            handleModalShow={handleModalShow}
            keyy={keyy}
            jsonField={selectedTopic}
            update={isUpdate}
            item={imageItem}
          />

          <div className="add_village_page_css">
            <div className="row d-flex justify-content-center">
              {/* left sidebar - start*/}
              <div
                className="col-md-5 my-3 mr-2 font-family-wali-class"
                style={{
                  backgroundColor: "#fff",
                  boxShadow: "rgb(152 136 177 / 25%) 14px 2px 30px 4px",
                  borderRadius: "15px",
                  width: "20%",
                  height: "74vh",
                  overflowY: "auto",
                }}
              >
                <span style={{ padding: "5px" }}>
                  {temporaryTopiclist?.map((item, index) => {
                    return topicsData[item] === null ||
                      topicsData[item] === "undefined" ? null : topicsData[item]
                        ?.length == 1 &&
                      undefineValue.includes(topicsData[item][0].media) &&
                      undefineValue.includes(topicsData[item][0].title) &&
                      undefineValue.includes(topicsData[item][0].description) &&
                      topicsData[item][0].status != 0 ? null : (
                      <>
                        <div
                          className={`th-pointer th-fw-400 action-btn my-2 text-capitalize col ${
                            selectedTopic === item
                              ? "th-fw-600 col selectiveColor"
                              : null
                          }`}
                          style={{ overflowWrap: "break-word" }}
                          key={index}
                          onClick={() => {
                            setSelectedTopic(item);
                          }}
                        >
                          {item === "geographical"
                            ? "Geographic and Demographic profile"
                            : item === "developmental"
                            ? "Developmental Profile"
                            : item === "festivals"
                            ? "Fairs and Festivals"
                            : item === "traditional_art"
                            ? "Traditional Art and Craft"
                            : item === "ornaments"
                            ? "Traditional Ornaments"
                            : item === "belief"
                            ? "Beliefs and Customs"
                            : item.toLowerCase().split("_").join(" ")}
                        </div>
                      </>
                    );
                  })}
                </span>
              </div>
              {/* left sidebar - end*/}

              <>
                <div
                  className="col-md-7 my-3 custom-class-for-adding-listener"
                  id="error-id"
                >
                  {
                    <Modal show={finalSubmit} centered>
                      <Modal.Body className="text-center text-success">
                        Submitted For Review
                      </Modal.Body>
                    </Modal>
                  }
                  {
                    <Modal show={submitFirst} centered>
                      <Modal.Body className="text-center text-success">
                        Submitted For Review
                      </Modal.Body>
                    </Modal>
                  }

                  {villageAlready && (
                    <Alert key={"danger"} variant={"danger"}>
                      <div className="text-center">
                        Same Village Already Exists!
                      </div>
                    </Alert>
                  )}
                  {
                    <Modal show={submitFirst} centered>
                      <Modal.Body className="text-center text-success">
                        {" "}
                        Submitted For Review
                      </Modal.Body>
                    </Modal>
                  }
                  {/* -----------------------------------------CULTURAL PROFILE----------------------------------------------- */}
                  {selectedTopic === "culture_profile" ? (
                    <div className="col-md-12 col-sm-12">
                      <form>
                        <div className="add-village-container-sub-field">
                          <div className="main_heading">
                            Fill The Details of Village
                            <hr></hr>
                          </div>

                          <div className="form-row d-flex pt-4 g-0">
                            <div className="form-group col-md-12">
                              <label className="label_form  ">
                                State
                                <span className="px-1 text-danger">*</span>
                              </label>
                              <MenuSelectAddVillage
                                attribute="state_name"
                                f_name="State"
                                className="col-md-12 bg-input-custom p-2 state_filter"
                                limit={1000}
                                passedDownItems={allStates}
                                onChangeFun_={handleChangeFilter}
                                selectedValue={selectedValueForStateFilter}
                                passedValue={culData?.State}
                                checkIfVillageAssigned={isDisabledMenuSelect}
                                error={isValidState}
                                onChangeFunc_={culChange}
                                passnewValue={newValue}
                              />
                              {isValidState && (
                                <span className="text-danger">
                                  "Please Enter State"
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="form-group col-md-12">
                              <label className="label_form  ">
                                District
                                <span className="px-1 text-danger">*</span>
                              </label>
                              <MenuSelectAddVillage
                                attribute="district_name"
                                f_name="District"
                                limit={1000}
                                className="distric_filter"
                                passedDownItems={allDistricts}
                                onChangeFun_={handleChangeFilter}
                                selectedValue={selectedValueForDistrictFilter}
                                passedValue={culData?.District}
                                checkIfVillageAssigned={isDisabledMenuSelect}
                                error={isValidDistrict}
                                onChangeFunc_={culChange}
                                passnewValue={newValue}
                              />
                              {isValidDistrict && (
                                <span className="text-danger">
                                  "Please Enter District"
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="form-row ">
                            <div></div>
                            <div className="form-group col-md-12 g-0">
                              <label className="label_form ">
                                Name Of Village
                                <span className="px-1 text-danger">*</span>
                              </label>
                              <MenuSelectAddVillage
                                attribute="village_name"
                                f_name="village_name"
                                limit={1000}
                                className="village_filter"
                                passedDownItems={allVillage}
                                passedValue={culData?.village_name}
                                onChangeFun_={handleChangeFilter}
                                selectedValue={selectedValueForVillageFilter}
                                onChangeFunc_={culChange}
                                error={isValidName}
                                passnewValue={newValue}
                              />
                              {isValidName && (
                                <span className="text-danger">
                                  "Please Enter Village Name"
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="label_form">
                              What is your village famous for?
                            </label>
                            <input
                              type="text"
                              name="your_village_famous_for"
                              onChange={(e) => culChange(e)}
                              value={culData?.your_village_famous_for}
                              className="form-control bg-input-custom"
                            />
                          </div>
                          <div className="form-group">
                            <label className="label_form ">
                              Is there anything that make your village unique or
                              different from other villages?{" "}
                            </label>
                            <input
                              type="text"
                              name="village_unique_from_other_village"
                              onChange={(e) => culChange(e)}
                              className="form-control bg-input-custom"
                              value={culData?.village_unique_from_other_village}
                            />
                          </div>
                          <div className="form-group">
                            <label className="label_form">
                              Are there any famous historic incidents that
                              happened in your village?{" "}
                            </label>
                            <input
                              type="text"
                              name="historic_incident"
                              onChange={(e) => culChange(e)}
                              value={culData?.historic_incident}
                              className="form-control bg-input-custom"
                            />
                          </div>

                          {/* dynamic fields image */}
                          <label className="label_form ">
                            Upload Media(Only JPG/PNG)
                          </label>
                          {culMedia.map((item, key) => {
                            return (
                              <div className="form-row d-flex" key={key}>
                                <div className="form-group col-10 pt-3">
                                  <div className="position-relative">
                                    <textarea
                                      className="form-control d-flex bg-input-custom custom-width-upload-media"
                                      value={item.caption}
                                      name="caption"
                                      onChange={(e) =>
                                        handleCaptionChange(key, e)
                                      }
                                      rows="5"
                                    />

                                    {/* delete btn wont show for first value */}
                                    {key != 0 ? (
                                      <img
                                        src={MinusIcon}
                                        alt="MinusIcon"
                                        className="position-absolute delete-button-custom"
                                        onClick={() =>
                                          removeFormFieldsMedia(key)
                                        }
                                      />
                                    ) : (
                                      <></>
                                    )}
                                  </div>

                                  {/* showing file if available */}
                                  {culMedia[key].media_name != "" ? (
                                    culMedia[key].media_name
                                  ) : (
                                    <>
                                      {uploadingToFirebase
                                        ? "uploading, please wait"
                                        : null}
                                    </>
                                  )}
                                  {culMedia[key].media_name != "" ? (
                                    <span
                                      className="mx-4"
                                      role="button"
                                      onClick={() => removeFileMedia(key)}
                                    >
                                      X
                                    </span>
                                  ) : (
                                    " "
                                  )}
                                </div>
                                <div className="form-group col-2" key={key}>
                                  <input
                                    type="file"
                                    name="file"
                                    id="custom-files-hidden"
                                    ref={filepick}
                                    onChange={(e) => onFileChange(e, "culture")}
                                    onClick={(event) => {
                                      event.target.value = null;
                                    }}
                                    onDrop
                                    hidden
                                    className="hidden"
                                    accept="image/jpg, image/png, image/jpeg"
                                  />{" "}
                                  <label
                                    onClick={() =>
                                      setMediaIndex(key, "cultural")
                                    }
                                    for="custom-files-hidden"
                                    className="btn_browse "
                                    role="button"
                                  >
                                    Browse
                                  </label>
                                </div>
                              </div>
                            );
                          })}

                          <div
                            className="dynamic-button-add dynamic-button-add-temp"
                            onClick={() => addFormFieldsMedia()}
                          >
                            Add More
                            <img
                              className="plus-icon"
                              src={PlusIcon}
                              alt="PlusIcon"
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  ) : null}

                  {/* ----------------------------------GEOGRAPHICAL LOCATION------------------------------------------ */}

                  {selectedTopic === "geographical" ? (
                    <>
                      <div className="col-md-12 col-sm-12">
                        <div>
                          <form>
                            <div className="add-village-container-sub-field">
                              <div className="main_heading">
                                Fill The Details of Village
                                <hr></hr>
                              </div>

                              <div className="form-row pt-4">
                                <div className="form-group col-md-12">
                                  <label className="label_form d-flex">
                                    Locate Your Village
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control bg-input-custom"
                                    name="locate"
                                    value={geoData.locate}
                                    onChange={geoChange}
                                  />
                                </div>
                              </div>
                              <div className="form-row">
                                <div className="form-group col-md-12 ">
                                  <label className="label_form  d-flex">
                                    Male Population
                                  </label>
                                  <input
                                    type="text"
                                    name="malePopulation"
                                    className="form-control bg-input-custom"
                                    onChange={(e) => geoChange(e)}
                                    value={geoData?.malePopulation}
                                  />
                                </div>
                                <div className="form-group col-md-12">
                                  <label className="label_form">
                                    Female Population
                                  </label>
                                  <input
                                    type="text"
                                    name="femalePopulation"
                                    className="form-control bg-input-custom"
                                    onChange={(e) => geoChange(e)}
                                    value={geoData?.femalePopulation}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="label_form">
                                  Total Population
                                </label>
                                <input
                                  type="text"
                                  className="form-control bg-input-custom"
                                  name="population"
                                  value={geoData.population}
                                  onChange={geoChange}
                                />
                              </div>
                              <div className="form-group">
                                <label className="label_form">
                                  Nearest Railway Station
                                </label>
                                <input
                                  type="text"
                                  className="form-control bg-input-custom"
                                  name="station"
                                  value={geoData.station}
                                  onChange={geoChange}
                                />
                              </div>
                              <div className="form-group">
                                <label className="label_form">
                                  Nearest Bus Terminal
                                </label>
                                <input
                                  type="text"
                                  className="form-control bg-input-custom"
                                  name="bus"
                                  value={geoData.bus}
                                  onChange={geoChange}
                                />
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </>
                  ) : null}

                  {/* ----------------------------------------------DEVELOPMENTAL---------------------------------------------------- */}

                  {selectedTopic === "developmental" ? (
                    <div className="col-md-12 col-sm-12 p-1">
                      <form>
                        <div className="add-village-container-sub-field">
                          <div className="main_heading ">
                            Fill The Details of Village
                            <hr></hr>
                          </div>
                          <div className="form-row d-flex pt-4">
                            <div className="form-group col-md-12">
                              <label for="inputEmail4" className="label_form ">
                                Primary Source Of Livelihood
                              </label>
                              <input
                                type="text"
                                className="form-control bg-input-custom"
                                name="livelihood"
                                value={developData.livelihood}
                                onChange={(e) => developChange(e)}
                              />
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="form-group col-md-12">
                              <label
                                for="inputState"
                                className="label_form                                         </label>

"
                              >
                                Add More Details
                              </label>
                              <textarea
                                className="form-control bg-input-custom"
                                name="details"
                                value={developData.details}
                                onChange={(e) => developChange(e)}
                              />
                            </div>
                          </div>
                          <div className="form-row d-flex devlop">
                            <div className="form-group col-md-4">
                              <label for="inputAddress" className="label_form">
                                Toilets
                              </label>
                              <input
                                type="text"
                                className="form-control bg-input-custom"
                                name="toilets"
                                value={developData.toilets}
                                onChange={(e) => developChange(e)}
                              />
                            </div>
                            <div className="form-group col-md-4  mx-1">
                              <label for="inputAddress" className="label_form">
                                Post Office
                              </label>
                              <input
                                type="text"
                                className="form-control bg-input-custom"
                                name="post"
                                value={developData.post}
                                onChange={(e) => developChange(e)}
                              />
                            </div>
                            <div className="form-group col-md-4">
                              <label for="inputAddress" className="label_form">
                                Telephone
                              </label>
                              <input
                                type="text"
                                className="form-control bg-input-custom"
                                name="telephone"
                                value={developData.telephone}
                                onChange={(e) => developChange(e)}
                              />
                            </div>
                          </div>
                          <div className="form-row d-flex devlop">
                            <div className="form-group col-md-4">
                              <label for="inputAddress" className="label_form">
                                Health Centre
                              </label>
                              <input
                                type="text"
                                className="form-control bg-input-custom"
                                name="health"
                                value={developData.health}
                                onChange={(e) => developChange(e)}
                              />
                            </div>
                            <div className="form-group col-md-4 mx-1">
                              <label for="inputAddress" className="label_form">
                                College
                              </label>
                              <input
                                type="text"
                                className="form-control bg-input-custom"
                                name="college"
                                value={developData.college}
                                onChange={(e) => developChange(e)}
                              />
                            </div>
                            <div className="form-group col-md-4">
                              <label className="label_form">Cooking Gas</label>
                              <input
                                type="text"
                                className="form-control bg-input-custom"
                                value={developData.gas}
                                name="gas"
                                onChange={(e) => developChange(e)}
                              />
                            </div>
                          </div>

                          <div className="form-row d-flex">
                            <div className="form-group col-md-4">
                              <label className="label_form">Water</label>
                              <input
                                type="text"
                                className="form-control bg-input-custom"
                                name="water"
                                value={developData.water}
                                onChange={(e) => developChange(e)}
                              />
                            </div>
                            <div className="form-group col-md-4 mx-1">
                              <label className="label_form ">Bank</label>
                              <input
                                type="text"
                                className="form-control bg-input-custom"
                                name="bank"
                                value={developData.bank}
                                onChange={(e) => developChange(e)}
                              />
                            </div>
                            <div className="form-group col-md-4">
                              <label className="label_form">
                                Oldest School
                              </label>
                              <input
                                type="text"
                                className="form-control bg-input-custom"
                                name="school"
                                value={developData.school}
                                onChange={(e) => developChange(e)}
                              />
                            </div>
                          </div>
                          <div className="form-row d-flex">
                            <div className="form-group col-md-4">
                              <label className="label_form">Drainage</label>
                              <input
                                type="text"
                                className="form-control bg-input-custom"
                                name="drainageSystem"
                                value={developData.drainageSystem}
                                onChange={(e) => developChange(e)}
                              />
                            </div>
                            <div className="form-group col-md-4 mx-1">
                              <label className="label_form ">Tab Water</label>
                              <input
                                type="text"
                                className="form-control bg-input-custom"
                                name="tabWater"
                                value={developData.tabWater}
                                onChange={(e) => developChange(e)}
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  ) : null}

                  {/* ----------------------------------------------FESTIVAL---------------------------------------------------- */}

                  {selectedTopic === "festivals" ? (
                    <>
                      <div className="col-md-12 col-sm-12 p-1">
                        <div>
                          <form>
                            <div className="add-village-container-sub-field">
                              <div className="main_heading ">
                                Fill The Details of Village
                                <hr></hr>
                              </div>

                              {/* mapping through the state data */}
                              {fairsAndFestivals.map((item, key) => {
                                return (
                                  <div className="position-relative">
                                    <div className="d-flex">
                                      <div className="form-group pt-3 ">
                                        <label className="label_form ">
                                          Name of Festival
                                        </label>
                                        <div className="d-flex align-center">
                                          <input
                                            type="text"
                                            className="form-control text-area bg-input-custom"
                                            name="title"
                                            value={item.title}
                                            onChange={(e) =>
                                              handleFairAndFestivals(e, key)
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="form-group">
                                      <label className="label_form ">
                                        Description
                                      </label>
                                      <div className="d-flex align-center">
                                        <textarea
                                          type="text"
                                          className="form-control text-area2 bg-input-custom"
                                          id="inputCity"
                                          value={item.description}
                                          name="description"
                                          rows="5"
                                          cols="15"
                                          onChange={(e) =>
                                            handleFairAndFestivals(e, key)
                                          }
                                        ></textarea>
                                      </div>
                                    </div>

                                    <div className="form-row d-flex">
                                      <div className="form-group col-md-6">
                                        <label
                                          for="inputAddress"
                                          className="label_form"
                                        >
                                          Type Of Festival
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control bg-input-custom"
                                          name="type_of_festival"
                                          value={item.type_of_festival}
                                          onChange={(e) =>
                                            handleFairAndFestivals(e, key)
                                          }
                                        />
                                      </div>
                                      <div className="form-group col-md-6 mx-1">
                                        <label
                                          for="inputAddress"
                                          className="label_form "
                                        >
                                          Who Organizes Festival?
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control bg-input-custom extra"
                                          name="who_organise_festival"
                                          value={item.who_organise_festival}
                                          onChange={(e) =>
                                            handleFairAndFestivals(e, key)
                                          }
                                        />
                                      </div>
                                    </div>

                                    <div className="form-row devlop">
                                      <div className="form-group col-md-6">
                                        <label
                                          for="inputAddress"
                                          className="label_form"
                                        >
                                          {" "}
                                          Who Takes Part In Festival?
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control bg-input-custom"
                                          name="who_take_part_in_festival"
                                          value={item.who_take_part_in_festival}
                                          onChange={(e) =>
                                            handleFairAndFestivals(e, key)
                                          }
                                        />
                                      </div>
                                    </div>

                                    {/* dynamic fields image */}
                                    <label className="label_form ">
                                      Upload Media(Only JPG/PNG)
                                    </label>
                                    {festivalsMedia[key]?.media.map(
                                      (item, subIndex) => {
                                        return (
                                          <div
                                            className="form-row d-flex"
                                            key={key}
                                          >
                                            <div className="form-group col-10 pt-3">
                                              <div className="position-relative">
                                                <textarea
                                                  className="form-control d-flex bg-input-custom "
                                                  value={item.caption}
                                                  name="caption"
                                                  onChange={(e) =>
                                                    handleCaptionChange(
                                                      key,
                                                      e,
                                                      subIndex,
                                                      "festivals"
                                                    )
                                                  }
                                                  rows="5"
                                                />

                                                {/* delete btn wont show for first value */}
                                                {subIndex != 0 ? (
                                                  <img
                                                    src={MinusIcon}
                                                    alt="PlusIcon"
                                                    className="position-absolute delete-button-custom"
                                                    onClick={() =>
                                                      removeFormFieldsMedia(
                                                        key,
                                                        subIndex,
                                                        "festivals"
                                                      )
                                                    }
                                                  />
                                                ) : (
                                                  <></>
                                                )}
                                              </div>

                                              {/* showing file if available */}
                                              {festivalsMedia[key].media[
                                                subIndex
                                              ].media_name != "" ? (
                                                festivalsMedia[key].media[
                                                  subIndex
                                                ].media_name
                                              ) : (
                                                <>
                                                  {uploadingToFirebase
                                                    ? "uploading, please wait"
                                                    : null}
                                                </>
                                              )}
                                              {festivalsMedia[key].media[
                                                subIndex
                                              ].media_name != "" ? (
                                                <span
                                                  className="mx-4"
                                                  role="button"
                                                  onClick={() =>
                                                    removeFileMedia(
                                                      key,
                                                      subIndex,
                                                      "festivals"
                                                    )
                                                  }
                                                >
                                                  X
                                                </span>
                                              ) : (
                                                " "
                                              )}
                                            </div>
                                            <div className="form-group col-2">
                                              <input
                                                type="file"
                                                name="file"
                                                id="custom-files-hidden"
                                                ref={filepick}
                                                onChange={(e) =>
                                                  onFileChange(e, "festivals")
                                                }
                                                onClick={(event) => {
                                                  event.target.value = null;
                                                }}
                                                hidden
                                                className="hidden"
                                                accept="image/jpg, image/png, image/jpeg"
                                              />{" "}
                                              <label
                                                onClick={() => {
                                                  setMediaIndex(key);
                                                  setMediaSubIndex(subIndex);
                                                }}
                                                for="custom-files-hidden"
                                                className="btn_browse "
                                                role="button"
                                              >
                                                Browse
                                              </label>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}

                                    <div
                                      className="dynamic-button-add dynamic-button-add-temp"
                                      onClick={() =>
                                        addFormFieldsMedia(key, "festivals")
                                      }
                                    >
                                      Add More
                                      <img
                                        className="plus-icon"
                                        src={PlusIcon}
                                        alt="PlusIcon"
                                      />
                                    </div>

                                    {/* if the index is one, then dont show delete button */}
                                    {key != 0 ? (
                                      <button
                                        className="add-new-field-btn img-custom-minus"
                                        onClick={() =>
                                          handleRemoveFairAndFestivals(key)
                                        }
                                      >
                                        Remove
                                      </button>
                                    ) : null}
                                    <hr
                                      //couldn't help myself
                                      style={{
                                        opacity: 0.1,
                                      }}
                                      className="mt-4"
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </form>
                        </div>
                        <div>
                          <button
                            className="add-new-field-btn"
                            onClick={handleAddFairAndFestivals}
                          >
                            Add More
                          </button>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {/* ----------------------------------------------TRADITIONAL FOOD---------------------------------------------------- */}

                  {selectedTopic === "traditional_food" ? (
                    <>
                      <div className="col-md-12 col-sm-12 p-1">
                        <div>
                          <form>
                            <div className="add-village-container-sub-field">
                              <div className="main_heading ">
                                Fill The Details of Village
                                <hr></hr>
                              </div>
                              {/* mapping through the state data */}
                              {traditionalFood.map((item, key) => {
                                return (
                                  <div className="position-relative">
                                    <div className="d-flex pt-4">
                                      <div className="form-group ">
                                        <label className="label_form  d-flex">
                                          Name
                                        </label>
                                        <div className="d-flex align-center">
                                          <input
                                            type="text"
                                            className="form-control bg-input-custom"
                                            name="title"
                                            value={item.title}
                                            onChange={(e) =>
                                              handleTraditionalFood(e, key)
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <label className="label_form  d-flex">
                                        Description
                                      </label>
                                      <div className="d-flex align-center">
                                        <textarea
                                          className="form-control text-area2 bg-input-custom"
                                          name="description"
                                          value={item.description}
                                          id="inputCity"
                                          rows="5"
                                          onChange={(e) =>
                                            handleTraditionalFood(e, key)
                                          }
                                        ></textarea>
                                      </div>
                                    </div>
                                    {/* dynamic fields image */}
                                    <label className="label_form ">
                                      Upload Media(Only JPG/PNG)
                                    </label>
                                    {traditionalFoodMedia[key]?.media.map(
                                      (item, subIndex) => {
                                        return (
                                          <div
                                            className={"form-row d-flex"}
                                            key={key}
                                          >
                                            <div
                                              className={
                                                "form-group col-10 pt-3"
                                              }
                                            >
                                              <div className="position-relative">
                                                <textarea
                                                  className="form-control d-flex bg-input-custom "
                                                  value={item.caption}
                                                  name="caption"
                                                  onChange={(e) =>
                                                    handleCaptionChange(
                                                      key,
                                                      e,
                                                      subIndex,
                                                      "traditionalFood"
                                                    )
                                                  }
                                                  rows="5"
                                                />

                                                {/* delete btn wont show for first value */}
                                                {subIndex != 0 ? (
                                                  <img
                                                    src={MinusIcon}
                                                    alt="PlusIcon"
                                                    className="position-absolute delete-button-custom"
                                                    onClick={() =>
                                                      removeFormFieldsMedia(
                                                        key,
                                                        subIndex,
                                                        "traditionalFood"
                                                      )
                                                    }
                                                  />
                                                ) : (
                                                  <></>
                                                )}
                                              </div>

                                              {/* showing file if available */}
                                              {traditionalFoodMedia[key].media[
                                                subIndex
                                              ].media_name != "" ? (
                                                traditionalFoodMedia[key].media[
                                                  subIndex
                                                ].media_name
                                              ) : (
                                                <>
                                                  {uploadingToFirebase
                                                    ? "uploading, please wait"
                                                    : null}
                                                </>
                                              )}
                                              {traditionalFoodMedia[key].media[
                                                subIndex
                                              ].media_name != "" ? (
                                                <span
                                                  className="mx-4"
                                                  role="button"
                                                  onClick={() =>
                                                    removeFileMedia(
                                                      key,
                                                      subIndex,
                                                      "traditionalFood"
                                                    )
                                                  }
                                                >
                                                  X
                                                </span>
                                              ) : (
                                                " "
                                              )}
                                            </div>
                                            <div className="form-group col-2">
                                              <input
                                                type="file"
                                                name="file"
                                                id="custom-files-hidden"
                                                ref={filepick}
                                                onChange={(e) =>
                                                  onFileChange(
                                                    e,
                                                    "traditionalFood"
                                                  )
                                                }
                                                onClick={(event) => {
                                                  event.target.value = null;
                                                }}
                                                hidden
                                                className="hidden"
                                                accept="image/jpg, image/png, image/jpeg"
                                              />{" "}
                                              <label
                                                onClick={() => {
                                                  setMediaIndex(key);
                                                  setMediaSubIndex(subIndex);
                                                }}
                                                for="custom-files-hidden"
                                                className="btn_browse "
                                                role="button"
                                              >
                                                Browse
                                              </label>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}

                                    <div
                                      className="dynamic-button-add dynamic-button-add-temp"
                                      onClick={() =>
                                        addFormFieldsMedia(
                                          key,
                                          "traditionalFood"
                                        )
                                      }
                                    >
                                      Add More
                                      <img
                                        className="plus-icon"
                                        src={PlusIcon}
                                        alt="PlusIcon"
                                      />
                                    </div>
                                    {/* if the index is one, then dont show delete button */}
                                    {key != 0 ? (
                                      <button
                                        className="remove-field-btn img-custom-minus"
                                        onClick={() =>
                                          handleRemoveTraditionalFood(key)
                                        }
                                      >
                                        Remove
                                      </button>
                                    ) : null}
                                    <hr
                                      //couldn't help myself
                                      style={{
                                        opacity: 0.1,
                                      }}
                                      className="mt-4"
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </form>
                          <div>
                            <button
                              className="add-new-field-btn"
                              onClick={handleAddTraditionalFood}
                            >
                              Add More
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {/* ----------------------------------------------BELIEFS---------------------------------------------------- */}

                  {selectedTopic === "belief" ? (
                    <>
                      <div className="col-md-12 col-sm-12 p-1">
                        <div>
                          <form>
                            <div className="add-village-container-sub-field">
                              <div className="main_heading ">
                                Fill The Details of Village
                                <hr></hr>
                              </div>

                              {/* mapping through the state data */}
                              {belief.map((item, key) => {
                                return (
                                  <div className="position-relative">
                                    <div className="d-flex pt-4">
                                      <div className="form-group ">
                                        <label className="label_form  d-flex">
                                          Name
                                        </label>
                                        <div className="d-flex align-center">
                                          <input
                                            type="text"
                                            className="form-control bg-input-custom"
                                            name="title"
                                            value={item.title}
                                            onChange={(e) =>
                                              handleBelief(e, key)
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="form-row d-flex devlop">
                                      <div className="form-group col-md-6">
                                        <label className="label_form   ">
                                          Type{" "}
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control bg-input-custom"
                                          value={item.type}
                                          name="type"
                                          onChange={(e) => handleBelief(e, key)}
                                        />
                                      </div>
                                      <div className="form-group col-md-6 mx-1">
                                        <label className="label_form   ">
                                          Belief Related To{" "}
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control bg-input-custom"
                                          name="relatedTo"
                                          value={item.relatedTo}
                                          onChange={(e) => handleBelief(e, key)}
                                        />
                                      </div>
                                    </div>

                                    <div className="form-group">
                                      <label className="label_form  d-flex">
                                        Description
                                      </label>
                                      <div className="d-flex align-center">
                                        <textarea
                                          className="form-control text-area2 bg-input-custom"
                                          name="description"
                                          value={item.description}
                                          id="inputCity"
                                          rows="5"
                                          onChange={(e) => handleBelief(e, key)}
                                        ></textarea>
                                      </div>
                                    </div>
                                    {/* dynamic fields image */}
                                    <label className="label_form ">
                                      Upload Media(Only JPG/PNG)
                                    </label>
                                    {beliefMedia[key]?.media.map(
                                      (item, subIndex) => {
                                        return (
                                          <div
                                            className={"form-row d-flex"}
                                            key={key}
                                          >
                                            <div
                                              className={
                                                "form-group col-10 pt-3"
                                              }
                                            >
                                              <div className="position-relative">
                                                <textarea
                                                  className="form-control d-flex bg-input-custom "
                                                  value={item.caption}
                                                  name="caption"
                                                  onChange={(e) =>
                                                    handleCaptionChange(
                                                      key,
                                                      e,
                                                      subIndex,
                                                      "belief"
                                                    )
                                                  }
                                                  rows="5"
                                                />

                                                {/* delete btn wont show for first value */}
                                                {subIndex != 0 ? (
                                                  <img
                                                    src={MinusIcon}
                                                    alt="PlusIcon"
                                                    className="position-absolute delete-button-custom"
                                                    onClick={() =>
                                                      removeFormFieldsMedia(
                                                        key,
                                                        subIndex,
                                                        "belief"
                                                      )
                                                    }
                                                  />
                                                ) : (
                                                  <></>
                                                )}
                                              </div>

                                              {/* showing file if available */}
                                              {beliefMedia[key].media[subIndex]
                                                .media_name != "" ? (
                                                beliefMedia[key].media[subIndex]
                                                  .media_name
                                              ) : (
                                                <>
                                                  {uploadingToFirebase
                                                    ? "uploading, please wait"
                                                    : null}
                                                </>
                                              )}
                                              {beliefMedia[key].media[subIndex]
                                                .media_name != "" ? (
                                                <span
                                                  className="mx-4"
                                                  role="button"
                                                  onClick={() =>
                                                    removeFileMedia(
                                                      key,
                                                      subIndex,
                                                      "belief"
                                                    )
                                                  }
                                                >
                                                  X
                                                </span>
                                              ) : (
                                                " "
                                              )}
                                            </div>
                                            <div className="form-group col-2">
                                              <input
                                                type="file"
                                                name="file"
                                                id="custom-files-hidden"
                                                ref={filepick}
                                                onChange={(e) =>
                                                  onFileChange(e, "belief")
                                                }
                                                onClick={(event) => {
                                                  event.target.value = null;
                                                }}
                                                hidden
                                                className="hidden"
                                                accept="image/jpg, image/png, image/jpeg"
                                              />{" "}
                                              <label
                                                onClick={() => {
                                                  setMediaIndex(key);
                                                  setMediaSubIndex(subIndex);
                                                }}
                                                for="custom-files-hidden"
                                                className="btn_browse "
                                                role="button"
                                              >
                                                Browse
                                              </label>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}

                                    <div
                                      className="dynamic-button-add dynamic-button-add-temp"
                                      onClick={() =>
                                        addFormFieldsMedia(key, "belief")
                                      }
                                    >
                                      Add More
                                      <img
                                        className="plus-icon"
                                        src={PlusIcon}
                                        alt="PlusIcon"
                                      />
                                    </div>
                                    {/* if the index is one, then dont show delete button */}
                                    {key != 0 ? (
                                      <button
                                        className="remove-field-btn img-custom-minus"
                                        onClick={() => handleRemoveBelief(key)}
                                      >
                                        Remove
                                      </button>
                                    ) : null}
                                    <hr
                                      //couldn't help myself
                                      style={{
                                        opacity: 0.1,
                                      }}
                                      className="mt-4"
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </form>
                        </div>
                        <div>
                          <button
                            className="add-new-field-btn"
                            onClick={handleAddBelief}
                          >
                            Add More
                          </button>
                        </div>
                      </div>
                    </>
                  ) : null}

                  {/* ----------------------------------------------heritage_places---------------------------------------------------- */}

                  {selectedTopic === "heritage_places" ? (
                    <>
                      <div className="col-md-12 col-sm-12 p-1">
                        <div>
                          <form>
                            <div className="add-village-container-sub-field">
                              <div className="main_heading ">
                                Fill The Details of Village
                                <hr></hr>
                              </div>
                              {/* mapping through the state data */}
                              {heritagePlaces.map((item, key) => {
                                return (
                                  <div className="position-relative">
                                    <div className="d-flex pt-4">
                                      <div className="form-group ">
                                        <label className="label_form  d-flex">
                                          Name
                                        </label>
                                        <div className="d-flex align-center">
                                          <input
                                            type="text"
                                            className="form-control bg-input-custom"
                                            name="title"
                                            value={item.title}
                                            onChange={(e) =>
                                              handleHeritagePlaces(e, key)
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <label className="label_form  d-flex">
                                        Description
                                      </label>
                                      <div className="d-flex align-center">
                                        <textarea
                                          className="form-control text-area2 bg-input-custom"
                                          name="description"
                                          value={item.description}
                                          id="inputCity"
                                          rows="5"
                                          onChange={(e) =>
                                            handleHeritagePlaces(e, key)
                                          }
                                        ></textarea>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <label className="label_form   ">
                                        Story Associated
                                      </label>
                                      <div className="d-flex align-center">
                                        <textarea
                                          type="text"
                                          className="form-control text-area2 bg-input-custom"
                                          name="story_associate_with_heritage"
                                          value={
                                            item.story_associate_with_heritage
                                          }
                                          id="inputCity"
                                          rows="5"
                                          cols="15"
                                          onChange={(e) =>
                                            handleHeritagePlaces(e, key)
                                          }
                                        ></textarea>
                                      </div>
                                    </div>

                                    <div className="form-row d-flex devlop">
                                      <div className="form-group col-md-6">
                                        <label className="label_form   ">
                                          Deities{" "}
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control bg-input-custom"
                                          value={
                                            item.deities_related_to_heritage
                                          }
                                          name="deities_related_to_heritage"
                                          onChange={(e) =>
                                            handleHeritagePlaces(e, key)
                                          }
                                        />
                                      </div>
                                      <div className="form-group col-md-6 mx-1">
                                        <label className="label_form   ">
                                          Ritual{" "}
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control bg-input-custom"
                                          name="ritual_worship_related_to_heritage"
                                          value={
                                            item.ritual_worship_related_to_heritage
                                          }
                                          onChange={(e) =>
                                            handleHeritagePlaces(e, key)
                                          }
                                        />
                                      </div>
                                    </div>

                                    <div className="form-row d-flex devlop">
                                      <div className="form-group col-md-4 ">
                                        <label className="label_form   ">
                                          Type Of Heritage
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control bg-input-custom"
                                          name="type"
                                          value={item.type}
                                          onChange={(e) =>
                                            handleHeritagePlaces(e, key)
                                          }
                                        />
                                      </div>
                                    </div>

                                    {/* dynamic fields image */}
                                    <label className="label_form ">
                                      Upload Media(Only JPG/PNG)
                                    </label>
                                    {heritagePlacesMedia[key]?.media.map(
                                      (item, subIndex) => {
                                        return (
                                          <div
                                            className={"form-row d-flex"}
                                            key={key}
                                          >
                                            <div
                                              className={
                                                "form-group col-10 pt-3"
                                              }
                                            >
                                              <div className="position-relative">
                                                <textarea
                                                  className="form-control d-flex bg-input-custom "
                                                  value={item.caption}
                                                  name="caption"
                                                  onChange={(e) =>
                                                    handleCaptionChange(
                                                      key,
                                                      e,
                                                      subIndex,
                                                      "heritagePlaces"
                                                    )
                                                  }
                                                  rows="5"
                                                />

                                                {/* delete btn wont show for first value */}
                                                {subIndex != 0 ? (
                                                  <img
                                                    src={MinusIcon}
                                                    alt="MinusIcon"
                                                    className="position-absolute delete-button-custom"
                                                    onClick={() =>
                                                      removeFormFieldsMedia(
                                                        key,
                                                        subIndex,
                                                        "heritagePlaces"
                                                      )
                                                    }
                                                  />
                                                ) : (
                                                  <></>
                                                )}
                                              </div>

                                              {/* showing file if available */}
                                              {heritagePlacesMedia[key].media[
                                                subIndex
                                              ].media_name != "" ? (
                                                heritagePlacesMedia[key].media[
                                                  subIndex
                                                ].media_name
                                              ) : (
                                                <>
                                                  {uploadingToFirebase
                                                    ? "uploading, please wait"
                                                    : null}
                                                </>
                                              )}
                                              {heritagePlacesMedia[key].media[
                                                subIndex
                                              ].media_name != "" ? (
                                                <span
                                                  className="mx-4"
                                                  role="button"
                                                  onClick={() =>
                                                    removeFileMedia(
                                                      key,
                                                      subIndex,
                                                      "heritagePlaces"
                                                    )
                                                  }
                                                >
                                                  X
                                                </span>
                                              ) : (
                                                " "
                                              )}
                                            </div>
                                            <div className="form-group col-2">
                                              <input
                                                type="file"
                                                name="file"
                                                id="custom-files-hidden"
                                                ref={filepick}
                                                onChange={(e) =>
                                                  onFileChange(
                                                    e,
                                                    "heritagePlaces"
                                                  )
                                                }
                                                onClick={(event) => {
                                                  event.target.value = null;
                                                }}
                                                hidden
                                                className="hidden"
                                                accept="image/jpg, image/png, image/jpeg"
                                              />{" "}
                                              <label
                                                onClick={() => {
                                                  setMediaIndex(key);
                                                  setMediaSubIndex(subIndex);
                                                }}
                                                for="custom-files-hidden"
                                                className="btn_browse "
                                                role="button"
                                              >
                                                Browse
                                              </label>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}

                                    <div
                                      className="dynamic-button-add dynamic-button-add-temp"
                                      onClick={() =>
                                        addFormFieldsMedia(
                                          key,
                                          "heritagePlaces"
                                        )
                                      }
                                    >
                                      Add More
                                      <img
                                        className="plus-icon"
                                        src={PlusIcon}
                                        alt="PlusIcon"
                                      />
                                    </div>

                                    {/* if the index is one, then dont show delete button */}
                                    {key != 0 ? (
                                      <button
                                        className="add-new-field-btn img-custom-minus"
                                        onClick={() =>
                                          handleRemoveHeritagePlaces(key)
                                        }
                                      >
                                        Remove
                                      </button>
                                    ) : null}
                                    <hr
                                      //couldn't help myself
                                      style={{
                                        opacity: 0.1,
                                      }}
                                      className="mt-4"
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </form>
                        </div>
                        <div>
                          <button
                            className="add-new-field-btn"
                            onClick={handleAddHeritagePlaces}
                          >
                            Add More
                          </button>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {/* ----------------------------------------------ORNAMENTS---------------------------------------------------- */}
                  {selectedTopic === "ornaments" ? (
                    <div className="col-md-12 col-sm-12 p-1">
                      <div>
                        <form>
                          <div className="add-village-container-sub-field">
                            <div className="main_heading ">
                              Fill The Details of Village
                              <hr></hr>
                            </div>
                            {ornament.map((item, key) => {
                              return (
                                <div className="position-relative">
                                  <div className="d-flex pt-4">
                                    <div className="form-group ">
                                      <label className="label_form  d-flex">
                                        Name
                                      </label>
                                      <div className="d-flex align-center">
                                        <input
                                          type="text"
                                          className="form-control bg-input-custom"
                                          name="title"
                                          value={item.title}
                                          onChange={(e) =>
                                            handleOrnament(e, key)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-group">
                                    <label className="label_form  d-flex">
                                      Description
                                    </label>
                                    <div className="d-flex align-center">
                                      <textarea
                                        className="form-control text-area2 bg-input-custom"
                                        name="description"
                                        value={item.description}
                                        rows="5"
                                        onChange={(e) => handleOrnament(e, key)}
                                      ></textarea>
                                    </div>
                                  </div>
                                  {/* dynamic fields image */}
                                  <label className="label_form ">
                                    Upload Media(Only JPG/PNG)
                                  </label>
                                  {ornamentsMedia[key]?.media.map(
                                    (item, subIndex) => {
                                      return (
                                        <div
                                          className={"form-row d-flex"}
                                          key={key}
                                        >
                                          <div
                                            className={"form-group col-10 pt-3"}
                                          >
                                            <div className="position-relative">
                                              <textarea
                                                className="form-control d-flex bg-input-custom "
                                                value={item.caption}
                                                name="caption"
                                                onChange={(e) =>
                                                  handleCaptionChange(
                                                    key,
                                                    e,
                                                    subIndex,
                                                    "ornaments"
                                                  )
                                                }
                                                rows="5"
                                              />

                                              {/* delete btn wont show for first value */}
                                              {subIndex != 0 ? (
                                                <img
                                                  src={MinusIcon}
                                                  alt="MinusIcon"
                                                  className="position-absolute delete-button-custom"
                                                  onClick={() =>
                                                    removeFormFieldsMedia(
                                                      key,
                                                      subIndex,
                                                      "ornaments"
                                                    )
                                                  }
                                                />
                                              ) : (
                                                <></>
                                              )}
                                            </div>

                                            {/* showing file if available */}
                                            {ornamentsMedia[key].media[subIndex]
                                              .media_name != "" ? (
                                              ornamentsMedia[key].media[
                                                subIndex
                                              ].media_name
                                            ) : (
                                              <>
                                                {uploadingToFirebase
                                                  ? "uploading, please wait"
                                                  : null}
                                              </>
                                            )}

                                            {ornamentsMedia[key].media[subIndex]
                                              .media_name != "" ? (
                                              <span
                                                className="mx-4"
                                                role="button"
                                                onClick={() =>
                                                  removeFileMedia(
                                                    key,
                                                    subIndex,
                                                    "ornaments"
                                                  )
                                                }
                                              >
                                                X
                                              </span>
                                            ) : (
                                              " "
                                            )}
                                          </div>
                                          <div className="form-group col-2">
                                            <input
                                              type="file"
                                              name="file"
                                              id="custom-files-hidden"
                                              ref={filepick}
                                              onChange={(e) =>
                                                onFileChange(e, "ornaments")
                                              }
                                              onClick={(event) => {
                                                event.target.value = null;
                                              }}
                                              hidden
                                              className="hidden"
                                              accept="image/jpg, image/png, image/jpeg"
                                            />{" "}
                                            <label
                                              onClick={() => {
                                                setMediaIndex(key);
                                                setMediaSubIndex(subIndex);
                                              }}
                                              for="custom-files-hidden"
                                              className="btn_browse "
                                              role="button"
                                            >
                                              Browse
                                            </label>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}

                                  <div
                                    className="dynamic-button-add dynamic-button-add-temp"
                                    onClick={() =>
                                      addFormFieldsMedia(key, "ornaments")
                                    }
                                  >
                                    Add More
                                    <img
                                      className="plus-icon"
                                      src={PlusIcon}
                                      alt="PlusIcon"
                                    />
                                  </div>

                                  {/* if the index is one, then dont show delete button */}
                                  {key != 0 ? (
                                    <button
                                      className="add-new-field-btn img-custom-minus"
                                      onClick={() => handleRemoveOrnament(key)}
                                    >
                                      Remove
                                    </button>
                                  ) : null}
                                  <hr
                                    //couldn't help myself
                                    style={{ opacity: 0.1 }}
                                    className="mt-4"
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </form>
                      </div>
                      <div>
                        <button
                          className="add-new-field-btn"
                          onClick={handleAddOrnament}
                        >
                          Add More
                        </button>
                      </div>
                    </div>
                  ) : null}

                  {/* ----------------------------------------------TRADITIONAL DRESS---------------------------------------------------- */}

                  {selectedTopic === "traditional_dress" ? (
                    <>
                      <div className="col-md-12 col-sm-12 p-1">
                        <div>
                          <form>
                            <div className="add-village-container-sub-field">
                              <div className="main_heading ">
                                Fill The Details of Village
                                <hr></hr>
                              </div>
                              {traditionalDress.map((item, key) => {
                                return (
                                  <div className="position-relative">
                                    <div className="d-flex pt-4">
                                      <div className="form-group ">
                                        <label className="label_form  d-flex">
                                          {" "}
                                          Name{" "}
                                        </label>
                                        <div className="d-flex align-center">
                                          <input
                                            type="text"
                                            className="form-control bg-input-custom"
                                            name="title"
                                            value={item.title}
                                            onChange={(e) =>
                                              handleTraditionalDress(e, key)
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <label className="label_form  d-flex">
                                        {" "}
                                        Description{" "}
                                      </label>
                                      <div className="d-flex align-center">
                                        <textarea
                                          className="form-control text-area2 bg-input-custom"
                                          name="description"
                                          value={item.description}
                                          id="inputCity"
                                          rows="5"
                                          onChange={(e) =>
                                            handleTraditionalDress(e, key)
                                          }
                                        ></textarea>
                                      </div>
                                    </div>

                                    {/* dynamic fields image */}
                                    <label className="label_form ">
                                      Upload Media(Only JPG/PNG)
                                    </label>
                                    {traditionalDressMedia[key]?.media.map(
                                      (item, subIndex) => {
                                        return (
                                          <div
                                            className={"form-row d-flex"}
                                            key={key}
                                          >
                                            <div
                                              className={
                                                "form-group col-10 pt-3"
                                              }
                                            >
                                              <div className="position-relative">
                                                <textarea
                                                  className="form-control d-flex bg-input-custom "
                                                  value={item?.caption}
                                                  name="caption"
                                                  onChange={(e) =>
                                                    handleCaptionChange(
                                                      key,
                                                      e,
                                                      subIndex,
                                                      "traditionalDress"
                                                    )
                                                  }
                                                  rows="5"
                                                />

                                                {/* delete btn wont show for first value */}
                                                {subIndex != 0 ? (
                                                  <img
                                                    src={MinusIcon}
                                                    alt="MinusIcon"
                                                    className="position-absolute delete-button-custom"
                                                    onClick={() =>
                                                      removeFormFieldsMedia(
                                                        key,
                                                        subIndex,
                                                        "traditionalDress"
                                                      )
                                                    }
                                                  />
                                                ) : (
                                                  <></>
                                                )}
                                              </div>

                                              {/* showing file if available */}
                                              {traditionalDressMedia[key].media[
                                                subIndex
                                              ].media_name != "" ? (
                                                traditionalDressMedia[key]
                                                  .media[subIndex].media_name
                                              ) : (
                                                <>
                                                  {uploadingToFirebase
                                                    ? "uploading, please wait"
                                                    : null}
                                                </>
                                              )}
                                              {traditionalDressMedia[key].media[
                                                subIndex
                                              ].media_name != "" ? (
                                                <span
                                                  className="mx-4"
                                                  role="button"
                                                  onClick={() =>
                                                    removeFileMedia(
                                                      key,
                                                      subIndex,
                                                      "traditionalDress"
                                                    )
                                                  }
                                                >
                                                  X
                                                </span>
                                              ) : (
                                                " "
                                              )}
                                            </div>
                                            <div className="form-group col-2">
                                              <input
                                                type="file"
                                                name="file"
                                                id="custom-files-hidden"
                                                ref={filepick}
                                                onChange={(e) =>
                                                  onFileChange(
                                                    e,
                                                    "traditionalDress"
                                                  )
                                                }
                                                onClick={(event) => {
                                                  event.target.value = null;
                                                }}
                                                hidden
                                                className="hidden"
                                                accept="image/jpg, image/png, image/jpeg"
                                              />{" "}
                                              <label
                                                onClick={() => {
                                                  setMediaIndex(key);
                                                  setMediaSubIndex(subIndex);
                                                }}
                                                for="custom-files-hidden"
                                                className="btn_browse "
                                                role="button"
                                              >
                                                Browse
                                              </label>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}

                                    <div
                                      className="dynamic-button-add dynamic-button-add-temp"
                                      onClick={() =>
                                        addFormFieldsMedia(
                                          key,
                                          "traditionalDress"
                                        )
                                      }
                                    >
                                      Add More
                                      <img
                                        className="plus-icon"
                                        src={PlusIcon}
                                        alt="PlusIcon"
                                      />
                                    </div>

                                    {key != 0 ? (
                                      <button
                                        className="add-new-field-btn img-custom-minus"
                                        onClick={() =>
                                          handleRemoveTraditionalDress(key)
                                        }
                                      >
                                        Remove
                                      </button>
                                    ) : null}
                                    <hr
                                      //couldn't help myself
                                      style={{ opacity: 0.1 }}
                                      className="mt-4"
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </form>{" "}
                        </div>
                        <div>
                          <button
                            className="add-new-field-btn"
                            onClick={handleAddTraditionalDress}
                          >
                            Add More
                          </button>
                        </div>
                      </div>
                    </>
                  ) : null}

                  {/* ----------------------------------------------prominent_artists---------------------------------------------------- */}

                  {selectedTopic === "prominent_artists" ? (
                    <>
                      <div className="col-md-12 col-sm-12 p-1">
                        <div>
                          <form>
                            <div className="add-village-container-sub-field">
                              <div className="main_heading ">
                                Fill The Details of Village
                                <hr></hr>
                              </div>

                              {prominentArtists.map((item, key) => {
                                return (
                                  <div className="position-relative">
                                    <div className="d-flex pt-4">
                                      <div className="form-group ">
                                        <label className="label_form ">
                                          Name
                                        </label>
                                        <div className="d-flex align-center">
                                          <input
                                            type="text"
                                            className="form-control bg-input-custom"
                                            name="name"
                                            value={item?.name}
                                            onChange={(e) =>
                                              handleProminentArtists(e, key)
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="form-row d-flex devlop">
                                      <div className="form-group col-md-6">
                                        <label className="label_form ">
                                          Expertise Of Category
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control bg-input-custom"
                                          value={item.expertise_of_category}
                                          name="expertise_of_category"
                                          onChange={(e) =>
                                            handleProminentArtists(e, key)
                                          }
                                        />
                                      </div>
                                      <div className="form-group col-md-6 mx-1">
                                        <label className="label_form">
                                          Expertise Of Skill
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control bg-input-custom"
                                          name="expertise_of_skills"
                                          value={item?.expertise_of_skills}
                                          onChange={(e) =>
                                            handleProminentArtists(e, key)
                                          }
                                        />
                                      </div>
                                    </div>

                                    <div className="form-row d-flex devlop">
                                      <div className="form-group col-md-6">
                                        <label className="label_form   ">
                                          ID Card
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control bg-input-custom"
                                          value={item.id_card_number}
                                          name="id_card_number"
                                          onChange={(e) =>
                                            handleProminentArtists(e, key)
                                          }
                                        />
                                      </div>
                                      <div className="form-group col-md-6   mx-1">
                                        <label className="label_form   ">
                                          Address
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control bg-input-custom"
                                          name="address"
                                          value={item.address}
                                          onChange={(e) =>
                                            handleProminentArtists(e, key)
                                          }
                                        />
                                      </div>
                                    </div>

                                    <div className="form-row d-flex devlop">
                                      <div className="form-group col-md-6">
                                        <label className="label_form   ">
                                          Age
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control bg-input-custom"
                                          value={item.age}
                                          name="age"
                                          onChange={(e) =>
                                            handleProminentArtists(e, key)
                                          }
                                        />
                                      </div>
                                    </div>

                                    <div className="form-row d-flex devlop">
                                      <div className="form-group col-md-6">
                                        <label className="label_form   ">
                                          Bank Account
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control bg-input-custom"
                                          value={item.bank_account_number}
                                          name="bank_account_number"
                                          onChange={(e) =>
                                            handleProminentArtists(e, key)
                                          }
                                        />
                                      </div>
                                      <div className="form-group col-md-6   mx-1">
                                        <label className="label_form  ">
                                          Account Holder Name
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control bg-input-custom"
                                          name="bank_account_holder_name"
                                          value={item.bank_account_holder_name}
                                          onChange={(e) =>
                                            handleProminentArtists(e, key)
                                          }
                                        />
                                      </div>
                                    </div>

                                    <div className="form-row d-flex devlop">
                                      <div className="form-group col-md-6 ">
                                        <label className="label_form   ">
                                          Branch Name
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control bg-input-custom"
                                          value={item.branch_name}
                                          name="branch_name"
                                          onChange={(e) =>
                                            handleProminentArtists(e, key)
                                          }
                                        />
                                      </div>
                                      <div className="form-group col-md-6   mx-1">
                                        <label className="label_form ">
                                          IFSC Code
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control bg-input-custom"
                                          name="ifsc_code"
                                          value={item.ifsc_code}
                                          onChange={(e) =>
                                            handleProminentArtists(e, key)
                                          }
                                        />
                                      </div>
                                    </div>

                                    <div className="form-row d-flex devlop">
                                      <div className="form-group col-md-6">
                                        <label className="label_form   ">
                                          Gender
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control bg-input-custom"
                                          value={item.gender}
                                          name="gender"
                                          onChange={(e) =>
                                            handleProminentArtists(e, key)
                                          }
                                        />
                                      </div>
                                      <div className="form-group col-md-6 mx-1">
                                        <label className="label_form ">
                                          Mobile Number
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control bg-input-custom"
                                          name="mobile"
                                          value={item.mobile}
                                          onChange={(e) =>
                                            handleProminentArtists(e, key)
                                          }
                                        />
                                      </div>
                                    </div>
                                    {/* dynamic fields image */}
                                    <label className="label_form ">
                                      Upload Media(Only JPG/PNG)
                                    </label>
                                    {prominentArtistsMedia[key]?.media.map(
                                      (item, subIndex) => {
                                        return (
                                          <div
                                            className={"form-row d-flex"}
                                            key={key}
                                          >
                                            <div
                                              className={
                                                "form-group col-10 pt-3"
                                              }
                                            >
                                              <div className="position-relative">
                                                <textarea
                                                  className="form-control d-flex bg-input-custom "
                                                  value={item.caption}
                                                  name="caption"
                                                  onChange={(e) =>
                                                    handleCaptionChange(
                                                      key,
                                                      e,
                                                      subIndex,
                                                      "prominentArtists"
                                                    )
                                                  }
                                                  on
                                                  rows="5"
                                                />

                                                {/* delete btn wont show for first value */}
                                                {subIndex != 0 ? (
                                                  <img
                                                    src={MinusIcon}
                                                    className="position-absolute delete-button-custom"
                                                    onClick={() =>
                                                      removeFormFieldsMedia(
                                                        key,
                                                        subIndex,
                                                        "prominentArtists"
                                                      )
                                                    }
                                                  />
                                                ) : (
                                                  <></>
                                                )}
                                              </div>

                                              {/* showing file if available */}
                                              {prominentArtistsMedia[key].media[
                                                subIndex
                                              ].media_name != "" ? (
                                                prominentArtistsMedia[key]
                                                  .media[subIndex].media_name
                                              ) : (
                                                <>
                                                  {uploadingToFirebase
                                                    ? "uploading, please wait"
                                                    : null}
                                                </>
                                              )}
                                              {prominentArtistsMedia[key].media[
                                                subIndex
                                              ].media_name != "" ? (
                                                <span
                                                  className="mx-4"
                                                  role="button"
                                                  onClick={() =>
                                                    removeFileMedia(
                                                      key,
                                                      subIndex,
                                                      "prominentArtists"
                                                    )
                                                  }
                                                >
                                                  X
                                                </span>
                                              ) : (
                                                " "
                                              )}
                                            </div>
                                            <div className="form-group col-2">
                                              <input
                                                type="file"
                                                name="file"
                                                id="custom-files-hidden"
                                                ref={filepick}
                                                onChange={(e) =>
                                                  onFileChange(
                                                    e,
                                                    "prominentArtists"
                                                  )
                                                }
                                                onClick={(event) => {
                                                  event.target.value = null;
                                                }}
                                                hidden
                                                className="hidden"
                                                accept="image/jpg, image/png, image/jpeg"
                                              />{" "}
                                              <label
                                                onClick={() => {
                                                  setMediaIndex(key);
                                                  setMediaSubIndex(subIndex);
                                                }}
                                                for="custom-files-hidden"
                                                className="btn_browse "
                                                role="button"
                                              >
                                                Browse
                                              </label>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}

                                    <div
                                      className="dynamic-button-add dynamic-button-add-temp"
                                      onClick={() =>
                                        addFormFieldsMedia(
                                          key,
                                          "prominentArtists"
                                        )
                                      }
                                    >
                                      Add More
                                      <img
                                        className="plus-icon"
                                        src={PlusIcon}
                                      />
                                    </div>

                                    {/* if the index is one, then dont show delete button */}
                                    {key != 0 ? (
                                      <button
                                        className="add-new-field-btn img-custom-minus"
                                        onClick={() =>
                                          handleRemoveProminentArtists(key)
                                        }
                                      >
                                        Remove
                                      </button>
                                    ) : null}
                                    <hr
                                      //couldn't help myself
                                      style={{
                                        opacity: 0.1,
                                      }}
                                      className="mt-4"
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </form>
                        </div>
                        <div>
                          <button
                            className="add-new-field-btn"
                            onClick={handleAddProminentArtists}
                          >
                            Add More
                          </button>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {/* ----------------------------------------------FAMOUS PERSONALITY---------------------------------------------------- */}

                  {selectedTopic === "famous_personalities" ? (
                    <>
                      <div className="col-md-12 col-sm-12 p-1">
                        <div>
                          <form>
                            <div className="add-village-container-sub-field">
                              <div className="main_heading ">
                                Fill The Details of Village
                                <hr></hr>
                              </div>
                              {famousPersonalities.map((item, key) => {
                                return (
                                  <div className="position-relative">
                                    <div className="d-flex pt-4">
                                      <div className="form-group ">
                                        <label className="label_form  d-flex">
                                          Name
                                        </label>
                                        <div className="d-flex align-center">
                                          <input
                                            type="text"
                                            className="form-control bg-input-custom"
                                            name="name_of_local_hero"
                                            value={item.name_of_local_hero}
                                            onChange={(e) =>
                                              handleFamousPersonalities(e, key)
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <label className="label_form  d-flex">
                                        Description
                                      </label>
                                      <div className="d-flex align-center">
                                        <textarea
                                          className="form-control text-area2 bg-input-custom"
                                          name="description"
                                          value={item.description}
                                          id="inputCity"
                                          rows="5"
                                          onChange={(e) =>
                                            handleFamousPersonalities(e, key)
                                          }
                                        ></textarea>
                                      </div>
                                    </div>

                                    <div className="form-row d-flex devlop">
                                      <div className="form-group col-md-6">
                                        <label className="label_form   ">
                                          Family Related To
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control bg-input-custom"
                                          value={item.family_related_to}
                                          name="family_related_to"
                                          onChange={(e) =>
                                            handleFamousPersonalities(e, key)
                                          }
                                        />
                                      </div>
                                      <div className="form-group col-md-6 mx-1">
                                        <label className="label_form   ">
                                          Personal Details
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control bg-input-custom"
                                          value={item.personal_details}
                                          name="personal_details"
                                          onChange={(e) =>
                                            handleFamousPersonalities(e, key)
                                          }
                                        />
                                      </div>
                                    </div>

                                    <div className="form-group">
                                      <label className="label_form   ">
                                        Legend Stories
                                      </label>

                                      <div className="d-flex align-center">
                                        <textarea
                                          className="form-control text-area2 bg-input-custom"
                                          type="text"
                                          name="legend_stories"
                                          value={item.legend_stories}
                                          onChange={(e) =>
                                            handleFamousPersonalities(e, key)
                                          }
                                          rows={5}
                                        ></textarea>
                                      </div>
                                    </div>
                                    {/* dynamic fields image */}
                                    <label className="label_form ">
                                      Upload Media(Only JPG/PNG)
                                    </label>
                                    {famousPersonalitiesMedia[key]?.media.map(
                                      (item, subIndex) => {
                                        return (
                                          <div
                                            className={"form-row d-flex"}
                                            key={key}
                                          >
                                            <div
                                              className={
                                                "form-group col-10 pt-3"
                                              }
                                            >
                                              <div className="position-relative">
                                                <textarea
                                                  className="form-control d-flex bg-input-custom "
                                                  value={item.caption}
                                                  name="caption"
                                                  onChange={(e) =>
                                                    handleCaptionChange(
                                                      key,
                                                      e,
                                                      subIndex,
                                                      "famousPersonalities"
                                                    )
                                                  }
                                                  rows="5"
                                                />

                                                {/* delete btn wont show for first value */}
                                                {subIndex != 0 ? (
                                                  <img
                                                    src={MinusIcon}
                                                    className="position-absolute delete-button-custom"
                                                    onClick={() =>
                                                      removeFormFieldsMedia(
                                                        key,
                                                        subIndex,
                                                        "famousPersonalities"
                                                      )
                                                    }
                                                  />
                                                ) : (
                                                  <></>
                                                )}
                                              </div>

                                              {/* showing file if available */}
                                              {famousPersonalitiesMedia[key]
                                                .media[subIndex].media_name !=
                                              "" ? (
                                                famousPersonalitiesMedia[key]
                                                  .media[subIndex].media_name
                                              ) : (
                                                <>
                                                  {uploadingToFirebase
                                                    ? "uploading, please wait"
                                                    : null}
                                                </>
                                              )}
                                              {famousPersonalitiesMedia[key]
                                                .media[subIndex].media_name !=
                                              "" ? (
                                                <span
                                                  className="mx-4"
                                                  role="button"
                                                  onClick={() =>
                                                    removeFileMedia(
                                                      key,
                                                      subIndex,
                                                      "famousPersonalities"
                                                    )
                                                  }
                                                >
                                                  X
                                                </span>
                                              ) : (
                                                " "
                                              )}
                                            </div>
                                            <div className="form-group col-2">
                                              <input
                                                type="file"
                                                name="file"
                                                id="custom-files-hidden"
                                                ref={filepick}
                                                onChange={(e) =>
                                                  onFileChange(
                                                    e,
                                                    "famousPersonalities"
                                                  )
                                                }
                                                onClick={(event) => {
                                                  event.target.value = null;
                                                }}
                                                hidden
                                                className="hidden"
                                                accept="image/jpg, image/png, image/jpeg"
                                              />{" "}
                                              <label
                                                onClick={() => {
                                                  setMediaIndex(key);
                                                  setMediaSubIndex(subIndex);
                                                }}
                                                for="custom-files-hidden"
                                                className="btn_browse "
                                                role="button"
                                              >
                                                Browse
                                              </label>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}

                                    <div
                                      className="dynamic-button-add dynamic-button-add-temp"
                                      onClick={() =>
                                        addFormFieldsMedia(
                                          key,
                                          "famousPersonalities"
                                        )
                                      }
                                    >
                                      Add More
                                      <img
                                        className="plus-icon"
                                        src={PlusIcon}
                                      />
                                    </div>

                                    {/* if the index is one, then dont show delete button */}
                                    {key != 0 ? (
                                      <button
                                        className="add-new-field-btn img-custom-minus"
                                        onClick={() =>
                                          handleRemoveFamousPersonalities(key)
                                        }
                                      >
                                        Remove
                                      </button>
                                    ) : null}
                                    <hr
                                      //couldn't help myself
                                      style={{
                                        opacity: 0.1,
                                      }}
                                      className="mt-4"
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </form>{" "}
                        </div>
                        <div>
                          <button
                            className="add-new-field-btn"
                            onClick={handleAddFamousPersonalities}
                          >
                            Add More
                          </button>
                        </div>
                      </div>
                    </>
                  ) : null}

                  {/* ----------------------------------------------ART & CRAFT---------------------------------------------------- */}
                  {selectedTopic === "traditional_art" ? (
                    <>
                      <div className="mx-1 my-1">
                        <div>
                          <div>
                            <form>
                              <div className="add-village-container-sub-field">
                                <div className="main_heading ">
                                  Fill The Details of Village
                                  <hr></hr>
                                </div>
                                {traditionalArtAndCraft.map((item, key) => {
                                  return (
                                    <div className="position-relative">
                                      <div className="d-flex ">
                                        <div className="form-group pt-4">
                                          <label className="label_form ">
                                            Name of Art
                                          </label>
                                          <div className="d-flex align-center">
                                            <input
                                              type="text"
                                              className="form-control text-area bg-input-custom"
                                              name="name_of_art"
                                              value={item.name_of_art}
                                              onChange={(e) =>
                                                handleTraditionalArtAndCraft(
                                                  e,
                                                  key
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="form-group">
                                        <label className="label_form ">
                                          Description
                                        </label>
                                        <div className="d-flex align-center">
                                          <textarea
                                            type="text"
                                            className="form-control text-area2 bg-input-custom"
                                            id="inputCity"
                                            name="description"
                                            value={item.description}
                                            rows="5"
                                            cols="15"
                                            onChange={(e) =>
                                              handleTraditionalArtAndCraft(
                                                e,
                                                key
                                              )
                                            }
                                          ></textarea>
                                        </div>
                                      </div>

                                      <div className="form-row d-flex">
                                        <div className="form-group col-md-6">
                                          <label
                                            for="inputAddress"
                                            className="label_form                                         </label>

"
                                          >
                                            Classification Of Art Form
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control bg-input-custom"
                                            name="classification_of_art_form"
                                            value={
                                              item.classification_of_art_form
                                            }
                                            onChange={(e) =>
                                              handleTraditionalArtAndCraft(
                                                e,
                                                key
                                              )
                                            }
                                          />
                                        </div>
                                        <div className="form-group col-md-6 mx-1">
                                          <label
                                            for="inputAddress"
                                            className="label_form "
                                          >
                                            Color(s) Used
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control bg-input-custom extra"
                                            name="color_used"
                                            value={item.color_used}
                                            onChange={(e) =>
                                              handleTraditionalArtAndCraft(
                                                e,
                                                key
                                              )
                                            }
                                          />
                                        </div>
                                      </div>

                                      <div className="form-row d-flex">
                                        <div className="form-group col-md-6">
                                          <label
                                            for="inputAddress"
                                            className="label_form 
"
                                          >
                                            Design And Motifs
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control bg-input-custom"
                                            name="design_and_motifs"
                                            value={item.design_and_motifs}
                                            onChange={(e) =>
                                              handleTraditionalArtAndCraft(
                                                e,
                                                key
                                              )
                                            }
                                          />
                                        </div>
                                        <div className="form-group col-md-6 mx-1">
                                          <label
                                            for="inputAddress"
                                            className="label_form "
                                          >
                                            Tools Used
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control bg-input-custom extra"
                                            name="implement_tools"
                                            value={item.implement_tools}
                                            onChange={(e) =>
                                              handleTraditionalArtAndCraft(
                                                e,
                                                key
                                              )
                                            }
                                          />
                                        </div>
                                      </div>

                                      <div className="form-row d-flex">
                                        <div className="form-group col-md-6">
                                          <label
                                            for="inputAddress"
                                            className="label_form                                         </label>

"
                                          >
                                            Individual Or Group Activity
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control bg-input-custom"
                                            name="individual_or_group_activity"
                                            value={
                                              item.individual_or_group_activity
                                            }
                                            onChange={(e) =>
                                              handleTraditionalArtAndCraft(
                                                e,
                                                key
                                              )
                                            }
                                          />
                                        </div>
                                        <div className="form-group col-md-6 mx-1">
                                          <label
                                            for="inputAddress"
                                            className="label_form "
                                          >
                                            Material Used
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control bg-input-custom extra"
                                            name="material_used"
                                            value={item.material_used}
                                            onChange={(e) =>
                                              handleTraditionalArtAndCraft(
                                                e,
                                                key
                                              )
                                            }
                                          />
                                        </div>
                                      </div>

                                      <div className="form-row d-flex">
                                        <div className="form-group col-md-6">
                                          <label
                                            for="inputAddress"
                                            className="label_form                                         </label>

"
                                          >
                                            Display Occasion
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control bg-input-custom"
                                            name="occasion_display_and_sale"
                                            value={
                                              item.occasion_display_and_sale
                                            }
                                            onChange={(e) =>
                                              handleTraditionalArtAndCraft(
                                                e,
                                                key
                                              )
                                            }
                                          />
                                        </div>
                                        <div className="form-group col-md-6 mx-1">
                                          <label
                                            for="inputAddress"
                                            className="label_form "
                                          >
                                            Practicing Communities
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control bg-input-custom extra"
                                            name="practicing_communities"
                                            value={item.practicing_communities}
                                            onChange={(e) =>
                                              handleTraditionalArtAndCraft(
                                                e,
                                                key
                                              )
                                            }
                                          />
                                        </div>
                                      </div>

                                      <div className="form-row d-flex">
                                        <div className="form-group col-md-6 ">
                                          <label
                                            for="inputAddress"
                                            className="label_form                                         </label>

"
                                          >
                                            Present Status Of Art Form
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control bg-input-custom"
                                            name="present_status_of_art_form"
                                            value={
                                              item.present_status_of_art_form
                                            }
                                            onChange={(e) =>
                                              handleTraditionalArtAndCraft(
                                                e,
                                                key
                                              )
                                            }
                                          />
                                        </div>
                                        <div className="form-group col-md-6 mx-1">
                                          <label
                                            for="inputAddress"
                                            className="label_form "
                                          >
                                            Specific Communities Associated
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control bg-input-custom extra"
                                            name="specific_communities_associated_with"
                                            value={
                                              item.specific_communities_associated_with
                                            }
                                            onChange={(e) =>
                                              handleTraditionalArtAndCraft(
                                                e,
                                                key
                                              )
                                            }
                                          />
                                        </div>
                                      </div>

                                      <div className="form-row d-flex">
                                        <div className="form-group col-md-6 ">
                                          <label
                                            for="inputAddress"
                                            className="label_form                                         </label>

"
                                          >
                                            Commemorative Songs
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control bg-input-custom"
                                            name="stories_songs"
                                            value={item.stories_songs}
                                            onChange={(e) =>
                                              handleTraditionalArtAndCraft(
                                                e,
                                                key
                                              )
                                            }
                                          />
                                        </div>
                                        <div className="form-group col-md-6 mx-1">
                                          <label
                                            for="inputAddress"
                                            className="label_form "
                                          >
                                            Story on Origin of Art
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control bg-input-custom extra"
                                            name="story_on_origin_of_art"
                                            value={item.story_on_origin_of_art}
                                            onChange={(e) =>
                                              handleTraditionalArtAndCraft(
                                                e,
                                                key
                                              )
                                            }
                                          />
                                        </div>
                                      </div>

                                      <div className="form-row d-flex">
                                        <div className="form-group col-md-6">
                                          <label
                                            for="inputAddress"
                                            className="label_form                                         </label>

"
                                          >
                                            Type Of Art
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control bg-input-custom"
                                            name="type_of_art_craft"
                                            value={item.type_of_art_craft}
                                            onChange={(e) =>
                                              handleTraditionalArtAndCraft(
                                                e,
                                                key
                                              )
                                            }
                                          />
                                        </div>
                                        <div className="form-group col-md-6 mx-1">
                                          <label
                                            for="inputAddress"
                                            className="label_form "
                                          >
                                            Sub-Type of Art
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control bg-input-custom extra"
                                            name="sub_type_of_art_craft"
                                            value={item.sub_type_of_art_craft}
                                            onChange={(e) =>
                                              handleTraditionalArtAndCraft(
                                                e,
                                                key
                                              )
                                            }
                                          />
                                        </div>
                                      </div>

                                      <div className="form-row d-flex">
                                        <div className="form-group col-md-6 ">
                                          <label
                                            for="inputAddress"
                                            className="label_form                                         </label>

"
                                          >
                                            Suggestion For Economics
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control bg-input-custom"
                                            name="suggestion_for_revival_and_economic"
                                            value={
                                              item.suggestion_for_revival_and_economic
                                            }
                                            onChange={(e) =>
                                              handleTraditionalArtAndCraft(
                                                e,
                                                key
                                              )
                                            }
                                          />
                                        </div>
                                        <div className="form-group col-md-6 mx-1">
                                          <label
                                            for="inputAddress"
                                            className="label_form "
                                          >
                                            Technique Used
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control bg-input-custom extra"
                                            name="technique_used"
                                            value={item.technique_used}
                                            onChange={(e) =>
                                              handleTraditionalArtAndCraft(
                                                e,
                                                key
                                              )
                                            }
                                          />
                                        </div>
                                      </div>

                                      <div className="form-row d-flex">
                                        <div className="form-group col-md-6 ">
                                          <label
                                            for="inputAddress"
                                            className="label_form                                         </label>

"
                                          >
                                            Is the Village Self Reliant?
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control bg-input-custom"
                                            name="village_self_reliant"
                                            value={item.village_self_reliant}
                                            onChange={(e) =>
                                              handleTraditionalArtAndCraft(
                                                e,
                                                key
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                      {/* dynamic fields image */}
                                      <label className="label_form ">
                                        Upload Media(Only JPG/PNG)
                                      </label>
                                      {traditionalArtAndCraftMedia[
                                        key
                                      ]?.media.map((item, subIndex) => {
                                        return (
                                          <div
                                            className="form-row d-flex"
                                            key={key}
                                          >
                                            <div
                                              className={
                                                "form-group col-10 pt-3"
                                              }
                                            >
                                              <div className="position-relative">
                                                <textarea
                                                  className="form-control d-flex bg-input-custom "
                                                  value={item.caption}
                                                  name="caption"
                                                  onChange={(e) =>
                                                    handleCaptionChange(
                                                      key,
                                                      e,
                                                      subIndex,
                                                      "traditionalArtAndCraft"
                                                    )
                                                  }
                                                  rows="5"
                                                />

                                                {/* delete btn wont show for first value */}
                                                {subIndex != 0 ? (
                                                  <img
                                                    src={MinusIcon}
                                                    className="position-absolute delete-button-custom"
                                                    onClick={() =>
                                                      removeFormFieldsMedia(
                                                        key,
                                                        subIndex,
                                                        "traditionalArtAndCraft"
                                                      )
                                                    }
                                                  />
                                                ) : (
                                                  <></>
                                                )}
                                              </div>

                                              {/* showing file if available */}
                                              {traditionalArtAndCraftMedia[key]
                                                .media[subIndex].media_name !=
                                              "" ? (
                                                traditionalArtAndCraftMedia[key]
                                                  .media[subIndex].media_name
                                              ) : (
                                                <>
                                                  {uploadingToFirebase
                                                    ? "uploading, please wait"
                                                    : null}
                                                </>
                                              )}
                                              {traditionalArtAndCraftMedia[key]
                                                .media[subIndex].media_name !=
                                              "" ? (
                                                <span
                                                  className="mx-4"
                                                  role="button"
                                                  onClick={() =>
                                                    removeFileMedia(
                                                      key,
                                                      subIndex,
                                                      "traditionalArtAndCraft"
                                                    )
                                                  }
                                                >
                                                  X
                                                </span>
                                              ) : (
                                                " "
                                              )}
                                            </div>
                                            <div className="form-group col-2">
                                              <input
                                                type="file"
                                                name="file"
                                                id="custom-files-hidden"
                                                ref={filepick}
                                                onChange={(e) =>
                                                  onFileChange(
                                                    e,
                                                    "traditionalArtAndCraft"
                                                  )
                                                }
                                                onClick={(event) => {
                                                  event.target.value = null;
                                                }}
                                                hidden
                                                className="hidden"
                                                accept="image/jpg, image/png, image/jpeg"
                                              />{" "}
                                              <label
                                                onClick={() => {
                                                  setMediaIndex(key);
                                                  setMediaSubIndex(subIndex);
                                                }}
                                                for="custom-files-hidden"
                                                className="btn_browse "
                                                role="button"
                                              >
                                                Browse
                                              </label>
                                            </div>
                                          </div>
                                        );
                                      })}

                                      <div
                                        className="dynamic-button-add dynamic-button-add-temp"
                                        onClick={() =>
                                          addFormFieldsMedia(
                                            key,
                                            "traditionalArtAndCraft"
                                          )
                                        }
                                      >
                                        Add More
                                        <img
                                          className="plus-icon"
                                          src={PlusIcon}
                                        />
                                      </div>

                                      {/* if the index is one, then dont show delete button */}
                                      {key != 0 ? (
                                        <button
                                          className="add-new-field-btn img-custom-minus"
                                          onClick={() =>
                                            handleRemoveTraditionalArtAndCraft(
                                              key
                                            )
                                          }
                                        >
                                          Remove
                                        </button>
                                      ) : null}
                                      <hr
                                        //couldn't help myself
                                        style={{
                                          opacity: 0.1,
                                        }}
                                        className="mt-4"
                                      />
                                    </div>
                                  );
                                })}
                              </div>
                            </form>{" "}
                          </div>
                          <div>
                            <button
                              className="add-new-field-btn"
                              onClick={handleAddTraditionalArtAndCraft}
                            >
                              Add More
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {/* -------------------------------------------------------------------------------------------------------------------------------- */}
                </div>
              </>
            </div>
            <div>
              {/* next and submit btn -- start */}
              <div>
                <div className="p-2 final_submit d-flex align-center">
                  {selectedTopic == "famous_personalities" ? (
                    ""
                  ) : (
                    <button
                      type="submit"
                      className="btn_submit"
                      onClick={() => routeChange()}
                    >
                      Next
                    </button>
                  )}

                  {loader ? (
                    <button
                      className={"btn_submit bg-green-custom"}
                      onClick={(e) => handleSubmit(e, "final_submit")}
                    >
                      <span
                        className="spinner-grow spinner-grow-sm me-3"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <spna className="pl-3">Wait...</spna>
                    </button>
                  ) : (
                    <button
                      className={"btn_submit bg-green-custom float-right"}
                      onClick={(e) => handleSubmit(e, "final_submit")}
                    >
                      Submit{" "}
                    </button>
                  )}
                </div>
              </div>

              {/* next and submit btn -- end */}
            </div>
          </div>
        </MyLayout>
      </MyDesktop>

      {/* ================================================================================================================================================================================================ */}

      <div className="add_village_page_css">
        <MyMobile>
          <MyLayout>
            <SuggestEdit
              show={modalShow}
              handleClose={handleModalClose}
              handleModalShow={handleModalShow}
              keyy={keyy}
              jsonField={selectedTopic}
              update={isUpdate}
              item={imageItem}
            />

            <div
              id="offCanv"
              className="col-2"
              style={{
                backgroundColor: "#75cf8f",
                borderRadius: "0px 10px 10px 0px",
                zIndex: "1000",
                opacity: "0.8",
                // position: "fixed",
                // top: "56px",
              }}
            >
              <div className="p-2 align-items-center d-flex justify-content-center">
                <div className="th-pointer " onClick={handleShow}>
                  <img height="30px" src={MenuIcon} alt="..." />
                </div>
              </div>
            </div>

            <Offcanvas
              show={show}
              onHide={handleClose}
              style={{ overflowY: "scroll" }}
            >
              <Offcanvas.Header closeButton>
                {/* <Offcanvas.Title>Offcanvas</Offcanvas.Title> */}
              </Offcanvas.Header>
              <Offcanvas.Body>
                {temporaryTopiclist?.map((item, index) =>
                  topicsData[item] === null ? null : (
                    <div
                      className={`th-pointer action-btn my-2 text-capitalize col ${
                        selectedTopic === item
                          ? "th-fw-600 col selectiveColor"
                          : null
                      }`}
                      style={{ overflowWrap: "break-word" }}
                      key={index}
                      onClick={() => {
                        setShow(false);
                        setSelectedTopic(item);
                      }}
                    >
                      {item === "geographical"
                        ? "Geographic and Demographic profile"
                        : item === "developmental"
                        ? "Developmental Profile"
                        : item === "festivals"
                        ? "Fairs and Festivals"
                        : item === "traditional_art"
                        ? "Traditional Art and Craft"
                        : item === "ornaments"
                        ? "Traditional Ornaments"
                        : item === "belief"
                        ? "Beliefs and Customs"
                        : item.toLowerCase().split("_").join(" ")}
                    </div>
                  )
                )}
              </Offcanvas.Body>
            </Offcanvas>

            {/* Village Details */}

            {
              <Modal show={villageValid} centered>
                <Modal.Body className="text-center text-danger">
                  {" "}
                  Please Add Village Name, State and District
                </Modal.Body>
              </Modal>
            }

            {
              <Modal show={finalSubmit} centered>
                <Modal.Body className="text-center text-success">
                  {" "}
                  Submitted for review
                </Modal.Body>
              </Modal>
            }
            {
              <Modal show={villageAlready} centered>
                <Modal.Body className="text-center text-danger">
                  {" "}
                  Same Village Already Exists!
                </Modal.Body>
              </Modal>
            }
            {
              <Modal show={submitFirst} centered>
                <Modal.Body className="text-center text-success">
                  {" "}
                  Submitted For Review
                </Modal.Body>
              </Modal>
            }
            {selectedTopic === "image_gallery" ? (
              ""
            ) : (
              <>
                <div className="col-md-12 mt-1 marginPut">
                  {/* -----------------------------------------CULTURAL PROFILE----------------------------------------------- */}
                  {selectedTopic === "culture_profile" ? (
                    <div
                      className="col-md-12 col-sm-12  form_main"
                      style={{ overflowY: "scroll" }}
                    >
                      <form>
                        <div className="add-village-container-sub-field">
                          <div className="main_heading ">
                            Fill The Details of Village
                            <hr></hr>
                          </div>

                          <div className="form-row d-flex pt-4">
                            <div className="form-group col-md-12">
                              <label className="label_form">
                                State
                                <span className="px-1 text-danger">*</span>
                              </label>
                              <MenuSelectAddVillage
                                attribute="state_name"
                                f_name="State"
                                className="bg-input-custom col-12 p-2"
                                limit={1000}
                                passedDownItems={allStates}
                                onChangeFun_={handleChangeFilter}
                                selectedValue={selectedValueForStateFilter}
                                passedValue={culData?.State}
                                checkIfVillageAssigned={isDisabledMenuSelect}
                                onChangeFunc_={culChange}
                                mobileView={true}
                                error={isValidState}
                                passnewValue={newValue}
                              />
                            </div>
                          </div>

                          <div className="form-row d-flex">
                            <div className="form-group col-md-12">
                              <label className="label_form  ">
                                District
                                <span className="px-1 text-danger">*</span>
                              </label>
                              <MenuSelectAddVillage
                                attribute="district_name"
                                f_name="District"
                                limit={1000}
                                className="bg-input-custom col-12 p-2 "
                                passedDownItems={allDistricts}
                                onChangeFun_={handleChangeFilter}
                                selectedValue={selectedValueForDistrictFilter}
                                passedValue={culData?.District}
                                checkIfVillageAssigned={isDisabledMenuSelect}
                                error={isValidDistrict}
                                onChangeFunc_={culChange}
                                mobileView={true}
                                passnewValue={newValue}
                              />
                            </div>
                          </div>
                          <div className="form-row ">
                            <div className="form-group col-12 ">
                              <label className="label_form ">
                                Name Of Village
                                <span className="px-1 text-danger">*</span>
                              </label>
                              <MenuSelectAddVillage
                                attribute="village_name"
                                f_name="village_name"
                                limit={1000}
                                className="bg-input-custom col-12 p-2"
                                passedDownItems={allVillage}
                                onChangeFun_={handleChangeFilter}
                                selectedValue={selectedValueForVillageFilter}
                                passedValue={culData?.village_name}
                                stateName={culData?.state}
                                onChangeFunc_={culChange}
                                mobileView={true}
                                error={isValidName}
                                passnewValue={newValue}
                              />
                            </div>
                          </div>
                          <div className="form-group max-width-select">
                            <label className="label_form  ">
                              What is your village famous for?
                            </label>
                            <input
                              type="text"
                              name="your_village_famous_for"
                              onChange={(e) => culChange(e)}
                              value={culData?.your_village_famous_for}
                              className="form-control bg-input-custom"
                            />
                          </div>
                          <div className="form-group max-width-select">
                            <label className="label_form">
                              Is there anything that make your village unique or
                              different from other villages?{" "}
                            </label>
                            <input
                              type="text"
                              name="village_unique_from_other_village"
                              onChange={(e) => culChange(e)}
                              className="form-control bg-input-custom"
                              value={culData?.village_unique_from_other_village}
                            />
                          </div>
                          <div className="form-group max-width-select">
                            <label className="label_form">
                              Are there any famous historic incidents that
                              happened in your village?{" "}
                            </label>
                            <input
                              type="text"
                              name="historic_incident"
                              onChange={(e) => culChange(e)}
                              value={culData?.historic_incident}
                              className="form-control bg-input-custom"
                            />
                          </div>

                          {/* dynamic fields image */}
                          <label className="label_form ">
                            Upload Media(Only JPG/PNG)
                          </label>
                          {culMedia.map((item, key) => {
                            return (
                              <div
                                className={"form-row d-flex max-width-select"}
                                key={key}
                              >
                                <div
                                  className={
                                    "form-group  col-md-6 " + (key == 0)
                                      ? "pt-3"
                                      : ""
                                  }
                                >
                                  <div className="position-relative">
                                    <textarea
                                      className="form-control d-flex bg-input-custom "
                                      value={item.caption}
                                      name="caption"
                                      onChange={(e) =>
                                        handleCaptionChange(key, e)
                                      }
                                      rows="5"
                                    />

                                    {/* delete btn wont show for first value */}
                                    {key != 0 ? (
                                      <img
                                        src={MinusIcon}
                                        className="position-absolute delete-button-custom"
                                        onClick={() =>
                                          removeFormFieldsMedia(key)
                                        }
                                      />
                                    ) : (
                                      <></>
                                    )}
                                  </div>

                                  {/* showing file if available */}
                                  {culMedia[key].media_name != "" ? (
                                    culMedia[key].media_name
                                  ) : (
                                    <>
                                      {uploadingToFirebase
                                        ? "uploading, please wait"
                                        : null}
                                    </>
                                  )}
                                  {culMedia[key].media_name != "" ? (
                                    <span
                                      className="mx-4"
                                      role="button"
                                      onClick={() => removeFileMedia(key)}
                                    >
                                      X
                                    </span>
                                  ) : (
                                    " "
                                  )}
                                </div>
                                <div className="form-group col-md-6" key={key}>
                                  <input
                                    type="file"
                                    name="file"
                                    id="custom-files-hidden"
                                    ref={filepick}
                                    onChange={(e) => onFileChange(e, "culture")}
                                    onClick={(event) => {
                                      event.target.value = null;
                                    }}
                                    onDrop
                                    hidden
                                    className="hidden"
                                    accept="image/jpg, image/png, image/jpeg"
                                    onF
                                  />{" "}
                                  <label
                                    onClick={() => setMediaIndex(key)}
                                    for="custom-files-hidden"
                                    className="btn_browse "
                                    role="button"
                                  >
                                    Browse
                                  </label>
                                </div>
                              </div>
                            );
                          })}

                          <div
                            className="dynamic-button-add dynamic-button-add-temp"
                            onClick={() => addFormFieldsMedia()}
                          >
                            Add More
                            <img className="plus-icon" src={PlusIcon} />
                          </div>
                        </div>
                      </form>
                    </div>
                  ) : null}

                  {/* ----------------------------------GEOGRAPHICAL LOCATION------------------------------------------ */}

                  {selectedTopic === "geographical" ? (
                    <>
                      <div className="col-md-12 col-sm-12 form_main">
                        <div>
                          <form>
                            <div className="add-village-container-sub-field">
                              <div className="main_heading ">
                                Fill The Details of Village
                                <hr></hr>
                              </div>

                              <div className="form-row pt-4">
                                <div className="form-group col-md-12">
                                  <label className="label_form d-flex">
                                    Locate Your Village
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control bg-input-custom"
                                    name="locate"
                                    value={geoData.locate}
                                    onChange={geoChange}
                                  />
                                </div>
                              </div>

                              <div className="form-row">
                                <div className="form-group col-md-12">
                                  <label className="label_form">
                                    Male Population
                                  </label>
                                  <input
                                    type="text"
                                    name="malePopulation"
                                    className="form-control bg-input-custom"
                                    onChange={(e) => geoChange(e)}
                                    value={geoData?.malePopulation}
                                  />
                                </div>
                                <div className="form-group col-md-12">
                                  <label className="label_form">
                                    Female Population
                                  </label>
                                  <input
                                    type="text"
                                    name="femalePopulation"
                                    className="form-control bg-input-custom"
                                    onChange={(e) => geoChange(e)}
                                    value={geoData?.femalePopulation}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="form-group ">
                                <label className="label_form">
                                  Total Population
                                </label>
                                <input
                                  type="text"
                                  className="form-control bg-input-custom"
                                  name="population"
                                  value={geoData.population}
                                  onChange={geoChange}
                                />
                              </div>
                              <div className="form-group">
                                <label className="label_form">
                                  Nearest Railway Station
                                </label>
                                <input
                                  type="text"
                                  className="form-control bg-input-custom"
                                  name="station"
                                  value={geoData.station}
                                  onChange={geoChange}
                                />
                              </div>
                              <div className="form-group">
                                <label className="label_form">
                                  Nearest Bus Terminal
                                </label>
                                <input
                                  type="text"
                                  className="form-control bg-input-custom"
                                  name="bus"
                                  value={geoData.bus}
                                  onChange={geoChange}
                                />
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {/* ----------------------------------------------DEVELOPMENTAL---------------------------------------------------- */}

                  {selectedTopic === "developmental" ? (
                    <div className="col-md-12 col-sm-12 p-1 form_main">
                      <form>
                        <div className="add-village-container-sub-field">
                          <div className="main_heading ">
                            Fill The Details of Village
                            <hr></hr>
                          </div>
                          <div className="form-row d-flex pt-4">
                            <div className="form-group col-md-12">
                              <label for="inputEmail4" className="label_form ">
                                Primary Source Of Livelihood
                              </label>
                              <input
                                type="text"
                                className="form-control bg-input-custom"
                                name="livelihood"
                                value={developData.livelihood}
                                onChange={(e) => developChange(e)}
                              />
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="form-group col-md-12">
                              <label for="inputState" className="label_form">
                                Add More Details
                              </label>
                              <textarea
                                className="form-control bg-input-custom"
                                name="details"
                                value={developData.details}
                                onChange={(e) => developChange(e)}
                              />
                            </div>
                          </div>
                          <div className="form-row d-flex devlop">
                            <div className="form-group col-md-4">
                              <label for="inputAddress" className="label_form">
                                Toilets
                              </label>
                              <input
                                type="text"
                                className="form-control bg-input-custom"
                                name="toilets"
                                value={developData.toilets}
                                onChange={(e) => developChange(e)}
                              />
                            </div>
                            <div className="form-group col-md-4 mx-1">
                              <label for="inputAddress" className="label_form">
                                Post Office
                              </label>
                              <input
                                type="text"
                                className="form-control bg-input-custom"
                                name="post"
                                value={developData.post}
                                onChange={(e) => developChange(e)}
                              />
                            </div>
                            <div className="form-group col-md-4">
                              <label for="inputAddress" className="label_form">
                                Telephone
                              </label>
                              <input
                                type="text"
                                className="form-control bg-input-custom"
                                name="telephone"
                                value={developData.telephone}
                                onChange={(e) => developChange(e)}
                              />
                            </div>
                          </div>
                          <div className="form-row d-flex devlop">
                            <div className="form-group col-md-4">
                              <label for="inputAddress" className="label_form">
                                Hospital
                              </label>
                              <input
                                type="text"
                                className="form-control bg-input-custom"
                                name="health"
                                value={developData.health}
                                onChange={(e) => developChange(e)}
                              />
                            </div>
                            <div className="form-group col-md-4 mx-1">
                              <label for="inputAddress" className="label_form">
                                College
                              </label>
                              <input
                                type="text"
                                className="form-control bg-input-custom"
                                name="college"
                                value={developData.college}
                                onChange={(e) => developChange(e)}
                              />
                            </div>
                            <div className="form-group col-md-4">
                              <label className="label_form">Gas</label>
                              <input
                                type="text"
                                className="form-control bg-input-custom"
                                value={developData.gas}
                                name="gas"
                                onChange={(e) => developChange(e)}
                              />
                            </div>
                          </div>

                          <div className="form-row d-flex">
                            <div className="form-group col-md-4">
                              <label className="label_form">Water</label>
                              <input
                                type="text"
                                className="form-control bg-input-custom"
                                name="water"
                                value={developData.water}
                                onChange={(e) => developChange(e)}
                              />
                            </div>
                            <div className="form-group col-md-4 mx-1">
                              <label className="label_form">Bank</label>
                              <input
                                type="text"
                                className="form-control bg-input-custom"
                                name="bank"
                                value={developData.bank}
                                onChange={(e) => developChange(e)}
                              />
                            </div>
                            <div className="form-group col-md-4">
                              <label className="label_form">Old School</label>
                              <input
                                type="text"
                                className="form-control bg-input-custom"
                                name="school"
                                value={developData.school}
                                onChange={(e) => developChange(e)}
                              />
                            </div>
                          </div>
                          <div className="form-row d-flex">
                            <div className="form-group col-md-4">
                              <label className="label_form">Drainage</label>
                              <input
                                type="text"
                                className="form-control bg-input-custom"
                                name="drainageSystem"
                                value={developData.drainageSystem}
                                onChange={(e) => developChange(e)}
                              />
                            </div>
                            <div className="form-group col-md-4 mx-1">
                              <label className="label_form ">Tab Water</label>
                              <input
                                type="text"
                                className="form-control bg-input-custom"
                                name="tabWater"
                                value={developData.tabWater}
                                onChange={(e) => developChange(e)}
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  ) : null}

                  {/* ----------------------------------------------FESTIVALS---------------------------------------------------- */}

                  {selectedTopic === "festivals" ? (
                    <>
                      <div className="col-md-12 col-sm-12 p-1 form_main">
                        <div>
                          <form>
                            <div className="add-village-container-sub-field">
                              <div className="main_heading ">
                                Fill The Details of Village
                                <hr></hr>
                              </div>

                              {/* mapping through the state data */}
                              {fairsAndFestivals.map((item, key) => {
                                return (
                                  <div className="position-relative">
                                    <div className="d-flex">
                                      <div className="form-group pt-3 ">
                                        <label className="label_form ">
                                          Name of Festival
                                        </label>
                                        <div className="d-flex align-center">
                                          <input
                                            type="text"
                                            className="form-control text-area bg-input-custom"
                                            name="title"
                                            value={item.title}
                                            onChange={(e) =>
                                              handleFairAndFestivals(e, key)
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="form-group">
                                      <label className="label_form ">
                                        Description
                                      </label>
                                      <div className="d-flex align-center">
                                        <textarea
                                          type="text"
                                          className="form-control text-area2 bg-input-custom"
                                          id="inputCity"
                                          value={item.description}
                                          name="description"
                                          rows="5"
                                          cols="15"
                                          onChange={(e) =>
                                            handleFairAndFestivals(e, key)
                                          }
                                        ></textarea>
                                      </div>
                                    </div>

                                    <div className="form-row d-flex">
                                      <div className="form-group col-md-6 fetival_field">
                                        <label
                                          for="inputAddress"
                                          className="label_form"
                                        >
                                          Type Of Festival
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control bg-input-custom"
                                          name="type_of_festival"
                                          value={item.type_of_festival}
                                          onChange={(e) =>
                                            handleFairAndFestivals(e, key)
                                          }
                                        />
                                      </div>
                                      <div className="form-group col-md-6 mx-1">
                                        <label
                                          for="inputAddress"
                                          className="label_form "
                                        >
                                          Festival Organizer
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control bg-input-custom extra"
                                          name="who_organise_festival"
                                          value={item.who_organise_festival}
                                          onChange={(e) =>
                                            handleFairAndFestivals(e, key)
                                          }
                                        />
                                      </div>
                                    </div>

                                    <div className="form-row devlop">
                                      <div className="form-group col-md-6">
                                        <label
                                          for="inputAddress"
                                          className="label_form"
                                        >
                                          Who Takes Part In Festival?
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control bg-input-custom"
                                          name="who_take_part_in_festival"
                                          value={item.who_take_part_in_festival}
                                          onChange={(e) =>
                                            handleFairAndFestivals(e, key)
                                          }
                                        />
                                      </div>
                                    </div>

                                    {/* dynamic fields image */}
                                    <label className="label_form ">
                                      Upload Media(Only JPG/PNG)
                                    </label>
                                    {festivalsMedia[key]?.media.map(
                                      (item, subIndex) => {
                                        return (
                                          <div
                                            className={"form-row d-flex"}
                                            key={key}
                                          >
                                            <div
                                              className={
                                                "form-group  col-md-6 " +
                                                (key == 0)
                                                  ? "pt-3"
                                                  : ""
                                              }
                                            >
                                              <div className="position-relative">
                                                <textarea
                                                  className="form-control d-flex bg-input-custom "
                                                  value={item.caption}
                                                  name="caption"
                                                  onChange={(e) =>
                                                    handleCaptionChange(
                                                      key,
                                                      e,
                                                      subIndex,
                                                      "festivals"
                                                    )
                                                  }
                                                  rows="5"
                                                />

                                                {/* delete btn wont show for first value */}
                                                {subIndex != 0 ? (
                                                  <img
                                                    src={MinusIcon}
                                                    className="position-absolute delete-button-custom"
                                                    onClick={() =>
                                                      removeFormFieldsMedia(
                                                        key,
                                                        subIndex,
                                                        "festivals"
                                                      )
                                                    }
                                                  />
                                                ) : (
                                                  <></>
                                                )}
                                              </div>

                                              {/* showing file if available */}
                                              {festivalsMedia[key].media[
                                                subIndex
                                              ].media_name != "" ? (
                                                festivalsMedia[key].media[
                                                  subIndex
                                                ].media_name
                                              ) : (
                                                <>
                                                  {uploadingToFirebase
                                                    ? "uploading, please wait"
                                                    : null}
                                                </>
                                              )}
                                              {festivalsMedia[key].media[
                                                subIndex
                                              ].media_name != "" ? (
                                                <span
                                                  className="mx-4"
                                                  role="button"
                                                  onClick={() =>
                                                    removeFileMedia(
                                                      key,
                                                      subIndex,
                                                      "festivals"
                                                    )
                                                  }
                                                >
                                                  X
                                                </span>
                                              ) : (
                                                " "
                                              )}
                                            </div>
                                            <div className="form-group col-md-6">
                                              <input
                                                type="file"
                                                name="file"
                                                id="custom-files-hidden"
                                                ref={filepick}
                                                onChange={(e) =>
                                                  onFileChange(e, "festivals")
                                                }
                                                onClick={(event) => {
                                                  event.target.value = null;
                                                }}
                                                hidden
                                                className="hidden"
                                                accept="image/jpg, image/png, image/jpeg"
                                              />{" "}
                                              <label
                                                onClick={() => {
                                                  setMediaIndex(key);
                                                  setMediaSubIndex(subIndex);
                                                }}
                                                for="custom-files-hidden"
                                                className="btn_browse "
                                                role="button"
                                              >
                                                Browse
                                              </label>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}

                                    <div
                                      className="dynamic-button-add dynamic-button-add-temp"
                                      onClick={() =>
                                        addFormFieldsMedia(key, "festivals")
                                      }
                                    >
                                      Add More
                                      <img
                                        className="plus-icon"
                                        src={PlusIcon}
                                      />
                                    </div>

                                    {/* if the index is one, then dont show delete button */}
                                    {key != 0 ? (
                                      <button
                                        className="add-new-field-btn img-custom-minus"
                                        onClick={() =>
                                          handleRemoveFairAndFestivals(key)
                                        }
                                      >
                                        Remove
                                      </button>
                                    ) : null}
                                    <hr
                                      //couldn't help myself
                                      style={{
                                        opacity: 0.1,
                                      }}
                                      className="mt-4"
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </form>
                        </div>
                        <div>
                          <button
                            className="add-new-field-btn"
                            onClick={handleAddFairAndFestivals}
                          >
                            Add More
                          </button>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {/* ----------------------------------------------TRADITIONAL FOOD---------------------------------------------------- */}

                  {selectedTopic === "traditional_food" ? (
                    <>
                      <div className="col-md-12 col-sm-12 p-1 form_main">
                        <div>
                          <form>
                            <div className="add-village-container-sub-field">
                              <div className="main_heading ">
                                Fill The Details of Village
                                <hr></hr>
                              </div>
                              {/* mapping through the state data */}
                              {traditionalFood.map((item, key) => {
                                return (
                                  <div className="position-relative">
                                    <div className="d-flex pt-4">
                                      <div className="form-group ">
                                        <label className="label_form  d-flex">
                                          Name
                                        </label>
                                        <div className="d-flex align-center">
                                          <input
                                            type="text"
                                            className="form-control bg-input-custom"
                                            name="title"
                                            value={item.title}
                                            onChange={(e) =>
                                              handleTraditionalFood(e, key)
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <label className="label_form  d-flex">
                                        Description
                                      </label>
                                      <div className="d-flex align-center">
                                        <textarea
                                          className="form-control text-area2 bg-input-custom"
                                          name="description"
                                          value={item.description}
                                          id="inputCity"
                                          rows="5"
                                          onChange={(e) =>
                                            handleTraditionalFood(e, key)
                                          }
                                        ></textarea>
                                      </div>
                                    </div>
                                    {/* dynamic fields image */}
                                    <label className="label_form ">
                                      Upload Media(Only JPG/PNG)
                                    </label>
                                    {traditionalFoodMedia[key]?.media.map(
                                      (item, subIndex) => {
                                        return (
                                          <div
                                            className={"form-row d-flex"}
                                            key={key}
                                          >
                                            <div
                                              className={
                                                "form-group  col-md-6 " +
                                                (key == 0)
                                                  ? "pt-3"
                                                  : ""
                                              }
                                            >
                                              <div className="position-relative">
                                                <textarea
                                                  className="form-control d-flex bg-input-custom "
                                                  value={item.caption}
                                                  name="caption"
                                                  onChange={(e) =>
                                                    handleCaptionChange(
                                                      key,
                                                      e,
                                                      subIndex,
                                                      "traditionalFood"
                                                    )
                                                  }
                                                  rows="5"
                                                />

                                                {/* delete btn wont show for first value */}
                                                {subIndex != 0 ? (
                                                  <img
                                                    src={MinusIcon}
                                                    className="position-absolute delete-button-custom"
                                                    onClick={() =>
                                                      removeFormFieldsMedia(
                                                        key,
                                                        subIndex,
                                                        "traditionalFood"
                                                      )
                                                    }
                                                  />
                                                ) : (
                                                  <></>
                                                )}
                                              </div>

                                              {/* showing file if available */}
                                              {traditionalFoodMedia[key].media[
                                                subIndex
                                              ].media_name != "" ? (
                                                traditionalFoodMedia[key].media[
                                                  subIndex
                                                ].media_name
                                              ) : (
                                                <>
                                                  {uploadingToFirebase
                                                    ? "uploading, please wait"
                                                    : null}
                                                </>
                                              )}
                                              {traditionalFoodMedia[key].media[
                                                subIndex
                                              ].media_name != "" ? (
                                                <span
                                                  className="mx-4"
                                                  role="button"
                                                  onClick={() =>
                                                    removeFileMedia(
                                                      key,
                                                      subIndex,
                                                      "traditionalFood"
                                                    )
                                                  }
                                                >
                                                  X
                                                </span>
                                              ) : (
                                                " "
                                              )}
                                            </div>
                                            <div className="form-group col-md-6">
                                              <input
                                                type="file"
                                                name="file"
                                                id="custom-files-hidden"
                                                ref={filepick}
                                                onChange={(e) =>
                                                  onFileChange(
                                                    e,
                                                    "traditionalFood"
                                                  )
                                                }
                                                onClick={(event) => {
                                                  event.target.value = null;
                                                }}
                                                hidden
                                                className="hidden"
                                                accept="image/jpg, image/png, image/jpeg"
                                              />{" "}
                                              <label
                                                onClick={() => {
                                                  setMediaIndex(key);
                                                  setMediaSubIndex(subIndex);
                                                }}
                                                for="custom-files-hidden"
                                                className="btn_browse "
                                                role="button"
                                              >
                                                Browse
                                              </label>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}

                                    <div
                                      className="dynamic-button-add dynamic-button-add-temp"
                                      onClick={() =>
                                        addFormFieldsMedia(
                                          key,
                                          "traditionalFood"
                                        )
                                      }
                                    >
                                      Add More
                                      <img
                                        className="plus-icon"
                                        src={PlusIcon}
                                      />
                                    </div>
                                    {/* if the index is one, then dont show delete button */}
                                    {key != 0 ? (
                                      <button
                                        className="remove-field-btn img-custom-minus"
                                        onClick={() =>
                                          handleRemoveTraditionalFood(key)
                                        }
                                      >
                                        Remove
                                      </button>
                                    ) : null}
                                    <hr
                                      //couldn't help myself
                                      style={{ opacity: 0.1 }}
                                      className="mt-4"
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </form>
                          <div>
                            <button
                              className="add-new-field-btn"
                              onClick={handleAddTraditionalFood}
                            >
                              Add More
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {/* ----------------------------------------------BELIEFS---------------------------------------------------- */}

                  {selectedTopic === "belief" ? (
                    <>
                      <div className="col-md-12 col-sm-12 p-1 form_main">
                        <div>
                          <form>
                            <div className="add-village-container-sub-field">
                              <div className="main_heading ">
                                Fill The Details of Village
                                <hr></hr>
                              </div>

                              {/* mapping through the state data */}
                              {belief.map((item, key) => {
                                return (
                                  <div className="position-relative">
                                    <div className="d-flex pt-4">
                                      <div className="form-group ">
                                        <label className="label_form d-flex">
                                          Name
                                        </label>
                                        <div className="d-flex align-center">
                                          <input
                                            type="text"
                                            className="form-control bg-input-custom"
                                            name="title"
                                            value={item.title}
                                            onChange={(e) =>
                                              handleBelief(e, key)
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="form-row d-flex devlop">
                                      <div className="form-group col-md-6">
                                        <label className="label_form">
                                          Type{" "}
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control bg-input-custom"
                                          value={item.type}
                                          name="type"
                                          onChange={(e) => handleBelief(e, key)}
                                        />
                                      </div>
                                      <div className="form-group col-md-6 mx-1">
                                        <label className="label_form">
                                          Belief Related To{" "}
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control bg-input-custom"
                                          name="relatedTo"
                                          value={item.relatedTo}
                                          onChange={(e) => handleBelief(e, key)}
                                        />
                                      </div>
                                    </div>

                                    <div className="form-group">
                                      <label className="label_form d-flex">
                                        Description
                                      </label>
                                      <div className="d-flex align-center">
                                        <textarea
                                          className="form-control text-area2 bg-input-custom"
                                          name="description"
                                          value={item.description}
                                          id="inputCity"
                                          rows="5"
                                          onChange={(e) => handleBelief(e, key)}
                                        ></textarea>
                                      </div>
                                    </div>
                                    {/* dynamic fields image */}
                                    <label className="label_form ">
                                      Upload Media(Only JPG/PNG)
                                    </label>
                                    {beliefMedia[key]?.media.map(
                                      (item, subIndex) => {
                                        return (
                                          <div
                                            className={"form-row d-flex"}
                                            key={key}
                                          >
                                            <div
                                              className={
                                                "form-group  col-md-6 " +
                                                (key == 0)
                                                  ? "pt-3"
                                                  : ""
                                              }
                                            >
                                              <div className="position-relative">
                                                <textarea
                                                  className="form-control d-flex bg-input-custom "
                                                  value={item.caption}
                                                  name="caption"
                                                  onChange={(e) =>
                                                    handleCaptionChange(
                                                      key,
                                                      e,
                                                      subIndex,
                                                      "belief"
                                                    )
                                                  }
                                                  rows="5"
                                                />

                                                {/* delete btn wont show for first value */}
                                                {subIndex != 0 ? (
                                                  <img
                                                    src={MinusIcon}
                                                    className="position-absolute delete-button-custom"
                                                    onClick={() =>
                                                      removeFormFieldsMedia(
                                                        key,
                                                        subIndex,
                                                        "belief"
                                                      )
                                                    }
                                                  />
                                                ) : (
                                                  <></>
                                                )}
                                              </div>

                                              {/* showing file if available */}
                                              {beliefMedia[key].media[subIndex]
                                                .media_name != "" ? (
                                                beliefMedia[key].media[subIndex]
                                                  .media_name
                                              ) : (
                                                <>
                                                  {uploadingToFirebase
                                                    ? "uploading, please wait"
                                                    : null}
                                                </>
                                              )}

                                              {beliefMedia[key].media[subIndex]
                                                .media_name != "" ? (
                                                <span
                                                  className="mx-4"
                                                  role="button"
                                                  onClick={() =>
                                                    removeFileMedia(
                                                      key,
                                                      subIndex,
                                                      "belief"
                                                    )
                                                  }
                                                >
                                                  X
                                                </span>
                                              ) : (
                                                " "
                                              )}
                                            </div>
                                            <div className="form-group col-md-6">
                                              <input
                                                type="file"
                                                name="file"
                                                id="custom-files-hidden"
                                                ref={filepick}
                                                onChange={(e) =>
                                                  onFileChange(e, "belief")
                                                }
                                                onClick={(event) => {
                                                  event.target.value = null;
                                                }}
                                                hidden
                                                className="hidden"
                                                accept="image/jpg, image/png, image/jpeg"
                                              />{" "}
                                              <label
                                                onClick={() => {
                                                  setMediaIndex(key);
                                                  setMediaSubIndex(subIndex);
                                                }}
                                                for="custom-files-hidden"
                                                className="btn_browse "
                                                role="button"
                                              >
                                                Browse
                                              </label>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}

                                    <div
                                      className="dynamic-button-add dynamic-button-add-temp"
                                      onClick={() =>
                                        addFormFieldsMedia(key, "belief")
                                      }
                                    >
                                      Add More
                                      <img
                                        className="plus-icon"
                                        src={PlusIcon}
                                        alt="PlusIcon"
                                      />
                                    </div>
                                    {/* if the index is one, then dont show delete button */}
                                    {key != 0 ? (
                                      <button
                                        className="remove-field-btn img-custom-minus"
                                        onClick={() => handleRemoveBelief(key)}
                                      >
                                        Remove
                                      </button>
                                    ) : null}
                                    <hr
                                      //couldn't help myself
                                      style={{
                                        opacity: 0.1,
                                      }}
                                      className="mt-4"
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </form>
                        </div>
                        <div>
                          <button
                            className="add-new-field-btn"
                            onClick={handleAddBelief}
                          >
                            Add More
                          </button>
                        </div>
                      </div>
                    </>
                  ) : null}

                  {/* ----------------------------------------------heritage_places---------------------------------------------------- */}

                  {selectedTopic === "heritage_places" ? (
                    <>
                      <div className="col-md-12 col-sm-12 p-1 form_main">
                        <div>
                          <form>
                            <div className="add-village-container-sub-field">
                              <div className="main_heading ">
                                Fill The Details of Village
                                <hr></hr>
                              </div>
                              {/* mapping through the state data */}
                              {heritagePlaces.map((item, key) => {
                                return (
                                  <div className="position-relative">
                                    <div className="d-flex pt-4">
                                      <div className="form-group ">
                                        <label className="label_form  d-flex">
                                          Name
                                        </label>
                                        <div className="d-flex align-center">
                                          <input
                                            type="text"
                                            className="form-control bg-input-custom"
                                            name="title"
                                            value={item.title}
                                            onChange={(e) =>
                                              handleHeritagePlaces(e, key)
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <label className="label_form  d-flex">
                                        Description
                                      </label>
                                      <div className="d-flex align-center">
                                        <textarea
                                          className="form-control text-area2 bg-input-custom"
                                          name="description"
                                          value={item.description}
                                          id="inputCity"
                                          rows="5"
                                          onChange={(e) =>
                                            handleHeritagePlaces(e, key)
                                          }
                                        ></textarea>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <label className="label_form">
                                        Story Associated
                                      </label>
                                      <div className="d-flex align-center">
                                        <textarea
                                          type="text"
                                          className="form-control text-area2 bg-input-custom"
                                          name="story_associate_with_heritage"
                                          value={
                                            item.story_associate_with_heritage
                                          }
                                          id="inputCity"
                                          rows="5"
                                          cols="15"
                                          onChange={(e) =>
                                            handleHeritagePlaces(e, key)
                                          }
                                        ></textarea>
                                      </div>
                                    </div>

                                    <div className="form-row d-flex devlop">
                                      <div className="form-group col-md-6">
                                        <label className="label_form">
                                          Deities{" "}
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control bg-input-custom"
                                          value={
                                            item.deities_related_to_heritage
                                          }
                                          name="deities_related_to_heritage"
                                          onChange={(e) =>
                                            handleHeritagePlaces(e, key)
                                          }
                                        />
                                      </div>
                                      <div className="form-group col-md-6 mx-1">
                                        <label className="label_form">
                                          Ritual{" "}
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control bg-input-custom"
                                          name="ritual_worship_related_to_heritage"
                                          value={
                                            item.ritual_worship_related_to_heritage
                                          }
                                          onChange={(e) =>
                                            handleHeritagePlaces(e, key)
                                          }
                                        />
                                      </div>
                                    </div>

                                    <div className="form-row d-flex devlop">
                                      <div className="form-group col-md-4 ">
                                        <label className="label_form ">
                                          Type Of Heritage
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control bg-input-custom"
                                          name="type"
                                          value={item.type}
                                          onChange={(e) =>
                                            handleHeritagePlaces(e, key)
                                          }
                                        />
                                      </div>
                                    </div>

                                    {/* dynamic fields image */}
                                    <label className="label_form ">
                                      Upload Media(Only JPG/PNG)
                                    </label>
                                    {heritagePlacesMedia[key]?.media.map(
                                      (item, subIndex) => {
                                        return (
                                          <div
                                            className={"form-row d-flex"}
                                            key={key}
                                          >
                                            <div
                                              className={
                                                "form-group  col-md-6 " +
                                                (key == 0)
                                                  ? "pt-3"
                                                  : ""
                                              }
                                            >
                                              <div className="position-relative">
                                                <textarea
                                                  className="form-control d-flex bg-input-custom "
                                                  value={item.caption}
                                                  name="caption"
                                                  onChange={(e) =>
                                                    handleCaptionChange(
                                                      key,
                                                      e,
                                                      subIndex,
                                                      "heritagePlaces"
                                                    )
                                                  }
                                                  rows="5"
                                                />

                                                {/* delete btn wont show for first value */}
                                                {subIndex != 0 ? (
                                                  <img
                                                    src={MinusIcon}
                                                    className="position-absolute delete-button-custom"
                                                    onClick={() =>
                                                      removeFormFieldsMedia(
                                                        key,
                                                        subIndex,
                                                        "heritagePlaces"
                                                      )
                                                    }
                                                  />
                                                ) : (
                                                  <></>
                                                )}
                                              </div>

                                              {/* showing file if available */}
                                              {heritagePlacesMedia[key].media[
                                                subIndex
                                              ].media_name != "" ? (
                                                heritagePlacesMedia[key].media[
                                                  subIndex
                                                ].media_name
                                              ) : (
                                                <>
                                                  {uploadingToFirebase
                                                    ? "uploading, please wait"
                                                    : null}
                                                </>
                                              )}
                                              {heritagePlacesMedia[key].media[
                                                subIndex
                                              ].media_name != "" ? (
                                                <span
                                                  className="mx-4"
                                                  role="button"
                                                  onClick={() =>
                                                    removeFileMedia(
                                                      key,
                                                      subIndex,
                                                      "heritagePlaces"
                                                    )
                                                  }
                                                >
                                                  X
                                                </span>
                                              ) : (
                                                " "
                                              )}
                                            </div>
                                            <div className="form-group col-md-6">
                                              <input
                                                type="file"
                                                name="file"
                                                id="custom-files-hidden"
                                                ref={filepick}
                                                onChange={(e) =>
                                                  onFileChange(
                                                    e,
                                                    "heritagePlaces"
                                                  )
                                                }
                                                onClick={(event) => {
                                                  event.target.value = null;
                                                }}
                                                hidden
                                                className="hidden"
                                                accept="image/jpg, image/png, image/jpeg"
                                              />{" "}
                                              <label
                                                onClick={() => {
                                                  setMediaIndex(key);
                                                  setMediaSubIndex(subIndex);
                                                }}
                                                for="custom-files-hidden"
                                                className="btn_browse "
                                                role="button"
                                              >
                                                Browse
                                              </label>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}

                                    <div
                                      className="dynamic-button-add dynamic-button-add-temp"
                                      onClick={() =>
                                        addFormFieldsMedia(
                                          key,
                                          "heritagePlaces"
                                        )
                                      }
                                    >
                                      Add More
                                      <img
                                        className="plus-icon"
                                        src={PlusIcon}
                                      />
                                    </div>

                                    {/* if the index is one, then dont show delete button */}
                                    {key != 0 ? (
                                      <button
                                        className="add-new-field-btn img-custom-minus"
                                        onClick={() =>
                                          handleRemoveHeritagePlaces(key)
                                        }
                                      >
                                        Remove
                                      </button>
                                    ) : null}
                                    <hr
                                      //couldn't help myself
                                      style={{
                                        opacity: 0.1,
                                      }}
                                      className="mt-4"
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </form>
                        </div>
                        <div>
                          <button
                            className="add-new-field-btn"
                            onClick={handleAddHeritagePlaces}
                          >
                            Add More
                          </button>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {/* ----------------------------------------------ORNAMENTS---------------------------------------------------- */}

                  {selectedTopic === "ornaments" ? (
                    <div className="col-md-12 col-sm-12 p-1 form_main">
                      <div>
                        <form>
                          <div className="add-village-container-sub-field">
                            <div className="main_heading ">
                              Fill The Details of Village
                              <hr></hr>
                            </div>
                            {ornament.map((item, key) => {
                              return (
                                <div className="position-relative">
                                  <div className="d-flex pt-4">
                                    <div className="form-group ">
                                      <label className="label_form  d-flex">
                                        Name
                                      </label>
                                      <div className="d-flex align-center">
                                        <input
                                          type="text"
                                          className="form-control bg-input-custom"
                                          name="title"
                                          value={item.title}
                                          onChange={(e) =>
                                            handleOrnament(e, key)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-group">
                                    <label className="label_form  d-flex">
                                      Description
                                    </label>
                                    <div className="d-flex align-center">
                                      <textarea
                                        className="form-control text-area2 bg-input-custom"
                                        name="description"
                                        value={item.description}
                                        rows="5"
                                        onChange={(e) => handleOrnament(e, key)}
                                      ></textarea>
                                    </div>
                                  </div>
                                  {/* dynamic fields image */}
                                  <label className="label_form ">
                                    Upload Media(Only JPG/PNG)
                                  </label>
                                  {ornamentsMedia[key]?.media.map(
                                    (item, subIndex) => {
                                      return (
                                        <div
                                          className={"form-row d-flex"}
                                          key={key}
                                        >
                                          <div
                                            className={
                                              "form-group  col-md-6 " +
                                              (key == 0)
                                                ? "pt-3"
                                                : ""
                                            }
                                          >
                                            <div className="position-relative">
                                              <textarea
                                                className="form-control d-flex bg-input-custom "
                                                value={item.caption}
                                                name="caption"
                                                onChange={(e) =>
                                                  handleCaptionChange(
                                                    key,
                                                    e,
                                                    subIndex,
                                                    "ornaments"
                                                  )
                                                }
                                                rows="5"
                                              />

                                              {/* delete btn wont show for first value */}
                                              {subIndex != 0 ? (
                                                <img
                                                  src={MinusIcon}
                                                  className="position-absolute delete-button-custom"
                                                  onClick={() =>
                                                    removeFormFieldsMedia(
                                                      key,
                                                      subIndex,
                                                      "ornaments"
                                                    )
                                                  }
                                                />
                                              ) : (
                                                <></>
                                              )}
                                            </div>

                                            {/* showing file if available */}
                                            {ornamentsMedia[key].media[subIndex]
                                              .media_name != "" ? (
                                              ornamentsMedia[key].media[
                                                subIndex
                                              ].media_name
                                            ) : (
                                              <>
                                                {uploadingToFirebase
                                                  ? "uploading, please wait"
                                                  : null}
                                              </>
                                            )}
                                            {ornamentsMedia[key].media[subIndex]
                                              .media_name != "" ? (
                                              <span
                                                className="mx-4"
                                                role="button"
                                                onClick={() =>
                                                  removeFileMedia(
                                                    key,
                                                    subIndex,
                                                    "ornaments"
                                                  )
                                                }
                                              >
                                                X
                                              </span>
                                            ) : (
                                              " "
                                            )}
                                          </div>
                                          <div className="form-group col-md-6">
                                            <input
                                              type="file"
                                              name="file"
                                              id="custom-files-hidden"
                                              ref={filepick}
                                              onChange={(e) =>
                                                onFileChange(e, "ornaments")
                                              }
                                              onClick={(event) => {
                                                event.target.value = null;
                                              }}
                                              hidden
                                              className="hidden"
                                              accept="image/jpg, image/png, image/jpeg"
                                            />{" "}
                                            <label
                                              onClick={() => {
                                                setMediaIndex(key);
                                                setMediaSubIndex(subIndex);
                                              }}
                                              for="custom-files-hidden"
                                              className="btn_browse "
                                              role="button"
                                            >
                                              Browse
                                            </label>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}

                                  <div
                                    className="dynamic-button-add dynamic-button-add-temp"
                                    onClick={() =>
                                      addFormFieldsMedia(key, "ornaments")
                                    }
                                  >
                                    Add More
                                    <img className="plus-icon" src={PlusIcon} />
                                  </div>

                                  {/* if the index is one, then dont show delete button */}
                                  {key != 0 ? (
                                    <button
                                      className="add-new-field-btn img-custom-minus"
                                      onClick={() => handleRemoveOrnament(key)}
                                    >
                                      Remove
                                    </button>
                                  ) : null}
                                  <hr
                                    //couldn't help myself
                                    style={{
                                      opacity: 0.1,
                                    }}
                                    className="mt-4"
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </form>
                      </div>
                      <div>
                        <button
                          className="add-new-field-btn"
                          onClick={handleAddOrnament}
                        >
                          Add More
                        </button>
                      </div>
                    </div>
                  ) : null}

                  {/* ----------------------------------------------TRADITIONAL DRESS---------------------------------------------------- */}

                  {selectedTopic === "traditional_dress" ? (
                    <>
                      <div className="col-md-12 col-sm-12 p-1 form_main">
                        <div>
                          <form>
                            <div className="add-village-container-sub-field">
                              <div className="main_heading ">
                                Fill The Details of Village
                                <hr></hr>
                              </div>
                              {traditionalDress.map((item, key) => {
                                return (
                                  <div className="position-relative">
                                    <div className="d-flex pt-4">
                                      <div className="form-group ">
                                        <label className="label_form  d-flex">
                                          Name
                                        </label>
                                        <div className="d-flex align-center">
                                          <input
                                            type="text"
                                            className="form-control bg-input-custom"
                                            name="title"
                                            value={item.title}
                                            onChange={(e) =>
                                              handleTraditionalDress(e, key)
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <label className="label_form  d-flex">
                                        Description
                                      </label>
                                      <div className="d-flex align-center">
                                        <textarea
                                          className="form-control text-area2 bg-input-custom"
                                          name="description"
                                          value={item.description}
                                          id="inputCity"
                                          rows="5"
                                          onChange={(e) =>
                                            handleTraditionalDress(e, key)
                                          }
                                        ></textarea>
                                      </div>
                                    </div>

                                    {/* dynamic fields image */}
                                    <label className="label_form ">
                                      Upload Media(Only JPG/PNG)
                                    </label>
                                    {traditionalDressMedia[key]?.media.map(
                                      (item, subIndex) => {
                                        return (
                                          <div
                                            className={"form-row d-flex"}
                                            key={key}
                                          >
                                            <div
                                              className={
                                                "form-group  col-md-6 " +
                                                (key == 0)
                                                  ? "pt-3"
                                                  : ""
                                              }
                                            >
                                              <div className="position-relative">
                                                <textarea
                                                  className="form-control d-flex bg-input-custom "
                                                  value={item.caption}
                                                  name="caption"
                                                  onChange={(e) =>
                                                    handleCaptionChange(
                                                      key,
                                                      e,
                                                      subIndex,
                                                      "traditionalDress"
                                                    )
                                                  }
                                                  rows="5"
                                                />

                                                {/* delete btn wont show for first value */}
                                                {subIndex != 0 ? (
                                                  <img
                                                    src={MinusIcon}
                                                    className="position-absolute delete-button-custom"
                                                    onClick={() =>
                                                      removeFormFieldsMedia(
                                                        key,
                                                        subIndex,
                                                        "traditionalDress"
                                                      )
                                                    }
                                                  />
                                                ) : (
                                                  <></>
                                                )}
                                              </div>

                                              {/* showing file if available */}
                                              {traditionalDressMedia[key].media[
                                                subIndex
                                              ].media_name != "" ? (
                                                traditionalDressMedia[key]
                                                  .media[subIndex].media_name
                                              ) : (
                                                <>
                                                  {uploadingToFirebase
                                                    ? "uploading, please wait"
                                                    : null}
                                                </>
                                              )}
                                              {traditionalDressMedia[key].media[
                                                subIndex
                                              ].media_name != "" ? (
                                                <span
                                                  className="mx-4"
                                                  role="button"
                                                  onClick={() =>
                                                    removeFileMedia(
                                                      key,
                                                      subIndex,
                                                      "traditionalDress"
                                                    )
                                                  }
                                                >
                                                  X
                                                </span>
                                              ) : (
                                                " "
                                              )}
                                            </div>
                                            <div className="form-group col-md-6">
                                              <input
                                                type="file"
                                                name="file"
                                                id="custom-files-hidden"
                                                ref={filepick}
                                                onChange={(e) =>
                                                  onFileChange(
                                                    e,
                                                    "traditionalDress"
                                                  )
                                                }
                                                onClick={(event) => {
                                                  event.target.value = null;
                                                }}
                                                hidden
                                                className="hidden"
                                                accept="image/jpg, image/png, image/jpeg"
                                              />{" "}
                                              <label
                                                onClick={() => {
                                                  setMediaIndex(key);
                                                  setMediaSubIndex(subIndex);
                                                }}
                                                for="custom-files-hidden"
                                                className="btn_browse "
                                                role="button"
                                              >
                                                Browse
                                              </label>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}

                                    <div
                                      className="dynamic-button-add dynamic-button-add-temp"
                                      onClick={() =>
                                        addFormFieldsMedia(
                                          key,
                                          "traditionalDress"
                                        )
                                      }
                                    >
                                      Add More
                                      <img
                                        className="plus-icon"
                                        src={PlusIcon}
                                      />
                                    </div>

                                    {key != 0 ? (
                                      <button
                                        className="add-new-field-btn img-custom-minus"
                                        onClick={() =>
                                          handleRemoveTraditionalDress(key)
                                        }
                                      >
                                        Remove
                                      </button>
                                    ) : null}
                                    <hr
                                      //couldn't help myself
                                      style={{
                                        opacity: 0.1,
                                      }}
                                      className="mt-4"
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </form>{" "}
                        </div>
                        <div>
                          <button
                            className="add-new-field-btn"
                            onClick={handleAddTraditionalDress}
                          >
                            Add More
                          </button>
                        </div>
                      </div>
                    </>
                  ) : null}

                  {/* ----------------------------------------------prominent_artists---------------------------------------------- */}

                  {selectedTopic === "prominent_artists" ? (
                    <>
                      <div className="col-md-12 col-sm-12 p-1 form_main">
                        <div>
                          <form>
                            <div className="add-village-container-sub-field">
                              <div className="main_heading ">
                                Fill The Details of Village
                                <hr></hr>
                              </div>

                              {prominentArtists.map((item, key) => {
                                return (
                                  <div className="position-relative">
                                    <div className="d-flex pt-4">
                                      <div className="form-group ">
                                        <label className="label_form ">
                                          Name
                                        </label>
                                        <div className="d-flex align-center">
                                          <input
                                            type="text"
                                            className="form-control bg-input-custom"
                                            name="name"
                                            value={item.name}
                                            onChange={(e) =>
                                              handleProminentArtists(e, key)
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="form-row d-flex devlop">
                                      <div className="form-group col-md-6">
                                        <label className="label_form   ">
                                          Category Expertise
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control bg-input-custom"
                                          value={item.expertise_of_category}
                                          name="expertise_of_category"
                                          onChange={(e) =>
                                            handleProminentArtists(e, key)
                                          }
                                        />
                                      </div>
                                      <div className="form-group col-md-6 mx-1">
                                        <label className="label_form   ">
                                          Expertise Of Skill
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control bg-input-custom"
                                          name="expertise_of_skills"
                                          value={item.expertise_of_skills}
                                          onChange={(e) =>
                                            handleProminentArtists(e, key)
                                          }
                                        />
                                      </div>
                                    </div>

                                    <div className="form-row d-flex devlop">
                                      <div className="form-group col-md-6">
                                        <label className="label_form   ">
                                          ID Card
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control bg-input-custom"
                                          value={item.id_card_number}
                                          name="id_card_number"
                                          onChange={(e) =>
                                            handleProminentArtists(e, key)
                                          }
                                        />
                                      </div>
                                      <div className="form-group col-md-6   mx-1">
                                        <label className="label_form   ">
                                          Address
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control bg-input-custom"
                                          name="address"
                                          value={item.address}
                                          onChange={(e) =>
                                            handleProminentArtists(e, key)
                                          }
                                        />
                                      </div>
                                    </div>

                                    <div className="form-row d-flex devlop">
                                      <div className="form-group col-md-6">
                                        <label className="label_form   ">
                                          Age
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control bg-input-custom"
                                          value={item.age}
                                          name="age"
                                          onChange={(e) =>
                                            handleProminentArtists(e, key)
                                          }
                                        />
                                      </div>
                                    </div>

                                    <div className="form-row d-flex devlop">
                                      <div className="form-group col-md-6">
                                        <label className="label_form   ">
                                          Bank Account
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control bg-input-custom"
                                          value={item.bank_account_number}
                                          name="bank_account_number"
                                          onChange={(e) =>
                                            handleProminentArtists(e, key)
                                          }
                                        />
                                      </div>
                                      <div className="form-group col-md-6   mx-1">
                                        <label className="label_form  ">
                                          Account Name
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control bg-input-custom"
                                          name="bank_account_holder_name"
                                          value={item.bank_account_holder_name}
                                          onChange={(e) =>
                                            handleProminentArtists(e, key)
                                          }
                                        />
                                      </div>
                                    </div>

                                    <div className="form-row d-flex devlop">
                                      <div className="form-group col-md-6 ">
                                        <label className="label_form   ">
                                          Branch Name
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control bg-input-custom"
                                          value={item.branch_name}
                                          name="branch_name"
                                          onChange={(e) =>
                                            handleProminentArtists(e, key)
                                          }
                                        />
                                      </div>
                                      <div className="form-group col-md-6   mx-1">
                                        <label className="label_form ">
                                          IFSC Code
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control bg-input-custom"
                                          name="ifsc_code"
                                          value={item.ifsc_code}
                                          onChange={(e) =>
                                            handleProminentArtists(e, key)
                                          }
                                        />
                                      </div>
                                    </div>

                                    <div className="form-row d-flex devlop">
                                      <div className="form-group col-md-6">
                                        <label className="label_form   ">
                                          Gender
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control bg-input-custom"
                                          value={item.gender}
                                          name="gender"
                                          onChange={(e) =>
                                            handleProminentArtists(e, key)
                                          }
                                        />
                                      </div>
                                      <div className="form-group col-md-6 mx-1">
                                        <label className="label_form ">
                                          Mobile Number
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control bg-input-custom"
                                          name="mobile"
                                          value={item.mobile}
                                          onChange={(e) =>
                                            handleProminentArtists(e, key)
                                          }
                                        />
                                      </div>
                                    </div>
                                    {/* dynamic fields image */}
                                    <label className="label_form ">
                                      Upload Media(Only JPG/PNG)
                                    </label>
                                    {prominentArtistsMedia[key]?.media.map(
                                      (item, subIndex) => {
                                        return (
                                          <div
                                            className={"form-row d-flex"}
                                            key={key}
                                          >
                                            <div
                                              className={
                                                "form-group  col-md-6 " +
                                                (key == 0)
                                                  ? "pt-3"
                                                  : ""
                                              }
                                            >
                                              <div className="position-relative">
                                                <textarea
                                                  className="form-control d-flex bg-input-custom "
                                                  value={item.caption}
                                                  name="caption"
                                                  onChange={(e) =>
                                                    handleCaptionChange(
                                                      key,
                                                      e,
                                                      subIndex,
                                                      "prominentArtists"
                                                    )
                                                  }
                                                  on
                                                  rows="5"
                                                />

                                                {/* delete btn wont show for first value */}
                                                {subIndex != 0 ? (
                                                  <img
                                                    src={MinusIcon}
                                                    className="position-absolute delete-button-custom"
                                                    onClick={() =>
                                                      removeFormFieldsMedia(
                                                        key,
                                                        subIndex,
                                                        "prominentArtists"
                                                      )
                                                    }
                                                  />
                                                ) : (
                                                  <></>
                                                )}
                                              </div>

                                              {/* showing file if available */}
                                              {prominentArtistsMedia[key].media[
                                                subIndex
                                              ].media_name != "" ? (
                                                prominentArtistsMedia[key]
                                                  .media[subIndex].media_name
                                              ) : (
                                                <>
                                                  {uploadingToFirebase
                                                    ? "uploading, please wait"
                                                    : null}
                                                </>
                                              )}
                                              {prominentArtistsMedia[key].media[
                                                subIndex
                                              ].media_name != "" ? (
                                                <span
                                                  className="mx-4"
                                                  role="button"
                                                  onClick={() =>
                                                    removeFileMedia(
                                                      key,
                                                      subIndex,
                                                      "prominentArtists"
                                                    )
                                                  }
                                                >
                                                  X
                                                </span>
                                              ) : (
                                                " "
                                              )}
                                            </div>
                                            <div className="form-group col-md-6">
                                              <input
                                                type="file"
                                                name="file"
                                                id="custom-files-hidden"
                                                ref={filepick}
                                                onChange={(e) =>
                                                  onFileChange(
                                                    e,
                                                    "prominentArtists"
                                                  )
                                                }
                                                onClick={(event) => {
                                                  event.target.value = null;
                                                }}
                                                hidden
                                                className="hidden"
                                                accept="image/jpg, image/png, image/jpeg"
                                              />{" "}
                                              <label
                                                onClick={() => {
                                                  setMediaIndex(key);
                                                  setMediaSubIndex(subIndex);
                                                }}
                                                for="custom-files-hidden"
                                                className="btn_browse "
                                                role="button"
                                              >
                                                Browse
                                              </label>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}

                                    <div
                                      className="dynamic-button-add dynamic-button-add-temp"
                                      onClick={() =>
                                        addFormFieldsMedia(
                                          key,
                                          "prominentArtists"
                                        )
                                      }
                                    >
                                      Add More
                                      <img
                                        className="plus-icon"
                                        src={PlusIcon}
                                        alt="PlusIcon"
                                      />
                                    </div>

                                    {/* if the index is one, then dont show delete button */}
                                    {key != 0 ? (
                                      <button
                                        className="add-new-field-btn img-custom-minus"
                                        onClick={() =>
                                          handleRemoveProminentArtists(key)
                                        }
                                      >
                                        Remove
                                      </button>
                                    ) : null}
                                    <hr
                                      //couldn't help myself
                                      style={{
                                        opacity: 0.1,
                                      }}
                                      className="mt-4"
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </form>
                        </div>
                        <div>
                          <button
                            className="add-new-field-btn"
                            onClick={handleAddProminentArtists}
                          >
                            Add More
                          </button>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {/* ----------------------------------------------FAMOUS PERSONALITY---------------------------------------------------- */}

                  {selectedTopic === "famous_personalities" ? (
                    <>
                      <div className="col-md-12 col-sm-12 p-1 form_main">
                        <div>
                          <form>
                            <div className="add-village-container-sub-field">
                              <div className="main_heading ">
                                Fill The Details of Village
                                <hr></hr>
                              </div>
                              {famousPersonalities.map((item, key) => {
                                return (
                                  <div className="position-relative">
                                    <div className="d-flex pt-4">
                                      <div className="form-group ">
                                        <label className="label_form  d-flex">
                                          Name
                                        </label>
                                        <div className="d-flex align-center">
                                          <input
                                            type="text"
                                            className="form-control bg-input-custom"
                                            name="name_of_local_hero"
                                            value={item.name_of_local_hero}
                                            onChange={(e) =>
                                              handleFamousPersonalities(e, key)
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <label className="label_form  d-flex">
                                        Description
                                      </label>
                                      <div className="d-flex align-center">
                                        <textarea
                                          className="form-control text-area2 bg-input-custom"
                                          name="description"
                                          value={item.description}
                                          id="inputCity"
                                          rows="5"
                                          onChange={(e) =>
                                            handleFamousPersonalities(e, key)
                                          }
                                        ></textarea>
                                      </div>
                                    </div>

                                    <div className="form-row d-flex devlop">
                                      <div className="form-group col-md-6">
                                        <label className="label_form   ">
                                          Family Related To
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control bg-input-custom"
                                          value={item.family_related_to}
                                          name="family_related_to"
                                          onChange={(e) =>
                                            handleFamousPersonalities(e, key)
                                          }
                                        />
                                      </div>
                                      <div className="form-group col-md-6 mx-1">
                                        <label className="label_form   ">
                                          Personal Details
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control bg-input-custom"
                                          value={item.personal_details}
                                          name="personal_details"
                                          onChange={(e) =>
                                            handleFamousPersonalities(e, key)
                                          }
                                        />
                                      </div>
                                    </div>

                                    <div className="form-group">
                                      <label className="label_form">
                                        Legend Stories
                                      </label>

                                      <div className="d-flex align-center">
                                        <textarea
                                          className="form-control text-area2 bg-input-custom"
                                          type="text"
                                          name="legend_stories"
                                          value={item.legend_stories}
                                          onChange={(e) =>
                                            handleFamousPersonalities(e, key)
                                          }
                                          rows={5}
                                        ></textarea>
                                      </div>
                                    </div>
                                    {/* dynamic fields image */}
                                    <label className="label_form ">
                                      Upload Media(Only JPG/PNG)
                                    </label>
                                    {famousPersonalitiesMedia[key]?.media.map(
                                      (item, subIndex) => {
                                        return (
                                          <div
                                            className={"form-row d-flex"}
                                            key={key}
                                          >
                                            <div
                                              className={
                                                "form-group  col-md-6 " +
                                                (key == 0)
                                                  ? "pt-3"
                                                  : ""
                                              }
                                            >
                                              <div className="position-relative">
                                                <textarea
                                                  className="form-control d-flex bg-input-custom "
                                                  value={item.caption}
                                                  name="caption"
                                                  onChange={(e) =>
                                                    handleCaptionChange(
                                                      key,
                                                      e,
                                                      subIndex,
                                                      "famousPersonalities"
                                                    )
                                                  }
                                                  rows="5"
                                                />

                                                {/* delete btn wont show for first value */}
                                                {subIndex != 0 ? (
                                                  <img
                                                    src={MinusIcon}
                                                    className="position-absolute delete-button-custom"
                                                    onClick={() =>
                                                      removeFormFieldsMedia(
                                                        key,
                                                        subIndex,
                                                        "famousPersonalities"
                                                      )
                                                    }
                                                  />
                                                ) : (
                                                  <></>
                                                )}
                                              </div>

                                              {/* showing file if available */}
                                              {famousPersonalitiesMedia[key]
                                                .media[subIndex].media_name !=
                                              "" ? (
                                                famousPersonalitiesMedia[key]
                                                  .media[subIndex].media_name
                                              ) : (
                                                <>
                                                  {uploadingToFirebase
                                                    ? "uploading, please wait"
                                                    : null}
                                                </>
                                              )}
                                              {famousPersonalitiesMedia[key]
                                                .media[subIndex].media_name !=
                                              "" ? (
                                                <span
                                                  className="mx-4"
                                                  role="button"
                                                  onClick={() =>
                                                    removeFileMedia(
                                                      key,
                                                      subIndex,
                                                      "famousPersonalities"
                                                    )
                                                  }
                                                >
                                                  X
                                                </span>
                                              ) : (
                                                " "
                                              )}
                                            </div>
                                            <div className="form-group col-md-6">
                                              <input
                                                type="file"
                                                name="file"
                                                id="custom-files-hidden"
                                                ref={filepick}
                                                onChange={(e) =>
                                                  onFileChange(
                                                    e,
                                                    "famousPersonalities"
                                                  )
                                                }
                                                onClick={(event) => {
                                                  event.target.value = null;
                                                }}
                                                hidden
                                                className="hidden"
                                                accept="image/jpg, image/png, image/jpeg"
                                              />{" "}
                                              <label
                                                onClick={() => {
                                                  setMediaIndex(key);
                                                  setMediaSubIndex(subIndex);
                                                }}
                                                for="custom-files-hidden"
                                                className="btn_browse "
                                                role="button"
                                              >
                                                Browse
                                              </label>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}

                                    <div
                                      className="dynamic-button-add dynamic-button-add-temp"
                                      onClick={() =>
                                        addFormFieldsMedia(
                                          key,
                                          "famousPersonalities"
                                        )
                                      }
                                    >
                                      Add More
                                      <img
                                        className="plus-icon"
                                        src={PlusIcon}
                                        alt="PlusIcon"
                                      />
                                    </div>

                                    {/* if the index is one, then dont show delete button */}
                                    {key != 0 ? (
                                      <button
                                        className="add-new-field-btn img-custom-minus"
                                        onClick={() =>
                                          handleRemoveFamousPersonalities(key)
                                        }
                                      >
                                        Remove
                                      </button>
                                    ) : null}
                                    <hr
                                      //couldn't help myself
                                      style={{ opacity: 0.1 }}
                                      className="mt-4"
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </form>{" "}
                        </div>
                        <div>
                          <button
                            className="add-new-field-btn"
                            onClick={handleAddFamousPersonalities}
                          >
                            Add More
                          </button>
                        </div>
                      </div>
                    </>
                  ) : null}

                  {/* ----------------------------------------------TRADITIONAL ART---------------------------------------------------- */}

                  {selectedTopic === "traditional_art" ? (
                    <>
                      <div className="mx-1 my-1">
                        <div>
                          <div>
                            <form>
                              <div className="add-village-container-sub-field">
                                <div className="main_heading ">
                                  Fill The Details of Village
                                  <hr></hr>
                                </div>
                                {traditionalArtAndCraft.map((item, key) => {
                                  return (
                                    <div className="position-relative">
                                      <div className="d-flex ">
                                        <div className="form-group pt-3 ">
                                          <label className="label_form ">
                                            Name of Art
                                          </label>
                                          <div className="d-flex align-center">
                                            <input
                                              type="text"
                                              className="form-control text-area bg-input-custom"
                                              name="name_of_art"
                                              value={item.name_of_art}
                                              onChange={(e) =>
                                                handleTraditionalArtAndCraft(
                                                  e,
                                                  key
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="form-group">
                                        <label className="label_form ">
                                          Description
                                        </label>
                                        <div className="d-flex align-center">
                                          <textarea
                                            type="text"
                                            className="form-control text-area2 bg-input-custom"
                                            id="inputCity"
                                            name="description"
                                            value={item.description}
                                            rows="5"
                                            cols="15"
                                            onChange={(e) =>
                                              handleTraditionalArtAndCraft(
                                                e,
                                                key
                                              )
                                            }
                                          ></textarea>
                                        </div>
                                      </div>

                                      <div className="form-row d-flex">
                                        <div className="form-group col-md-6">
                                          <label
                                            for="inputAddress"
                                            className="label_form "
                                          >
                                            Classification
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control bg-input-custom"
                                            name="classification_of_art_form"
                                            value={
                                              item.classification_of_art_form
                                            }
                                            onChange={(e) =>
                                              handleTraditionalArtAndCraft(
                                                e,
                                                key
                                              )
                                            }
                                          />
                                        </div>
                                        <div className="form-group col-md-6 mx-1">
                                          <label
                                            for="inputAddress"
                                            className="label_form "
                                          >
                                            Color(s) Used
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control bg-input-custom extra"
                                            name="color_used"
                                            value={item.color_used}
                                            onChange={(e) =>
                                              handleTraditionalArtAndCraft(
                                                e,
                                                key
                                              )
                                            }
                                          />
                                        </div>
                                      </div>

                                      <div className="form-row d-flex">
                                        <div className="form-group col-md-6">
                                          <label
                                            for="inputAddress"
                                            className="label_form "
                                          >
                                            Design And Motifs
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control bg-input-custom"
                                            name="design_and_motifs"
                                            value={item.design_and_motifs}
                                            onChange={(e) =>
                                              handleTraditionalArtAndCraft(
                                                e,
                                                key
                                              )
                                            }
                                          />
                                        </div>
                                        <div className="form-group col-md-6 mx-1">
                                          <label
                                            for="inputAddress"
                                            className="label_form "
                                          >
                                            Tools Used
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control bg-input-custom extra"
                                            name="implement_tools"
                                            value={item.implement_tools}
                                            onChange={(e) =>
                                              handleTraditionalArtAndCraft(
                                                e,
                                                key
                                              )
                                            }
                                          />
                                        </div>
                                      </div>

                                      <div className="form-row d-flex">
                                        <div className="form-group col-md-6">
                                          <label
                                            for="inputAddress"
                                            className="label_form"
                                          >
                                            Individual Or Group
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control bg-input-custom"
                                            name="individual_or_group_activity"
                                            value={
                                              item?.individual_or_group_activity
                                            }
                                            onChange={(e) =>
                                              handleTraditionalArtAndCraft(
                                                e,
                                                key
                                              )
                                            }
                                          />
                                        </div>
                                        <div className="form-group col-md-6 mx-1">
                                          <label
                                            for="inputAddress"
                                            className="label_form "
                                          >
                                            Material Used
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control bg-input-custom extra"
                                            name="material_used"
                                            value={item.material_used}
                                            onChange={(e) =>
                                              handleTraditionalArtAndCraft(
                                                e,
                                                key
                                              )
                                            }
                                          />
                                        </div>
                                      </div>

                                      <div className="form-row d-flex">
                                        <div className="form-group col-md-6">
                                          <label
                                            for="inputAddress"
                                            className="label_form "
                                          >
                                            Display Occasion
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control bg-input-custom"
                                            name="occasion_display_and_sale"
                                            value={
                                              item?.occasion_display_and_sale
                                            }
                                            onChange={(e) =>
                                              handleTraditionalArtAndCraft(
                                                e,
                                                key
                                              )
                                            }
                                          />
                                        </div>
                                        <div className="form-group col-md-6 mx-1">
                                          <label
                                            for="inputAddress"
                                            className="label_form "
                                          >
                                            Practiced by
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control bg-input-custom extra"
                                            name="practicing_communities"
                                            value={item.practicing_communities}
                                            onChange={(e) =>
                                              handleTraditionalArtAndCraft(
                                                e,
                                                key
                                              )
                                            }
                                          />
                                        </div>
                                      </div>

                                      <div className="form-row d-flex">
                                        <div className="form-group col-md-6 ">
                                          <label
                                            for="inputAddress"
                                            className="label_form"
                                          >
                                            Present Status
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control bg-input-custom"
                                            name="present_status_of_art_form"
                                            value={
                                              item?.present_status_of_art_form
                                            }
                                            onChange={(e) =>
                                              handleTraditionalArtAndCraft(
                                                e,
                                                key
                                              )
                                            }
                                          />
                                        </div>
                                        <div className="form-group col-md-6 mx-1">
                                          <label
                                            for="inputAddress"
                                            className="label_form "
                                          >
                                            Associations with
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control bg-input-custom extra"
                                            name="specific_communities_associated_with"
                                            value={
                                              item?.specific_communities_associated_with
                                            }
                                            onChange={(e) =>
                                              handleTraditionalArtAndCraft(
                                                e,
                                                key
                                              )
                                            }
                                          />
                                        </div>
                                      </div>

                                      <div className="form-row d-flex">
                                        <div className="form-group col-md-6 ">
                                          <label
                                            for="inputAddress"
                                            className="label_form"
                                          >
                                            Associated Songs
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control bg-input-custom"
                                            name="stories_songs"
                                            value={item?.stories_songs}
                                            onChange={(e) =>
                                              handleTraditionalArtAndCraft(
                                                e,
                                                key
                                              )
                                            }
                                          />
                                        </div>
                                        <div className="form-group col-md-6 mx-1">
                                          <label
                                            for="inputAddress"
                                            className="label_form "
                                          >
                                            Origin story
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control bg-input-custom extra"
                                            name="story_on_origin_of_art"
                                            value={item?.story_on_origin_of_art}
                                            onChange={(e) =>
                                              handleTraditionalArtAndCraft(
                                                e,
                                                key
                                              )
                                            }
                                          />
                                        </div>
                                      </div>

                                      <div className="form-row d-flex">
                                        <div className="form-group col-md-6">
                                          <label
                                            for="inputAddress"
                                            className="label_form "
                                          >
                                            Type Of Art
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control bg-input-custom"
                                            name="type_of_art_craft"
                                            value={item?.type_of_art_craft}
                                            onChange={(e) =>
                                              handleTraditionalArtAndCraft(
                                                e,
                                                key
                                              )
                                            }
                                          />
                                        </div>
                                        <div className="form-group col-md-6 mx-1">
                                          <label
                                            for="inputAddress"
                                            className="label_form "
                                          >
                                            Sub-Type
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control bg-input-custom extra"
                                            name="sub_type_of_art_craft"
                                            value={item?.sub_type_of_art_craft}
                                            onChange={(e) =>
                                              handleTraditionalArtAndCraft(
                                                e,
                                                key
                                              )
                                            }
                                          />
                                        </div>
                                      </div>

                                      <div className="form-row d-flex">
                                        <div className="form-group col-md-6 ">
                                          <label
                                            for="inputAddress"
                                            className="label_form "
                                          >
                                            economic ideas
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control bg-input-custom"
                                            name="suggestion_for_revival_and_economic"
                                            value={
                                              item?.suggestion_for_revival_and_economic
                                            }
                                            onChange={(e) =>
                                              handleTraditionalArtAndCraft(
                                                e,
                                                key
                                              )
                                            }
                                          />
                                        </div>
                                        <div className="form-group col-md-6 mx-1">
                                          <label
                                            for="inputAddress"
                                            className="label_form "
                                          >
                                            Technique Used
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control bg-input-custom extra"
                                            name="technique_used"
                                            value={item.technique_used}
                                            onChange={(e) =>
                                              handleTraditionalArtAndCraft(
                                                e,
                                                key
                                              )
                                            }
                                          />
                                        </div>
                                      </div>

                                      <div className="form-row d-flex">
                                        <div className="form-group col-md-6 ">
                                          <label
                                            for="inputAddress"
                                            className="label_form"
                                          >
                                            Is the Village Self Reliant?
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control bg-input-custom"
                                            name="village_self_reliant"
                                            value={item.village_self_reliant}
                                            onChange={(e) =>
                                              handleTraditionalArtAndCraft(
                                                e,
                                                key
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                      {/* dynamic fields image */}
                                      <label className="label_form ">
                                        Upload Media(Only JPG/PNG)
                                      </label>
                                      {traditionalArtAndCraftMedia[
                                        key
                                      ]?.media.map((item, subIndex) => {
                                        return (
                                          <div
                                            className="form-row d-flex"
                                            key={key}
                                          >
                                            <div
                                              className={
                                                "form-group  col-md-6 " +
                                                (key == 0)
                                                  ? "pt-3"
                                                  : ""
                                              }
                                            >
                                              <div className="position-relative">
                                                <textarea
                                                  className="form-control d-flex bg-input-custom "
                                                  value={item?.caption}
                                                  name="caption"
                                                  onChange={(e) =>
                                                    handleCaptionChange(
                                                      key,
                                                      e,
                                                      subIndex,
                                                      "traditionalArtAndCraft"
                                                    )
                                                  }
                                                  rows="5"
                                                />

                                                {/* delete btn wont show for first value */}
                                                {subIndex != 0 ? (
                                                  <img
                                                    src={MinusIcon}
                                                    className="position-absolute delete-button-custom"
                                                    onClick={() =>
                                                      removeFormFieldsMedia(
                                                        key,
                                                        subIndex,
                                                        "traditionalArtAndCraft"
                                                      )
                                                    }
                                                  />
                                                ) : (
                                                  <></>
                                                )}
                                              </div>

                                              {/* showing file if available */}
                                              {traditionalArtAndCraftMedia[key]
                                                .media[subIndex].media_name !=
                                              "" ? (
                                                traditionalArtAndCraftMedia[key]
                                                  .media[subIndex].media_name
                                              ) : (
                                                <>
                                                  {uploadingToFirebase
                                                    ? "uploading, please wait"
                                                    : null}
                                                </>
                                              )}
                                              {traditionalArtAndCraftMedia[key]
                                                .media[subIndex].media_name !=
                                              "" ? (
                                                <span
                                                  className="mx-4"
                                                  role="button"
                                                  onClick={() =>
                                                    removeFileMedia(
                                                      key,
                                                      subIndex,
                                                      "traditionalArtAndCraft"
                                                    )
                                                  }
                                                >
                                                  X
                                                </span>
                                              ) : (
                                                " "
                                              )}
                                            </div>
                                            <div className="form-group col-md-6">
                                              <input
                                                type="file"
                                                name="file"
                                                id="custom-files-hidden"
                                                ref={filepick}
                                                onChange={(e) =>
                                                  onFileChange(
                                                    e,
                                                    "traditionalArtAndCraft"
                                                  )
                                                }
                                                onClick={(event) => {
                                                  event.target.value = null;
                                                }}
                                                hidden
                                                className="hidden"
                                                accept="image/jpg, image/png, image/jpeg"
                                              />{" "}
                                              <label
                                                onClick={() => {
                                                  setMediaIndex(key);
                                                  setMediaSubIndex(subIndex);
                                                }}
                                                for="custom-files-hidden"
                                                className="btn_browse "
                                                role="button"
                                              >
                                                Browse
                                              </label>
                                            </div>
                                          </div>
                                        );
                                      })}

                                      <div
                                        className="dynamic-button-add dynamic-button-add-temp"
                                        onClick={() =>
                                          addFormFieldsMedia(
                                            key,
                                            "traditionalArtAndCraft"
                                          )
                                        }
                                      >
                                        Add More
                                        <img
                                          className="plus-icon"
                                          src={PlusIcon}
                                          alt="PlusIcon"
                                        />
                                      </div>
                                      {/* if the index is one, then dont show delete button */}
                                      {key != 0 ? (
                                        <button
                                          className="add-new-field-btn img-custom-minus"
                                          onClick={() =>
                                            handleRemoveTraditionalArtAndCraft(
                                              key
                                            )
                                          }
                                        >
                                          Remove
                                        </button>
                                      ) : null}
                                      <hr
                                        //couldn't help myself
                                        style={{ opacity: 0.1 }}
                                        className="mt-4"
                                      />
                                    </div>
                                  );
                                })}
                              </div>
                            </form>{" "}
                          </div>
                          <div>
                            <button
                              className="add-new-field-btn"
                              onClick={handleAddTraditionalArtAndCraft}
                            >
                              Add More
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}
                  <div
                    className="p-2 final_submit"
                    // style={{ marginBottom: "20px" }}
                  >
                    <div className="p-2">
                      <div style={{ display: "flex", justifyContent: "end" }}>
                        {selectedTopic == "famous_personalities" ? (
                          ""
                        ) : (
                          <button
                            type="submit"
                            className="btn_submit btn-next-add-new-village ml-0"
                            onClick={() => routeChange()}
                          >
                            Next
                          </button>
                        )}
                        {loader ? (
                          <button className="btn_submit bg-green-custom">
                            <span
                              className="spinner-grow spinner-grow-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            Wait...
                          </button>
                        ) : (
                          <button
                            className={"btn_submit bg-green-custom float-right"}
                            onClick={(e) => handleSubmit(e, "final_submit")}
                          >
                            Submit{" "}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </MyLayout>
        </MyMobile>
      </div>
    </>
  );
};

export default withRouter(VillageDetails);

export const VillageImageDiv = styled.div`
  background: url(image.png);
  width: 100%;
  padding-bottom: 50%;
  background-size: contain;
  background-repeat: no-repeat !important;
  background-color: "#555";
  background-position: center;
  border-radius: 20px;
`;
