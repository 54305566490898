const CustomSearchBox = ({ _className, _onchangeFunc, value }) => {
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent the default form submission behavior
    }
  };
  return (
    <form noValidate action="" role="search" className={_className}>
      <input
        type="search"
        onChange={(event) => {
          _onchangeFunc(event);
        }}
        value={value}
        onKeyPress={handleKeyPress}
        className="search-bar shadow-sm"
        placeholder="Search by village name"
      />
    </form>
  );
};

export default CustomSearchBox;
