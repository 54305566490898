import React, { useEffect, useState } from "react";
import { connectMenu } from "react-instantsearch-dom";
import Select from "react-select";

const MenuSelectAddVillage = (props) => {
  const {
    searchBarActivated,
    mobileView,
    checkIfVillageAssigned, //if village has a value then select field will be disable for district and state
    error,
    onChangeFunc_,
    onChangeFun_,
    f_name,
    passedDownItems,
    passnewValue,
    passedValue,
  } = props;

  const [temp, setTemp] = useState();

  useEffect(() => {
    if (!!passedValue) {
      setTemp({ value: passedValue, label: passedValue });
    } else {
      setTemp(null);
    }
  }, [passedValue]);

  const onChange = (event) => {
    let _value = "";

    //event is undefined incase of clearing the value
    if (event != undefined) {
      _value = event?.value;
    }

    //passing in the value to parent component
    onChangeFunc_(_value, f_name);
    onChangeFun_(_value, f_name);
  };
  const onInputChange = (eve) => {
    passnewValue(eve);
  };

  return (
    <div>
      <Select
        value={temp}
        isDisabled={passedDownItems?.length == 0}
        className={"basic-single " + (mobileView ? " max-width-select" : "")}
        classNamePrefix="select"
        isSearchable={true}
        isClearable={true}
        name="categories"
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
        options={passedDownItems}
        onChange={onChange}
        onInputChange={onInputChange} //for village search from firebase directly
        placeholder={searchBarActivated ? "Search here.." : "Select"}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
          },
        })}
        styles={{
          control: (base) => ({
            ...base,
            border: !error && 0,
            borderColor: error && "red",
            boxShadow: "none",
          }),
          menuPortal: (provided) => ({ ...provided, zIndex: 1000 }),
          menu: (provided) => ({ ...provided, zIndex: 1000 }),
        }}
        // isDisabled={checkIfVillageAssigned}
        noOptionsMessage={({ inputValue }) =>
          !inputValue ? "Loading" : "No results found"
        }
      />
    </div>
  );
};

export default MenuSelectAddVillage;
