import { useEffect, useState } from "react";
import MyDesktop from "../../Components/MyComponents/MyDesktop";
import MyMobile from "../../Components/MyComponents/MyMobile";
import MyLayout from "../../Components/MyLayout/index";
import firebase from "../../../../src/firebase";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import ArtPopup from "./ArtPopup";

import IndibadgesImg from "../../Assets/img/General/indibadges.svg";
import AvatarDefault from "../../Assets/img/General/AvatarDefault.jpg";
import "./index.scss";
import axios from "axios";

const ViewProfile = () => {
  const [userInfo, setUserInfo] = useState();
  const [mediaData, setMediaData] = useState();
  const [popupMedia, setPopupMedia] = useState();
  const [loading, setLoading] = useState(false);
  const { artistId } = useParams();
  const [modalShow, setModalShow] = useState(false);
  const [readMore, setReadMore] = useState(false);
  const history = useHistory();
  const [imageData, setImageData] = useState("");
  useEffect(() => {
    setMediaData();
    setUserInfo();
    getUserInfo();
    getMediaData();
  }, [artistId]);

  useEffect(() => {
    if (userInfo && userInfo.hasOwnProperty("avatar")) {
      const fetchData = async () => {
        try {
          const url = `https://asia-south1-mgmd-356213.cloudfunctions.net/getSignedURLFromCF?url=https://d296j4bbogbdom.cloudfront.net/fit-in/400x400/images/${userInfo.avatar}`;
          const response = await axios.get(url);
          const data = response?.data?.data;

          setImageData(data);
        } catch (error) {
          console.error("Error fetching image data:", error);
        }
      };

      fetchData();
    }
    console.log(userInfo);
  }, [userInfo]);
  const getUserInfo = async () => {
    await firebase
      .firestore()
      .collection("users")
      .doc(artistId)
      .get()
      .then((doc) => {
        setUserInfo(doc.data());
      })
      .catch((err) => {
        console.log("err in getting user: ", err);
      });
  };

  const getMediaData = async () => {
    await firebase
      .firestore()
      .collection("artist_media")
      .where("userId", "==", artistId)
      .get()
      .then((doc) => {
        let data = [];
        doc.docs?.map((item) => {
          if (item?.data().moderation == true) {
            data.push(item.data());
          }
        });
        data = data.sort((a, b) => b.created - a.created);
        setMediaData(data);
        setLoading(true);
      })
      .catch((err) => {
        console.log("err in getting media: ", err);
      });
  };

  const getStateName = (code) => {
    switch (code) {
      case "AN":
        return "Andaman and Nicobar Islands";
      case "AP":
        return "Andhra Pradesh";
      case "AR":
        return "Arunachal Pradesh";
      case "AS":
        return "Assam";
      case "BR":
        return "Bihar";
      case "CH":
        return "Chandigarh";
      case "CT":
        return "Chhattisgarh";
      case "DN":
        return "Dadra and Nagar Haveli";
      case "DD":
        return "Daman and Diu";
      case "DL":
        return "Delhi";
      case "GA":
        return "Goa";
      case "GJ":
        return "Gujarat";
      case "HR":
        return "Haryana";
      case "HP":
        return "Himachal Pradesh";
      case "JK":
        return "Jammu and Kashmir";
      case "JH":
        return "Jharkhand";
      case "KA":
        return "Karnataka";
      case "KL":
        return "Kerala";
      case "LA":
        return "Ladakh";
      case "LD":
        return "Lakshadweep";
      case "MP":
        return "Madhya Pradesh";
      case "MH":
        return "Maharashtra";
      case "MN":
        return "Manipur";
      case "ML":
        return "Meghalaya";
      case "MZ":
        return "Mizoram";
      case "NL":
        return "Nagaland";
      case "OR":
        return "Odisha";
      case "PY":
        return "Puducherry";
      case "PB":
        return "Punjab";
      case "RJ":
        return "Rajasthan";
      case "SK":
        return "Sikkim";
      case "TN":
        return "Tamil Nadu";
      case "TG":
        return "Telangana";
      case "TR":
        return "Tripura";
      case "UP":
        return "Uttar Pradesh";
      case "UT":
        return "Uttarakhand";
      case "WB":
        return "West Bengal";
      default:
        return code;
    }
  };
  const ViewCard = ({ image, description, name, type, item }) => {
    const [imageData, setImageData] = useState("");
    useEffect(() => {
      if (image[0]) {
        const fetchData = async () => {
          try {
            const url = `https://asia-south1-mgmd-356213.cloudfunctions.net/getSignedURLFromCF?url=https://d296j4bbogbdom.cloudfront.net/images/${image[0]}`;
            const response = await axios.get(url);
            const data = response?.data?.data;

            setImageData(data);
          } catch (error) {
            console.error("Error fetching image data:", error);
          }
        };

        fetchData();
      }
    }, [image[0]]);
    return (
      <MediaCardDiv
        role="button"
        className="gold th-br-12 overflow-hidden position-relative media-card-content"
        onClick={() => {
          setPopupMedia(item);
          setModalShow(true);
        }}
      >
        <div
          className="media-main-image"
          style={{
            backgroundSize: "cover",
            backgroundPosition: "top",
            height: "250px",
            width: "100%",
            textAlign: "center",
            backgroundColor: "#fff",
          }}
        >
          <img
            src={imageData ? imageData : AvatarDefault}
            style={{
              width: "fit-content",
              height: "100%",
              // borderRadius: "50%",
            }}
            onError={(e) => (e.target.src = AvatarDefault)}
          />
        </div>
        <div className="th-bg-white  py-2 px-3">
          <div className="row align-items-center">
            <div className="col-12">
              <div className="name-text th-fw-600 text-truncate text-capitalize text-lowercase">
                {name ? name : ""}
              </div>
              <span
                data-toggle="tooltip"
                data-placement="top"
                title={description}
                className="Tool-tip"
              >
                <div className="th-grey-2 description-text ">
                  {description ? description : ""}
                </div>
              </span>
              <div className="name-text th-fw-600 text-truncate text-capitalize text-lowercase ">
                {type ? type : ""}
              </div>
            </div>
          </div>
        </div>
      </MediaCardDiv>
    );
  };

  return (
    <>
      <MyLayout>
        <MyDesktop>
          <div className="view-artist-card">
            {!loading ? (
              <div className="col-md-12">
                <div className="recentLoading media">Loading....</div>
              </div>
            ) : (
              <>
                <div className="main_con bg-white th-br-16">
                  <div className="row m-3  px-3 py-5 col-12 col-md-12">
                    <div className="col-lg-3 col-md-6 image-section">
                      <div
                        className="artist-circle text-center mx-auto rounded-circle mt-4"
                        style={{
                          height: "250px",
                          width: "250px",
                          backgroundSize: "cover",
                          backgroundColor: "#f5f5f5",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          border: "1px solid white",
                          textAlign: "center",
                          backgroundImage: `url(${
                            !imageData && AvatarDefault
                          })`,
                        }}
                      >
                        <img
                          src={imageData ? imageData : AvatarDefault}
                          style={{
                            width: "-webkit-fill-available",
                            height: "100%",
                            borderRadius: "50%",
                          }}
                          onError={(e) => (e.target.src = AvatarDefault)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-8 col-md-6 ">
                      <div className="details">
                        {userInfo?.values?.name && (
                          <div className="details d-flex">
                            <div className="heading">Name : </div>
                            <div className="" style={{ padding: "0px 10px" }}>
                              {userInfo?.values?.name}
                            </div>
                          </div>
                        )}
                        {userInfo?.values?.email && (
                          <div className="details d-flex">
                            <div className="heading">Email : </div>
                            <div className="" style={{ padding: "0px 10px" }}>
                              {userInfo?.values?.email}
                            </div>
                          </div>
                        )}

                        {userInfo?.values?.phonenumber && (
                          <div className="details d-flex">
                            <div className="heading">Phone Number : </div>
                            <div className="" style={{ padding: "0px 10px" }}>
                              {userInfo?.values?.phonenumber}
                            </div>
                          </div>
                        )}
                        {userInfo?.values?.state && (
                          <div className="details d-flex">
                            <div className="heading">State : </div>
                            <div className="" style={{ padding: "0px 10px" }}>
                              {getStateName(userInfo?.values?.state)}
                            </div>
                          </div>
                        )}
                        {userInfo?.values?.city && (
                          <div className="details d-flex">
                            <div className="heading">Village : </div>
                            <div className="" style={{ padding: "0px 10px" }}>
                              {getStateName(userInfo?.values?.city)}
                            </div>
                          </div>
                        )}
                        {userInfo?.values?.artname && (
                          <div className="details d-flex">
                            <div className="heading">Art Style : </div>
                            <div className="" style={{ padding: "0px 10px" }}>
                              {userInfo?.values?.artname}
                            </div>
                          </div>
                        )}
                        {userInfo?.values?.experience && (
                          <div className="details d-flex">
                            <div className="heading">
                              Years Of Experience :{" "}
                            </div>
                            <div className="" style={{ padding: "0px 10px" }}>
                              {userInfo?.values?.experience}
                            </div>
                          </div>
                        )}

                        <div className="details d-flex">
                          <div className="heading"> About Artist : </div>
                          <div
                            className="text-about"
                            style={{ lineHeight: "1.5", padding: "0px 15px" }}
                          >
                            {userInfo?.values?.bio?.length > 590
                              ? readMore
                                ? userInfo?.values?.bio
                                : userInfo?.values?.bio.slice(0, 590) + "..."
                              : userInfo?.values?.bio}
                            {userInfo?.values?.bio?.length > 590 && (
                              <span
                                onClick={() => setReadMore(!readMore)}
                                style={{
                                  fontWeight: "700",
                                  color: "#7d7d7d",
                                  cursor: "pointer",
                                }}
                              >
                                {readMore ? "Read Less" : "Read More..."}
                              </span>
                            )}
                          </div>
                        </div>
                        {/* <span style={{ color: "#75cf8f", fontSize: "12px" }}>
                          Read More
                        </span> */}
                        <div className="details d-flex">
                          <div className="heading"> Unique ID : </div>
                          <div
                            className="text-about p-1"
                            style={{ lineHeight: "1.5", padding: "0px 15px" }}
                          >
                            @{JSON.parse(localStorage.getItem("user"))?.uid}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="new_section m-3">
                  <div className="th-fw-500 th-20 th-grey-4 pt-3">
                    Art Works
                  </div>

                  {!loading ? (
                    <div className="col-md-12">
                      <div className="recentLoading media">Loading....</div>
                    </div>
                  ) : (
                    <div className="row py-3 d-flex">
                      {mediaData && mediaData?.length > 0 ? (
                        mediaData.map((hit, index) => (
                          <div
                            className="col-lg-3 col-md-12 pb-3 px-2 custom artistcard"
                            key={index}
                          >
                            <ViewCard
                              image={hit?.avatar}
                              description={hit?.values?.description}
                              name={hit?.values?.title}
                              type={hit?.values?.type}
                              item={hit}
                            />
                          </div>
                        ))
                      ) : (
                        <div className="row g-0 d-flex bg-white th-br-16 justify-content-center">
                          <div className="bg-white th-br-16 p-3">
                            <div className="col-md-12 col-lg-12 p-md-5 p-3 align-self-center">
                              <div className="text-center">
                                <div className="w-50 mx-auto">
                                  <img
                                    src={IndibadgesImg}
                                    className="edit-media-image"
                                    alt="media-image"
                                  />
                                </div>
                                <h4 className="pt-3 th-14">No Media found.</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </MyDesktop>
        <MyMobile>
          <div className="view-artist-card mobile-theme">
            {!loading ? (
              <div className="col-md-12">
                <div className="recentLoading media">Loading....</div>
              </div>
            ) : (
              <>
                <div className="main_con bg-white th-br-16 mt-3">
                  <div className="row m-3  px-3 py-5 col-12 col-md-12">
                    <div className="col-lg-3 col-md-3 mobile-image-section">
                      <img
                        src={imageData ? imageData : AvatarDefault}
                        onError={(e) => {
                          e.target.src = AvatarDefault;
                        }}
                        alt="user image"
                      ></img>
                      <div className="pt-1">
                        <b>
                          {userInfo?.values?.name
                            ? userInfo?.values?.name
                            : "-"}
                        </b>
                      </div>
                      <span className="pt-1 uuid_moblie"></span>
                    </div>
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-8 col-md-9 ">
                          <div className="details">
                            {userInfo?.values?.email && (
                              <div className="details d-flex">
                                <div className="heading">Email : </div>
                                <div className="text-about p-1">
                                  {userInfo?.values?.email
                                    ? userInfo?.values?.email
                                    : "-"}
                                </div>
                              </div>
                            )}
                            {userInfo?.values?.phonenumber && (
                              <div className="details d-flex">
                                <div className="heading">Phone : </div>
                                <div className="text-about p-1">
                                  {userInfo?.values?.phonenumber
                                    ? userInfo?.values?.phonenumber
                                    : "-"}
                                </div>
                              </div>
                            )}
                            {userInfo?.values?.state && (
                              <div className="details d-flex">
                                <div className="heading">State : </div>
                                <div className="text-about p-1">
                                  {userInfo?.values?.state
                                    ? getStateName(userInfo?.values?.state)
                                    : "-"}
                                </div>
                              </div>
                            )}
                            {userInfo?.values?.city && (
                              <div className="details d-flex">
                                <div className="heading">Village : </div>
                                <div className="text-about p-1">
                                  {userInfo?.values?.state
                                    ? getStateName(userInfo?.values?.city)
                                    : "-"}
                                </div>
                              </div>
                            )}
                            {userInfo?.values?.artname && (
                              <div className="details d-flex">
                                <div className="heading">Art Style : </div>
                                <div className="text-about p-1">
                                  {userInfo?.values?.artname
                                    ? userInfo?.values?.artname
                                    : "-"}
                                </div>
                              </div>
                            )}
                            {userInfo?.values?.experience && (
                              <div className="details d-flex">
                                <div className="heading">Experience : </div>
                                <div className="text-about p-1">
                                  {userInfo?.values?.experience
                                    ? userInfo?.values?.experience
                                    : "-"}
                                </div>
                              </div>
                            )}
                            {userInfo?.values?.bio && (
                              <div className="details d-flex">
                                <div className="heading"> About Artist : </div>
                                <div className="text-about p-1">
                                  {userInfo?.values?.bio?.length > 300
                                    ? readMore
                                      ? userInfo?.values?.bio
                                      : userInfo?.values?.bio.slice(0, 300) +
                                        "..."
                                    : userInfo?.values?.bio}
                                  {userInfo?.values?.bio?.length > 300 && (
                                    <span
                                      onClick={() => setReadMore(!readMore)}
                                      style={{
                                        fontWeight: "700",
                                        color: "#7d7d7d",
                                      }}
                                    >
                                      {readMore ? "Read Less" : "Read More..."}
                                    </span>
                                  )}
                                </div>
                              </div>
                            )}
                            <div className="details d-flex">
                              <div className="heading">Unique ID : </div>
                              <div className="text-about p-1">
                                @{JSON.parse(localStorage.getItem("user"))?.uid}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="new_section m-3">
                  <div className="th-fw-500 th-20 th-grey-4 pt-3 text-center">
                    Art Works
                  </div>

                  {!loading ? (
                    <div className="col-md-12">
                      <div className="recentLoading media">Loading....</div>
                    </div>
                  ) : (
                    <div className="row py-3 justify-content-center align-items-center d-flex mb-5">
                      {mediaData && mediaData?.length > 0 ? (
                        mediaData.map((hit, index) => (
                          <div
                            className="col-lg-3 col-md-12 pb-3 px-2 custom artistcard"
                            key={index}
                          >
                            <ViewCard
                              image={hit?.avatar}
                              description={hit?.values?.description}
                              name={hit?.values?.title}
                              type={hit?.values?.type}
                              item={hit}
                            />
                          </div>
                        ))
                      ) : (
                        <div className="row d-flex g-0 justify-content-center">
                          <div className="bg-white th-br-16 p-3">
                            <div className="col-md-12 col-lg-12 p-md-5 p-3 align-self-center">
                              <div className="text-center">
                                <div className=" mx-auto">
                                  <img
                                    src={IndibadgesImg}
                                    className="edit-media-image"
                                    alt="media-image"
                                  />
                                </div>
                                <h4 className="pt-3 th-14">No Media found.</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </MyMobile>
        {modalShow ? (
          <ArtPopup
            show={modalShow}
            onHide={() => setModalShow(false)}
            item={popupMedia}
            indexForImage={0}
          />
        ) : (
          false
        )}
      </MyLayout>
    </>
  );
};

const MediaCardDiv = styled.div`
  transition: all 0.15s ease;
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  }
`;
export default ViewProfile;
