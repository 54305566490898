import React, { useCallback, useEffect, useRef, useState } from "react";
import firebase from "../../../firebase";
import ReactPlaceholder from "react-placeholder";
import { RectShape } from "react-placeholder/lib/placeholders";
import { Link } from "react-router-dom";
import MyLayout from "../../Components/MyLayout/index";
import MyDesktop from "../../Components/MyComponents/MyDesktop";
import MyMobile from "../../Components/MyComponents/MyMobile";
import VillageCard from "./VillageCard";
import MenuSelect from "./MenuSelect";
import "react-placeholder/lib/reactPlaceholder.css";
import "./index.css";
import axios from "axios";
import SearchBarWithDropdown from "../../Components/AdvanceSearch";
import Typography from "@mui/material/Typography";
import ExploreWebBanner from "../../Assets/img/background/explore_web_banner.png";
import ExploreMobBanner from "../../Assets/img/background/explore_mob_banner.png";
import { Box } from "@mui/material";
import { Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import PageContainer from "../../Components/Container";
import { useInView } from "react-intersection-observer";

const Village = (callback, deps) => {
  const [searchWord, setSearchWord] = useState("");
  const [villageList, setVillageList] = useState([]);
  const [searchClicked, setSearchClicked] = useState(false);
  const [allDistricts, setAllDistricts] = useState([]);
  const [allStates, setAllStates] = useState([]);
  const [allBlock, setAllBlock] = useState([]);
  const [allVillages, setVillages] = useState([]);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [selectedValueForVillageFilter, setSelectedValueForVillageFilter] =
    useState("");
  const [selectedValueForDistrictFilter, setSelectedValueForDistrictFilter] =
    useState("");
  const [selectedValueForStateFilter, setSelectedValueForStateFilter] =
    useState("");
  const [selectedValueForBlockFilter, setSelectedValueForBlockFilter] =
    useState("");
  const [isEndOfList, setIsEndOfList] = useState(false);
  const [selectedToggle, setSelectedToggle] = useState("live");
  const [isLoading, setIsLoading] = useState(false);
  const [lastDocumentFetched, setLastDocumentFetched] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [villageName, setVillageName] = useState(false);
  const [food, setFood] = useState(false);
  const [attire, setAttire] = useState(false);
  const [ornaments, setOrnaments] = useState(false);
  const [festivals, setFestivals] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const db = firebase.firestore().collection("state_district_detail");
  const blockDb = firebase.firestore().collection("district_block_detail");

  const lastVillageElementRef = useRef();

  // Normal function instead of useCallback
  async function fetchVillage() {
    if (isLoading) return; // Avoid multiple simultaneous requests
    setIsLoading(true);

    let db = firebase.firestore().collection("large_village_list");

    if (selectedToggle) {
      db = db.where("live", "==", true).where("video", "!=", "");
    } else {
      db = db.where("video", "!=", "");
    }

    // Start query with the 'video' field if it's used in an inequality filter
    let query = db.orderBy("video").orderBy("village_name").limit(48);

    if (lastDocumentFetched) {
      query = query.startAfter(lastDocumentFetched);
    }

    try {
      const snapshot = await query.get();
      if (snapshot.empty) {
        setIsEndOfList(true);
        setIsLoading(false);
        return;
      }

      const documents = snapshot.docs;
      const list = documents.map((doc) => ({
        key: doc.id,
        village_key: doc.data()["village_document_id"],
        state_name: doc.data()["state_name"],
        village_name: doc.data()["village_name"],
        thumbnail: doc.data()["video_thumbnail"],
        thumbnaiId: doc.data()["thumbnail_name"],
        live: doc.data()["live"] || false,
        isGoodStories: doc.data()["isGoodStories"] || false,
      }));

      setVillageList((prev) => [...prev, ...list]);
      setLastDocumentFetched(documents[documents.length - 1]); // Update last document to paginate
      setIsEndOfList(list.length < 48); // If fewer than 48 results, set end of list flag
    } catch (error) {
      console.error("Error fetching villages: ", error);
    } finally {
      setIsLoading(false);
    }
  }

  const handleSearch = async (page = 0) => {
    setLastDocumentFetched(null)
    if (page <= totalPages) {
      setCurrentPage(page);
      setIsLoading(true); // Show loading spinner

      // Base URL
      let query = `https://asia-south1-mgmd-356213.cloudfunctions.net/Algoliasearch?page=${page}&restrict=custom`;

      // Add search query if present
      if (searchValue) {
        query += `&query=${encodeURIComponent(searchValue)}`;
      }

      // Start customFilter string
      let customFilter = "";

      if (selectedToggle === "live") {
        customFilter += "live:true";
      }

      if (selectedValueForStateFilter) {
        customFilter +=
          (customFilter ? " AND " : "") +
          `state_name:${encodeURIComponent(selectedValueForStateFilter)}`;
      }

      if (selectedValueForDistrictFilter) {
        customFilter +=
          (customFilter ? " AND " : "") +
          `district_name:${encodeURIComponent(selectedValueForDistrictFilter)}`;
      }

      if (selectedValueForBlockFilter) {
        customFilter +=
          (customFilter ? " AND " : "") +
          `block_name:${encodeURIComponent(selectedValueForBlockFilter)}`;
      }

      if (selectedValueForVillageFilter) {
        customFilter +=
          (customFilter ? " AND " : "") +
          `village_name:${encodeURIComponent(selectedValueForVillageFilter)}`;
      }

      // Append customFilter if it has any content
      if (customFilter) {
        query += `&customFilter=${customFilter}`;
      }

      // Restrict searchable attributes based on villageName and food
      const restrictSearchableAttributes = [];

      if (villageName) {
        restrictSearchableAttributes.push("village_name");
      }

      // Check if food should be included
      if (food) {
        restrictSearchableAttributes.push("data.traditional_food.description");
        restrictSearchableAttributes.push("data.traditional_food.title");
      }

      if (attire) {
        restrictSearchableAttributes.push("data.traditional_dress.description");
        restrictSearchableAttributes.push("data.traditional_dress.title");
      }

      if (ornaments) {
        restrictSearchableAttributes.push("data.ornaments.description");
        restrictSearchableAttributes.push("data.ornaments.title");
      }

      if (festivals) {
        restrictSearchableAttributes.push("data.festivals.description");
        restrictSearchableAttributes.push("data.festivals.title");
      }

      if (restrictSearchableAttributes.length > 0) {
        query += `&restrictSearchableAttributes=${restrictSearchableAttributes.join(
          ","
        )}`;
      }

      try {
        // Fetch search results with dynamic query
        const response = await axios.get(query);
        const villageData = response.data.hits;
        const newTotalPages = response.data.nbPages;

        // Update state with new data
        setTotalPages(newTotalPages);
        setIsEndOfList(page >= newTotalPages);

        // Map fetched data to the desired format
        const list = villageData.map((obj) => ({
          key: obj?.objectID,
          village_key: obj?.objectID,
          state_name: obj?.state_name,
          village_name: obj?.village_name,
          thumbnaiId: obj?.thumbnail_name,
          live: obj?.live || false,
          isGoodStories: obj?.isGoodStories || false,
        }));

        setVillageList((prevList) => [...prevList, ...list]);
      } catch (e) {
        setVillageList([]);
        console.error("Error fetching search results:", e);
      } finally {
        setIsLoading(false); // Hide loading spinner
      }
    }
  };

  useEffect(() => {
    const fetchStatesAndDistricts = async () => {
      try {
        // Reset state values
        setSelectedValueForDistrictFilter("");
        setSelectedValueForStateFilter("");
        setSelectedValueForBlockFilter("");
        setSelectedValueForVillageFilter("");

        // Get states
        const statesSnapshot = await firebase
          .firestore()
          .collection("state_detail")
          .limit(100)
          .get();
        const tempStates = statesSnapshot.docs.map((state) => ({
          value: state.data().state_name,
        }));
        setAllStates(tempStates);
      } catch (err) {
        console.error("Error fetching states, districts, and blocks:", err);
      }
    };

    fetchStatesAndDistricts().then();
  }, []);

  useEffect(() => {
    let filters = JSON.parse(localStorage.getItem("filters"));
    if (filters?.stateName) {
      handleStateChange(filters.stateName);
    }
    if (filters?.districtName) {
      handleDistrictChange(filters.districtName);
    }
    if (filters?.blockName) {
      handleBlockChange(filters.blockName);
    }
    if (filters?.villageName) {
      setSelectedValueForVillageFilter(filters.villageName);
    }
    if (filters?.searchValue) {
      setSearchValue(filters?.searchValue);
    }
  }, []);

  useEffect(() => {
    const filters = JSON.parse(localStorage.getItem("filters") || "{}");
    if (
      !filters?.stateName &&
      !filters?.districtName &&
      !filters?.blockName &&
      !filters?.villageName &&
      !searchWord
    ) {
      if (selectedToggle) {
        fetchVillage().then();
      }
    }
  }, [selectedToggle]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !isLoading && !isEndOfList) {
          if (
            !searchValue &&
            !selectedValueForStateFilter &&
            !selectedValueForDistrictFilter &&
            !selectedValueForBlockFilter &&
            !villageName &&
            !food &&
            !attire &&
            !ornaments &&
            !festivals
          ) {
            fetchVillage().then();
          } else {
            handleSearch(currentPage + 1).then();
          }
        }
      },
      { threshold: 0.4 }
    );

    if (lastVillageElementRef.current) {
      observer.observe(lastVillageElementRef.current);
    }

    return () => {
      if (lastVillageElementRef.current) {
        observer.unobserve(lastVillageElementRef.current);
      }
    };
  }, [isLoading, isEndOfList, fetchVillage]);

  useEffect(() => {
    if (
      searchValue.length > 2 ||
      selectedValueForStateFilter ||
      selectedValueForDistrictFilter ||
      selectedValueForBlockFilter ||
      selectedValueForVillageFilter
    ) {
      setVillageList([]);
      handleSearch().then();
    }
  }, [
    searchValue,
    selectedValueForStateFilter,
    selectedValueForDistrictFilter,
    selectedValueForBlockFilter,
    selectedValueForVillageFilter,
  ]);

  useEffect(() => {
    if (searchValue.length > 2) {
      setVillageList([]);
      handleSearch().then();
    }
  }, [villageName, food, ornaments, attire, festivals]);

  const clearState = () => {
    localStorage.removeItem("filters");
    setLastDocumentFetched(null);
    setSearchValue("");
    setVillageName(false);
    setFood(false);
    setAttire(false);
    setOrnaments(false);
    setFestivals(false);
    setTotalPages(0);
    setCurrentPage(0);
    setSelectedValueForStateFilter("");
    setSelectedValueForDistrictFilter("");
    setSelectedValueForBlockFilter("");
    setSelectedValueForVillageFilter("");
  };

  const handleToggleChange = (newToggle) => {
    if (newToggle !== null) {
      clearState();
      setVillageList([]); // Clear the village list
      setSelectedToggle(newToggle); // Update the selected toggle
    }
  };

  const onReset = () => {
    fetchVillage().then();
    setSearchWord("");
    setSearchClicked(false);
  };

  const handleStateChange = async (value) => {
    setSelectedValueForStateFilter(value);
    setSelectedValueForDistrictFilter("");
    setSelectedValueForBlockFilter("");
    setSelectedValueForVillageFilter("");
    setAllDistricts([]);
    setAllBlock([]);

    let getFilters = JSON.parse(localStorage.getItem("filters"));
    getFilters = {
      ...getFilters,
      stateName: value,
    };
    localStorage.setItem("filters", JSON.stringify(getFilters));
    // Update districts based on state
    try {
      if (value) {
        const stateQuerySnapshot = await db
          .where("state_name", "==", value)
          .get();
        const newDistricts = stateQuerySnapshot.docs.map((doc) => ({
          value: doc.data().district_name,
        }));
        setAllDistricts(newDistricts);

        // Fetch blocks for all districts of the selected state
        const districtNames = newDistricts.map((d) => d.value);
        // const blocks = await fetchBlocksByDistricts(districtNames);
        // setAllBlock(blocks);
      }
    } catch (error) {
      console.error("Error fetching districts and blocks:", error);
    } finally {
    }
  };

  const handleDistrictChange = async (value) => {
    setSelectedValueForDistrictFilter(value);
    setSelectedValueForBlockFilter("");
    setSelectedValueForVillageFilter("");
    setAllBlock([]);

    let getFilters = JSON.parse(localStorage.getItem("filters"));
    getFilters = {
      ...getFilters,
      districtName: value,
    };
    localStorage.setItem("filters", JSON.stringify(getFilters));

    // Update blocks based on district
    try {
      if (value) {
        const blockQuerySnapshot = await blockDb
          .where("district_name", "==", value)
          .get();
        const newBlocks = blockQuerySnapshot.docs.map((doc) => ({
          value: doc.data().block_name,
        }));
        setAllBlock(newBlocks);
      }
    } catch (error) {
      console.error("Error fetching blocks:", error);
    } finally {
    }
  };

  const handleBlockChange = async (e) => {
    let arr = [];
    setSelectedValueForVillageFilter("");
    setSelectedValueForBlockFilter(e);
    let getFilters = JSON.parse(localStorage.getItem("filters"));
    getFilters = {
      ...getFilters,
      blockName: e,
    };
    localStorage.setItem("filters", JSON.stringify(getFilters));
    const db = firebase.firestore();
    const collectionName = db.collection("large_village_list");
    const querySnapshot = await collectionName
      .where("block_name", "==", e)
      .get();
    if (!querySnapshot.empty) {
      querySnapshot.forEach((doc) =>
        arr.push({ value: doc.data().village_name })
      );
    }
    setVillages(arr);
  };

  return (
    <PageContainer
      keywords="Explore Villages, Discover Cultural Heritage"
      title="Explore Villages with MGMD | Discover India's Rural Legacy"
      description="Dive deep into India’s rural heritage. Explore various villages, their traditions, history, and cultural significance with MGMD’s comprehensive database and mapping tools."
      canonical="https://mgmd.gov.in/explore"
    >
      <MyLayout>
        <div className="col-md-12 custom-list">
          <div className=" pb-3 search-content justify-content-between">
            <MyDesktop>
              <div style={divStyle}>
                <img
                  src={ExploreWebBanner}
                  style={{ width: "62%", position: "absolute", zIndex: "1" }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    zIndex: "2",
                    width: "35%",
                    top: "12%",
                    left: "10%",
                    marginBottom: "10%",
                  }}
                >
                  <Typography
                    variant="h4"
                    color="white"
                    sx={{
                      fontFamily: "Playfair Display, serif",
                      fontSize: "2rem",
                    }}
                  >
                    Welcome to Mera Gaon Meri Dharohar: The largest cultural
                    repository of the villages of India.
                  </Typography>

                  <Typography
                    variant="subtitle1"
                    mt={2}
                    color="white"
                    sx={{ fontFamily: "Inter, sans-serif", fontSize: "1rem" }}
                  >
                    Discover the nation's diversity through its vibrant
                    villages, each showcased with its unique cultural profile.
                    Explore local tales, ancient traditions, rich heritage and
                    more. <br />
                    <br />
                    Presenting 6.5 Lakh+ Villages | 4.4 Lakh Surveyed so far
                  </Typography>

                  <br />

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <button
                      className="btn"
                      style={{
                        borderColor: "white",
                        color: "white",
                        padding: "5px 30px 5px 30px",
                      }}
                      onClick={handleShow}
                    >
                      Know More
                    </button>

                    <Modal show={show} onHide={handleClose} size="lg" centered>
                      <Modal.Header closeButton></Modal.Header>
                      <Modal.Body>
                        <div className="embed-responsive embed-responsive-16by9">
                          <video
                            className="embed-responsive-item"
                            autoPlay
                            muted
                            controls
                            width="100%"
                            height="400px"
                          >
                            <source
                              src="https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/videos%2Fknowmore.mp4?alt=media&token=1cc2fc23-c23a-4c9d-af97-636d9428627b"
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </div>
                </Box>

                <Box
                  width="60%"
                  sx={{ position: "absolute", left: "47%", overflow: "hidden" }}
                >
                  <video
                    height="100%"
                    width="100%"
                    style={{
                      objectFit: "contain",
                    }}
                    controls={false}
                    loop
                    autoPlay
                    muted
                    // Set the source of the video
                  >
                    <source
                      src="https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/videos%2Fexplorebanner.mp4?alt=media&token=5fcdfa2d-1b21-46b0-a923-84c92e68c5a1"
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </Box>
              </div>
              <div className="row col-12 g-0 mt-3">
                <SearchBarWithDropdown
                  setSearchValue={setSearchValue}
                  searchValue={searchValue}
                  villageName={villageName}
                  food={food}
                  attire={attire}
                  ornaments={ornaments}
                  festivals={festivals}
                  setVillageName={setVillageName}
                  setFood={setFood}
                  setAttire={setAttire}
                  setOrnaments={setOrnaments}
                  setFestivals={setFestivals}
                />
              </div>
              <div className="text-disclaimer-with-edit py-2">
                Register New Village, click
                <Link
                  className="text-primary px-1"
                  to={localStorage.getItem("user") ? "/add-village" : "/login"}
                >
                  here
                </Link>
              </div>
            </MyDesktop>
            <MyMobile>
              <div style={divMobStyle}>
                <Box
                  width="100%"
                  sx={{
                    position: "absolute",
                    overflow: "hidden",
                    height: "60vh",
                  }}
                >
                  <video
                    height="100%"
                    width="100%"
                    style={{
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                    controls={false}
                    autoPlay
                    muted
                    // Set the source of the video
                  >
                    <source
                      src="https://firebasestorage.googleapis.com/v0/b/mgmd-356213.appspot.com/o/videos%2Fexplorebanner.mp4?alt=media&token=5fcdfa2d-1b21-46b0-a923-84c92e68c5a1"
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </Box>
                <img
                  src={ExploreMobBanner}
                  style={{
                    width: "100%",
                    position: "absolute",
                    zIndex: "1",
                    bottom: "35px",
                  }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    zIndex: "2",
                    width: "90%",
                    top: "65%",
                    left: "5%",
                  }}
                >
                  <Typography variant="subtitle1" color="white">
                    Welcome to Mera Gaon Meri Dharohar: The largest cultural
                    repository of India's villages.
                  </Typography>
                  <Typography variant="subtitle2" mt={5} color="white">
                    Discover the nation's diversity through its vibrant
                    villages, each showcased with its unique cultural profile.
                    Explore local tales, ancient traditions, rich heritage and
                    more. <br />
                    <br />
                    Presenting 6.5 Lakh+ Villages | 4.4 Lakh Surveyed so far
                  </Typography>
                </Box>
              </div>
              <div className="row col-12 g-0 mt-3">
                <SearchBarWithDropdown
                  setSearchValue={setSearchValue}
                  searchValue={searchValue}
                  villageName={villageName}
                  food={food}
                  attire={attire}
                  ornaments={ornaments}
                  festivals={festivals}
                  setVillageName={setVillageName}
                  setFood={setFood}
                  setAttire={setAttire}
                  setOrnaments={setOrnaments}
                  setFestivals={setFestivals}
                />
              </div>
            </MyMobile>
          </div>
          <div className="drop-down-filter d-flex pb-3">
            <div className="filter-part">
              <div className="row">
                <div className="col">
                  <MenuSelect
                    attribute="state_name"
                    f_name="State"
                    limit={100}
                    className="state_filter"
                    passedDownItems={allStates}
                    onChangeFun_={handleStateChange}
                    selectedValue={selectedValueForStateFilter}
                    isDisable={allStates.length < 1}
                  />
                </div>
                <div className="col">
                  <MenuSelect
                    attribute="district_name"
                    f_name="District"
                    limit={1000}
                    className="distric_filter"
                    passedDownItems={allDistricts}
                    onChangeFun_={handleDistrictChange}
                    selectedValue={selectedValueForDistrictFilter}
                    isDisable={!selectedValueForStateFilter}
                  />
                </div>
                <div className="col">
                  <MenuSelect
                    attribute="tehsil"
                    f_name="Block"
                    limit={500}
                    className="block_filter"
                    passedDownItems={allBlock}
                    onChangeFun_={(e) => handleBlockChange(e)}
                    selectedValue={selectedValueForBlockFilter}
                    isDisable={!selectedValueForDistrictFilter}
                  />
                </div>
                {/* { allVillages.length > 0 && ( */}
                <div className="col">
                  <MenuSelect
                    attribute="tehsil"
                    f_name="Village"
                    limit={500}
                    className="block_filter"
                    passedDownItems={allVillages}
                    onChangeFun_={(e) => {
                      let getFilters = JSON.parse(
                        localStorage.getItem("filters")
                      );
                      getFilters = {
                        ...getFilters,
                        villageName: e,
                      };
                      localStorage.setItem(
                        "filters",
                        JSON.stringify(getFilters)
                      );
                      setSelectedValueForVillageFilter(e);
                    }}
                    selectedValue={selectedValueForVillageFilter}
                    isDisable={!selectedValueForBlockFilter}
                  />
                </div>
                {/* ) } */}
                <div className="col-md-3 col-xs-12">
                  <div style={containerStyle}>
                    <div
                      style={{
                        ...buttonStyle,
                        ...(selectedToggle === "all"
                          ? selectedStyle
                          : unselectedStyle),
                      }}
                      onClick={() => handleToggleChange("all")}
                    >
                      All Villages
                    </div>
                    <div
                      style={{
                        ...buttonStyle,
                        ...(selectedToggle === "live"
                          ? selectedStyle
                          : unselectedStyle),
                      }}
                      onClick={() => handleToggleChange("live")}
                    >
                      Live Villages (4,47,383)
                    </div>
                  </div>
                </div>
              </div>
              {(searchValue ||
                selectedValueForStateFilter ||
                selectedValueForDistrictFilter ||
                selectedValueForBlockFilter ||
                selectedValueForVillageFilter ||
                searchValue) && (
                <div className="row">
                  <div className="col-3" style={{ marginTop: "1%" }}>
                    <button
                      className="btn"
                      style={{
                        backgroundColor: "#3CAF5C",
                        color: "#fff",
                      }}
                      onClick={() => {
                        clearState();
                        fetchVillage();
                      }}
                    >
                      Clear
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            className="th-village-height-scroll pr-md-3 pr-0"
            id="anchor"
            style={{ overflowX: "hidden" }}
          >
            {searchClicked && searchWord.length !== 0 ? (
              <div className="pb-3 d-flex justify-content-between">
                <div>
                  Search results for: "<b>{searchWord}</b>"
                </div>
                <div
                  onClick={onReset}
                  className="th-btn-green-outline th-fw-600 th-pointer px-3 ml-4"
                >
                  Reset
                </div>
              </div>
            ) : null}
            {searchClicked && searchWord.length !== 0 ? null : (
              <div className="row"></div>
            )}

            {villageList.length > 0 ? (
              <div className="row px-2 mobile-scroll">
                <ReactPlaceholder
                  ready={villageList.length > 0}
                  customPlaceholder={awesomePlaceholder}
                >
                  {
                    // this.state.villageList.length > 0 &&
                    villageList.map((hit, index) => {
                      return (
                        <div
                          className="col-lg-3 col-md-6 pb-3 px-1"
                          key={index}
                          ref={
                            index === villageList.length - 1
                              ? lastVillageElementRef
                              : null
                          }
                        >
                          {hit?.live ? (
                            <Link
                              to={{
                                pathname: `village-details/${hit.village_key}`,
                                state: {
                                  villageId: hit.village_key,
                                },
                              }}
                            >
                              <VillageCard
                                village={hit}
                                village_name={hit?.village_name}
                                state={hit?.state_name}
                                cover={hit?.thumbnail}
                                thumbnaiId={hit?.thumbnaiId}
                                live={hit?.live}
                                isGoodStories={hit?.isGoodStories}
                              />
                            </Link>
                          ) : (
                            <VillageCard
                              village={hit}
                              village_name={hit?.village_name}
                              state={hit?.state_name}
                              cover={hit?.thumbnail}
                              thumbnaiId={hit?.thumbnaiId}
                              live={hit?.live}
                            />
                          )}
                        </div>
                      );
                      //only showing 16 entries as of now
                    })
                  }
                </ReactPlaceholder>
              </div>
            ) : (
              <Typography variant="h1">No Data Found</Typography>
            )}
            {isLoading && (
              <div className="col-12 text-center py-3">
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </MyLayout>
    </PageContainer>
  );
};

export default Village;

const buttonStyle = {
  borderRadius: "20px",
  textTransform: "uppercase",
  fontWeight: "bold",
  fontSize: "0.75rem",
  padding: "8px 16px",
  margin: "0 2px",
  border: "none",
  outline: "none",
  cursor: "pointer",
  userSelect: "none",
  transition: "background-color 0.3s, color 0.3s",
};

const selectedStyle = {
  background: "linear-gradient(45deg, #4caf50 30%, #81c784 90%)",
  color: "#fff",
};

const unselectedStyle = {
  backgroundColor: "#fff",
  color: "#757575",
};

const containerStyle = {
  display: "flex",
  borderRadius: "20px",
  backgroundColor: "#ffffff",
  width: "300px",
  marginLeft: window.innerWidth > 768 ? "35%" : "6%",
  marginTop: window.innerWidth < 786 ? "10px" : "0",
};

const divStyle = {
  height: "70vh", // Make sure the body takes the full viewport height
  width: "100%",
  position: "relative",
  left: "-5%",
};

const divMobStyle = {
  height: "100vh", // Make sure the body takes the full viewport height
  width: "100%",
  position: "relative",
  top: "-3%",
};

const awesomePlaceholder = (
  <>
    <div className="d-flex col-12 my-awesome-placeholder my-2">
      <div className="col-3 mx-3">
        <RectShape color="#dadada" style={{ height: "60px" }} />
      </div>
      <div className="col-3 mx-3">
        <RectShape color="#dadada" style={{ height: "60px" }} />
      </div>
      <div className="col-3 mx-3">
        <RectShape color="#dadada" style={{ height: "60px" }} />
      </div>
    </div>
    <div className="d-flex col-12 my-awesome-placeholder my-2">
      <div className="col-3 mx-3">
        <RectShape color="#dadada" style={{ height: "60px" }} />
      </div>
      <div className="col-3 mx-3">
        <RectShape color="#dadada" style={{ height: "60px" }} />
      </div>
      <div className="col-3 mx-3">
        <RectShape color="#dadada" style={{ height: "60px" }} />
      </div>
    </div>
    <div className="d-flex col-12 my-awesome-placeholder my-2">
      <div className="col-3 mx-3">
        <RectShape color="#dadada" style={{ height: "60px" }} />
      </div>
      <div className="col-3 mx-3">
        <RectShape color="#dadada" style={{ height: "60px" }} />
      </div>
      <div className="col-3 mx-3">
        <RectShape color="#dadada" style={{ height: "60px" }} />
      </div>
    </div>
    <div className="d-flex col-12 my-awesome-placeholder my-2">
      <div className="col-3 mx-3">
        <RectShape color="#dadada" style={{ height: "60px" }} />
      </div>
      <div className="col-3 mx-3">
        <RectShape color="#dadada" style={{ height: "60px" }} />
      </div>
      <div className="col-3 mx-3">
        <RectShape color="#dadada" style={{ height: "60px" }} />
      </div>
    </div>
  </>
);
