import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, Redirect } from "react-router-dom";
import { Heading2, Heading3, SubText1 } from "./FontStyle";
import Badge from "./Badge";

import EditIcon from "../Assets/img/icons/edit.svg";
import AvatarDefault from "../Assets/img/General/AvatarDefault.jpg";
import IconAchi2 from "../Assets/img/HomeScreen/icon4.svg";
import IconAchi3 from "../Assets/img/HomeScreen/icon5.svg";
import axios from "axios";

const HomeProfile = (props) => {
  const [redirectCheck, setRedirectCheck] = useState(false);
  const handleRedirect = () => {
    setRedirectCheck(true);
  };

  const [imageData, setImageData] = useState("");
  useEffect(() => {
    if (props.ProfileImage) {
      const fetchData = async () => {
        try {
          const url = `https://asia-south1-mgmd-356213.cloudfunctions.net/getSignedURLFromCF?url=https://d296j4bbogbdom.cloudfront.net/images/${props.ProfileImage}`;
          const response = await axios.get(url);
          const data = response?.data?.data;

          setImageData(data);
        } catch (error) {
          console.error("Error fetching image data:", error);
        }
      };

      fetchData();
    }
  }, [props.ProfileImage]);
  const getStateName = (code) => {
    switch (code) {
      case "AN":
        return "Andaman and Nicobar Islands";
      case "AP":
        return "Andhra Pradesh";
      case "AR":
        return "Arunachal Pradesh";
      case "AS":
        return "Assam";
      case "BR":
        return "Bihar";
      case "CH":
        return "Chandigarh";
      case "CT":
        return "Chhattisgarh";
      case "DN":
        return "Dadra and Nagar Haveli";
      case "DD":
        return "Daman and Diu";
      case "DL":
        return "Delhi";
      case "GA":
        return "Goa";
      case "GJ":
        return "Gujarat";
      case "HR":
        return "Haryana";
      case "HP":
        return "Himachal Pradesh";
      case "JK":
        return "Jammu and Kashmir";
      case "JH":
        return "Jharkhand";
      case "KA":
        return "Karnataka";
      case "KL":
        return "Kerala";
      case "LA":
        return "Ladakh";
      case "LD":
        return "Lakshadweep";
      case "MP":
        return "Madhya Pradesh";
      case "MH":
        return "Maharashtra";
      case "MN":
        return "Manipur";
      case "ML":
        return "Meghalaya";
      case "MZ":
        return "Mizoram";
      case "NL":
        return "Nagaland";
      case "OR":
        return "Odisha";
      case "PY":
        return "Puducherry";
      case "PB":
        return "Punjab";
      case "RJ":
        return "Rajasthan";
      case "SK":
        return "Sikkim";
      case "TN":
        return "Tamil Nadu";
      case "TG":
        return "Telangana";
      case "TR":
        return "Tripura";
      case "UP":
        return "Uttar Pradesh";
      case "UT":
        return "Uttarakhand";
      case "WB":
        return "West Bengal";
      default:
        return code;
    }
  };

  // to disable right click context menu on profile click
  const handleContextMenu = (event) => {
    event.preventDefault();
    const img = document.getElementById("my-image");
    // to disable copy form web
    img.addEventListener("mousedown", (event) => {
      if (event.ctrlKey || event.metaKey) {
        event.preventDefault();
        // Optionally, you can display an error message or take other actions to prevent the user from accessing the image.
      }
    });
  };

  return (
    <>
      {!redirectCheck ? (
        <div className={` ${props.className} mainDiv`}>
          <div
            className="UserImageAvatar"
            style={{
              backgroundImage: `url(${imageData ? imageData : AvatarDefault})`,
            }}
            id="my-image"
            onContextMenu={handleContextMenu}
          ></div>
          <div>
            <div className="pt-2">
              <Heading2>
                {props.UserName}
                <div role="button" onClick={() => handleRedirect()}>
                  <img
                    src={EditIcon}
                    height="17px"
                    width="17px"
                    alt="EditIcon"
                    className="mx-1 mb-1"
                  />
                </div>
              </Heading2>

              <Heading3 className="text-center w-100 mb-0 mt-2 mb-1">
                {props.userCity}
                {props.userState && props.userCity && `${","}`}
                {props.userState && `${getStateName(props.userState)}`}
              </Heading3>
            </div>
          </div>
          <div className="w-75 mx-auto">
            {/* <SubText1>
              {props?.Description?.slice(0, 150)}
              <Link
                to={
                  localStorage.getItem("userType") === "simpleUser"
                    ? `profile`
                    : `artist-profile`
                }
              >
                <span className="read-or-hide" style={{ fontWeight: "700" }}>
                  {"...read more"}
                </span>
              </Link>
            </SubText1> */}
          </div>

          <div className="d-flex pb-1">
            <div className="col-6 px-2">
              <Badge
                title={"MGMD Smriti"}
                icon={IconAchi2}
                number={props.indibadges}
              />
            </div>
            <div className=" col-6 px-2">
              <Badge
                title={"Tickets"}
                icon={IconAchi3}
                number={props.passports}
              />
            </div>
          </div>
        </div>
      ) : (
        <Redirect
          to={
            localStorage.getItem("userType") == "artist"
              ? "/artist-profile"
              : "/profile"
          }
        />
      )}
    </>
  );
};

export default HomeProfile;
