import React, { useEffect } from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import { Carousel } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import firebase from "../../../../src/firebase";
import RightArrow from "../../Assets/img/General/arrow-right.svg";
import LeftArrow from "../../Assets/img/General/arrow-left.svg";
import AvatarDefault from "../../Assets/img/General/AvatarDefault.jpg";
import axios from "axios";
const ImageComponent = ({ id }) => {
  const [imageData, setImageData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `https://asia-south1-mgmd-356213.cloudfunctions.net/getSignedURLFromCF?url=https://d296j4bbogbdom.cloudfront.net/fit-in/350x300/filters:quality(90)/images/${id}`;
        const response = await axios.get(url);
        const data = response?.data?.data;
        setImageData(data);
      } catch (error) {
        console.error("Error fetching image data:", error);
      }
    };

    fetchData();
  }, [id]);

  return imageData ? (
    <img
      className="edit-media-image"
      alt="media-image"
      id="current-image-displayed"
      src={imageData ? imageData : AvatarDefault}
      onError={(e) => {
        e.target.src = AvatarDefault;
      }}
    />
  ) : (
    <div>Loading...</div>
  );
};
const ArtPopup = (props) => {
  //media state for all images uploaded
  const [allMedia, setAllMedia] = useState([]);
  const [artistName, setArtistName] = useState();

  //media index for displaying the right image from @allMedia
  const [currentMediaDisplayed, setCurrentMediaDisplayed] = useState(
    props?.indexForImage
  );

  useEffect(async () => {
    await firebase
      .firestore()
      .collection("users")
      .doc(props?.item.userId)
      .get()
      .then((doc) => {
        // setAllMedia(...props?.item, { name: doc.data().values.name });
        setArtistName(doc.data().values.name);
        console.log();
      })
      .catch((err) => {
        console.log("err in getting user: ", err);
      });
    setAllMedia(...allMedia, props?.item?.avatar);
    console.log(allMedia);
    //updating all media state if props.edit is true
    console.log(props);
  }, [props]);

  // to disable right click context menu on profile click
  const handleContextMenu = (event) => {
    event.preventDefault();
    const img = document.getElementById("my-image");
    // to disable copy form web
    img.addEventListener("mousedown", (event) => {
      if (event.ctrlKey || event.metaKey) {
        event.preventDefault();
        // Optionally, you can display an error message or take other actions to prevent the user from accessing the image.
      }
    });
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="add-edit-media media-popup"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row col-md-12 d-flex media-body">
          <div
            className="col-md-6 image-media-part"
            style={{ maxHeight: "277px" }}
          >
            {allMedia.length > 0 ? (
              <div
                className="position-relative"
                id="img-main-container-allEditMedia"
              >
                <Carousel
                  wrap={false}
                  interval={null}
                  activeIndex={currentMediaDisplayed}
                  keyboard={false}
                  prevIcon={
                    // {/* will only show if the index isn't zero(the first value) */}

                    <img
                      id="my-image"
                      className="back-arrow-add-artwork"
                      src={LeftArrow}
                      onClick={() => {
                        //handling setState inside the UI
                        setCurrentMediaDisplayed(currentMediaDisplayed - 1);
                      }}
                      onContextMenu={handleContextMenu}
                    />
                  }
                  nextIcon={
                    // {/* will only show if the next value is present(isn't undefined) */}

                    <img
                      className="front-arrow-add-artwork front-arrow-add-artwork front-arrow-add-artwork-view-profile right"
                      src={RightArrow}
                      onClick={() => {
                        //handling setState inside the UI
                        setCurrentMediaDisplayed(currentMediaDisplayed + 1);
                      }}
                    />
                  }
                >
                  {allMedia?.map((item, index) => {
                    return (
                      <Carousel.Item>
                        <ImageComponent id={item} />
                      </Carousel.Item>
                    );
                  })}
                </Carousel>
              </div>
            ) : null}
          </div>
          <div className="col-md-6 d-flex justify-content-center align-items-center">
            <div className="align-items-center">
              <div className="media-description">
                Artist Name : {artistName ? artistName : ""}
              </div>
              <div className="media-name">
                {props?.item?.values?.title ? props?.item?.values?.title : ""}
              </div>
              <div className="media-type">
                {props?.item?.values?.type ? props?.item?.values?.type : ""}
              </div>
              <div className="media-description">
                {props?.item?.values?.description
                  ? props?.item?.values?.description
                  : ""}
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ArtPopup;
