import React, { useEffect, useState } from "react";
import firebase from "../../../../src/firebase";
import { v4 as uuid } from "uuid";
import { Alert } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Carousel from "react-bootstrap/Carousel";

import MinusIcon from "../../Assets/img/General/MinusIcon.svg";
import RightArrow from "../../Assets/img/General/arrow-right.svg";
import LeftArrow from "../../Assets/img/General/arrow-left.svg";

import AWS from "aws-sdk";
import axios from "axios";

const ImageComponent = ({ id }) => {
  const [imageData, setImageData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `https://asia-south1-mgmd-356213.cloudfunctions.net/getSignedURLFromCF?url=https://d296j4bbogbdom.cloudfront.net/fit-in/350x300/filters:quality(90)/images/${id}`;
        const response = await axios.get(url);
        const data = response?.data?.data;
        setImageData(data);
      } catch (error) {
        console.error("Error fetching image data:", error);
      }
    };

    fetchData();
  }, [id]);

  return imageData ? (
    <img
      className="edit-media-image"
      alt="media-image"
      id="current-image-displayed"
      src={imageData}
    />
  ) : (
    <div>Loading...</div>
  );
};
const AddEditMedia = (props) => {
  const s3 = new AWS.S3();
  AWS.config.update({
    accessKeyId: "AKIA6JXYGZ42T6UQGT4S",
    secretAccessKey: "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",
    region: "ap-south-1",
  });
  const [mediaData, setMediaData] = useState({
    title: "",
    description: "",
    price: "",
    arttypeAny: "",
    type: "",
  });
  const [loader, setLoader] = useState(false);
  const [isimageValid, setIsimageValid] = useState("");
  const [sucessUpdated, setSucessUpdated] = useState("");
  const [anyOther, setAnyOther] = useState("");

  //media state for all images uploaded
  const [allMedia, setAllMedia] = useState([]);
  const [mediaName, setMediaName] = useState([]);
  //media index for displaying the right image from @allMedia
  const [currentMediaDisplayed, setCurrentMediaDisplayed] = useState(0);

  //loading state
  const [uploadingMedia, setUploadingMedia] = useState(false);
  const [doneUploadingMedia, setDoneUploadingMedia] = useState(false);

  const handleInputChange = (e, val) => {
    if (val == "artType") {
      if (e.target.value == "anyother") {
        setAnyOther("anyother");
      } else {
        setAnyOther("");
        setMediaData({ ...mediaData, arttypeAny: "" });
      }
    }
    setMediaData({ ...mediaData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    // resetting the media index
    setCurrentMediaDisplayed(0);
    if (!props?.edit) {
      setMediaData({
        title: "",
        description: "",
        price: "",
        arttypeAny: "",
        type: "",
      });
    }
    if (props?.item && props?.edit) {
      //updating all media state if props.edit is true
      setMediaName(props.item.avatar);
      setAllMedia(props.item.avatar);

      //extra - prev dev work
      let data = {
        title: props?.item?.values?.title,
        type: props?.item?.values?.type,
        description: props?.item?.values?.description,
      };
      if (anyOther == "anyother") {
        data = {
          title: props?.item?.values?.title,
          type: props?.item?.values?.type,
          arttypeAny: props?.item?.values?.arttypeAny
            ? props?.item?.values?.arttypeAny
            : "",
          description: props?.item?.values?.description,
        };
      } else {
        data = {
          title: props?.item?.values?.title,
          type: props?.item?.values?.type,
          arttypeAny: "",
          description: props?.item?.values?.description,
        };
      }

      if (
        props?.item?.values?.type &&
        props?.item?.values?.type == "anyother"
      ) {
        setAnyOther("anyother");
      } else {
        setAnyOther("");
      }
      setMediaData(data);
    }
  }, [props]);

  const getAllMediaData = async () => {
    props?.getMediaData();
  };

  const mediaMessage = (msj) => {
    setIsimageValid(msj);

    setTimeout(() => {
      setIsimageValid("");
    }, 1000);
  };

  const handleFileUploadMedia = async (e) => {
    //size check
    let sizeGreaterThanOneMB = false;

    //whitelist for extensions
    let extensionChecker = true;

    //converting ibjects to array
    const objectsToArray = Object.entries(e.target.files);

    //looping over each image
    for (let i = 0; i < objectsToArray.length; i++) {
      let size = Math.round(objectsToArray[i][1]?.size / 1024);
      if (size > 1024) {
        sizeGreaterThanOneMB = true;
        break;
      }

      extensionChecker = new RegExp(
        "(" + [".jpg", ".jpeg", ".png"].join("|").replace(/\./g, "\\.") + ")$"
      ).test(objectsToArray[i][1]?.name);
      if (!extensionChecker) {
        break;
      }
    }

    if (sizeGreaterThanOneMB) {
      mediaMessage("Please select image(s) size less than 1 MB");
    } else if (!extensionChecker) {
      mediaMessage("only PNG/JPG/JPEG allowed");
    } else {
      //setting the loader as true
      setUploadingMedia(true);

      //uplaod to firestore
      saveToFirebase(e.target.files);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (allMedia.length == 0) {
      mediaMessage("Please select media image");
    } else if (
      mediaData == "undefined" ||
      mediaData.title == "undefined" ||
      mediaData.title == ""
    ) {
      mediaMessage("Please Enter Media Title");
      return false;
    } else {
      let dataM = {
        title: mediaData?.title ? mediaData?.title : "",
        type: mediaData?.type ? mediaData?.type : "",
        arttypeAny: mediaData?.arttypeAny ? mediaData?.arttypeAny : "",
        description: mediaData?.description ? mediaData?.description : "",
      };
      if (
        dataM.title == "undefined" ||
        dataM.title == "" ||
        dataM.title == " "
      ) {
        mediaMessage("Please Enter Media Title");
        return false;
      } else {
        setLoader(true);
        if (props?.edit && allMedia.length != 0) {
          firebase
            .firestore()
            .collection("artist_media")
            .doc(props?.item?.mediaId)
            .update({
              avatar: mediaName,
              moderation: false,
              values: dataM,
            })
            .then((response) => {
              getAllMediaData();
              setSucessUpdated("Media Updated successfully");
              setTimeout(() => {
                setSucessUpdated("");
                props.onHide();
                setLoader(false);
              }, 800);
            });
        } else {
          if (allMedia.length != 0) {
            let mediaId = uuid();

            //updating the already available data
            if (props?.edit) {
              firebase
                .firestore()
                .collection("artist_media")
                .doc(props?.item?.mediaId)
                .update({
                  avatar: mediaName,
                  moderation: false,
                  values: mediaData,
                })
                .then((response) => {
                  getAllMediaData();
                  setSucessUpdated("Media Updated successfully");
                  setTimeout(() => {
                    setSucessUpdated("");
                    props.onHide();
                    setLoader(false);
                  }, 800);
                });
            } else {
              //adding new data from artists profile
              firebase
                .firestore()
                .collection("artist_media")
                .doc(mediaId)
                .set({
                  avatar: mediaName,
                  mediaId: mediaId,
                  moderation: false,
                  userId: JSON.parse(localStorage.getItem("user"))?.uid,
                  values: mediaData,
                  created: Date.now(),
                })
                .then((response) => {
                  getAllMediaData();
                  setSucessUpdated("Media Added successfully");
                  setTimeout(() => {
                    setSucessUpdated("");
                    props.onHide();
                    setLoader(false);
                  }, 800);
                });
            }
          }
        }
      }
    }
  };

  //upload file to firebase

  const saveToFirebase = (files) => {
    console.log("lll");
    const fileName = `${Date.now()}.${files[0].name.split(".").pop()}`;

    const params = {
      Bucket: "static.mgmd.gov.in",
      Key: `images/${fileName}`,
      Body: files[0],
      ContentType: "image/jpg",
    };

    s3.upload(params, (err, data) => {
      if (err) {
        console.error(err);
        // reject(err);
      } else {
        console.log("Image uploaded successfully:");
        const reader = new FileReader();

        reader.onload = () => {
          setMediaName([...mediaName, fileName]);
          setAllMedia([...allMedia, reader.result]);
        };

        if (files[0]) {
          reader.readAsDataURL(files[0]);
        }
        //updating loaders
        setUploadingMedia(false);
        setDoneUploadingMedia(true);

        setTimeout(() => {
          //making the sign disappear after 2s
          setDoneUploadingMedia(false);
        }, 2000);
      }
    });
  };

  //handle remove any specific media
  const handleRemoveMediaFile = (_index) => {
    const temp = [...allMedia];
    const temp2 = [...mediaName];
    temp.splice(_index, 1);
    temp2.splice(_index, 1);
    //media index has to be set accordingly
    //the last image will be shown on deletion
    if (_index != 0) {
      setCurrentMediaDisplayed(currentMediaDisplayed - 1);
    } //else do nothing

    setAllMedia(temp);
    setMediaName(temp2);
  };

  console.log(allMedia);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="add-edit-media"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props?.edit ? "Edit" : "Add"} Media
        </Modal.Title>
      </Modal.Header>
      {/* {isimageValid && (
        <Alert className="text-center mt-3" key={"danger"} variant={"danger"}>
          {isimageValid}
        </Alert>
      )} */}
      {/* {sucessUpdated && (
        <Alert className="text-center mt-3" key={"success"} variant={"success"} style={{
          position: "absolute",
          top: 0,
          left: "50%",
          transform: "translateX(-50%)",
          maxWidth: "90%",
          zIndex: 1
        }}>
          {sucessUpdated}
        </Alert>
      )} */}

      {/* {!doneUploadingMedia && (
        <Alert className="text-center mt-3" key={"success"} variant={"success"} style={{
          position: "absolute",
          top: 0,
          left: "50%",
          transform: "translateX(-50%)",
          maxWidth: "100%",
          zIndex: 1
        }}>
          Uploaded Successfully
        </Alert>
      )} */}
      <Modal.Body>
        {isimageValid && (
          <Alert
            className="text-center mt-3"
            key={"danger"}
            variant={"danger"}
            style={{
              position: "absolute",
              top: -41,
              zIndex: 1,
              width: "90%",
            }}
          >
            {isimageValid}
          </Alert>
        )}
        {sucessUpdated && (
          <Alert
            className="text-center mt-3"
            key={"success"}
            variant={"success"}
            style={{
              position: "absolute",
              top: -41,
              zIndex: 1,
              width: "90%",
            }}
          >
            {sucessUpdated}
          </Alert>
        )}

        {doneUploadingMedia && (
          <Alert
            className="text-center mt-3"
            key={"success"}
            variant={"success"}
            style={{
              position: "absolute",
              top: -41,
              width: "90%",
              zIndex: 1,
            }}
          >
            Uploaded Successfully
          </Alert>
        )}
        <div className="row col-md-12 pt-4">
          <div className="col-md-6">
            <div>
              <div className="th-12 form-label">
                <b>Title</b>
              </div>
              <div>
                <input
                  value={mediaData?.title}
                  onChange={(e) => handleInputChange(e)}
                  type="text"
                  placeholder=""
                  className="w-70 form-control-plaintext shadow-none th-lt-grey px-1 input-box-EditMedia"
                  name="title"
                  required
                />
                <hr className="th-hr mb-0 input-box-EditMedia"></hr>
              </div>
            </div>
            <div>
              <div className="th-12 form-label">
                <b>Art Form/ Art type</b>
              </div>
              <div>
                <select
                  value={mediaData?.type}
                  name="type"
                  className="w-80 input-box-EditMedia form-control-plaintext shadow-none th-lt-grey px-1"
                  onChange={(e) => handleInputChange(e, "artType")}
                  style={{ height: "35px" }}
                >
                  <option value="">Select</option>
                  <option value="Folk Music">Folk Music</option>
                  <option value="Folk Dance">Folk Dance</option>
                  <option value="Folk Songs">Folk Songs</option>
                  <option value="Folk Theatre">Folk Theatre</option>
                  <option value="Folk Dance Drama">Folk Dance Drama</option>
                  <option value="Folk Tale Narration">
                    Folk Tale Narration
                  </option>
                  <option value="Folk Epic Singing">Folk Epic Singing</option>
                  <option value="Martial Arts ">Martial Arts </option>
                  <option value="Painting/Drawing">Painting/Drawing</option>
                  <option value="Wood Craft">Wood Craft</option>
                  <option value="Metal Craft">Metal Craft</option>
                  <option value="Paper Craft">Paper Craft</option>
                  <option value="Clay and Mud Craft">Clay and Mud Craft</option>
                  <option value="Stone Craft">Stone Craft</option>
                  <option value="Bone Craft">Bone Craft</option>
                  <option value="Leather Craft">Leather Craft</option>
                  <option value="Glass Craft">Glass Craft</option>
                  <option value="Cane Craft">Cane Craft</option>
                  <option value="Grass Craft">Grass Craft</option>
                  <option value="Bamboo and Basketry">
                    Bamboo and Basketry
                  </option>
                  <option value="Weaving">Weaving</option>
                  <option value="Needle and Thread work">
                    Needle and Thread work
                  </option>
                  <option value="Textile and Printing">
                    Textile and Printing
                  </option>
                  <option value="Theatre Craft">Theatre Craft</option>
                  <option value="anyother">Any Other</option>
                </select>
                <hr className="th-hr mb-0 input-box-EditMedia"></hr>
              </div>
            </div>
            {anyOther && (
              <div>
                <div className="th-12 form-label">Any Other Art Type</div>
                <div>
                  <input
                    value={mediaData?.arttypeAny}
                    onChange={(e) => handleInputChange(e)}
                    type="text"
                    placeholder=""
                    className="w-70 form-control-plaintext shadow-none th-lt-grey px-1"
                    name="arttypeAny"
                    style={{ width: "100%" }}
                  />
                  <hr className="th-hr mb-0" style={{ width: "100%" }}></hr>
                </div>
              </div>
            )}
            <div>
              <div className="th-12 form-label">
                <b>Description</b>
              </div>
              <div>
                <input
                  value={mediaData?.description}
                  onChange={(e) => handleInputChange(e)}
                  type="text"
                  placeholder=""
                  className="w-70 input-box-EditMedia form-control-plaintext shadow-none th-lt-grey px-1"
                  name="description"
                />
                <hr className="th-hr mb-0 input-box-EditMedia"></hr>
              </div>
            </div>
            <div style={{ marginTop: "30px" }}>
              {loader ? (
                <span
                  className="spinner-grow spinner-grow-sm me-3 bg-green-custom-add-edit-media text-center"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                <span
                  className="btn_browse"
                  role="button"
                  onClick={(e) => handleSubmit(e)}
                  style={{ pointerEvents: loader ? "none" : "" }}
                >
                  Submit
                </span>
              )}
            </div>
          </div>
          <div
            className="col-md-6 d-flex justify-content-center align-items-center flex-column border rounded image-upload-section-artist-media"
            style={{ height: "330px" }}
          >
            <div>
              {allMedia.length > 0 ? (
                <div
                  className="position-relative"
                  id="img-main-container-allEditMedia"
                >
                  <Carousel
                    wrap={false}
                    interval={null}
                    activeIndex={currentMediaDisplayed}
                    keyboard={false}
                    variant="dark"
                    prevIcon={
                      <img
                        className="back-arrow-add-artwork"
                        src={LeftArrow}
                        onClick={() => {
                          //handling setState inside the UI
                          setCurrentMediaDisplayed(currentMediaDisplayed - 1);
                        }}
                      />
                    }
                    nextIcon={
                      <img
                        className="front-arrow-add-artwork"
                        src={RightArrow}
                        onClick={() => {
                          //handling setState inside the UI
                          setCurrentMediaDisplayed(currentMediaDisplayed + 1);
                        }}
                      />
                    }
                  >
                    {mediaName.map((item, index) => {
                      return (
                        <Carousel.Item key={index}>
                          <img
                            src={MinusIcon}
                            className="position-absolute delete-button-custom-add-artwork"
                            onClick={() => handleRemoveMediaFile(index)}
                          />
                          <ImageComponent id={item} />
                        </Carousel.Item>
                      );
                    })}
                  </Carousel>
                </div>
              ) : null}
            </div>

            {!uploadingMedia ? ( // will only show if a media file is not uploadin
              <div className="image_upload">
                <input
                  style={{ display: "none" }}
                  id="file-input-media"
                  type="file"
                  accept="image/*"
                  title=""
                  onChange={(e) => handleFileUploadMedia(e)}
                  multiple
                />
                <label
                  htmlFor="file-input-media"
                  className="upload-pic px-3 py-2 th-btn-primary-br-grey th-box-shadow h-white th-12 th-fw-600"
                  role="button"
                >
                  Upload Picture
                </label>
              </div>
            ) : (
              <div
                className="spinner-grow bg-red-custom-add-edit-media"
                role="status"
              >
                <span className="sr-only">Uploading...</span>
              </div>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddEditMedia;
