import React, { useEffect, useState, useCallback, useRef } from "react";
import MyLayout from "../../Components/MyLayout/index";
import "./index.scss";
import { Link, useHistory } from "react-router-dom";
import { history } from "../../..";
import firebase from "../../../firebase";
import Default from "../../Assets/img/General/default.png";
import HTMLFlipBook from "react-pageflip";
import ReactPlaceholder from "react-placeholder";
import "react-placeholder/lib/reactPlaceholder.css";
import axios, { all } from "axios";
// svgs
import PassportImg from "../../Assets/img/SVGTickets/passport.svg";
import TicketText from "../../Assets/img/SVGTickets/TicketText.svg";
import IndiraLogo from "../../Assets/img/SVGTickets/IndiraLogo.svg";
import MeraGaonLogo from "../../Assets/img/SVGTickets/MeraGaonLogo.svg";
import SwipeBox from "../../Assets/img/SVGTickets/SwipeBox.svg";
import BlackDots from "../../Assets/img/SVGTickets/BlackDots.svg";
import BarCode from "../../Assets/img/SVGTickets/BarCode.svg";
import smallTicket from "../../Assets/img/SVGTickets/smallTicket.svg";
import BackgroundLeft from "../../Assets/img/SVGTickets/BackgroundLeft.svg";
import MeraGaonTicketLogo from "../../Assets/img/SVGTickets/MeraGaonTicketLogo.svg";
import GeryDots from "../../Assets/img/SVGTickets/GreyDots.svg";
import ExploreImg from "../../Assets/img/General/explore.svg";
import RightBg from "../../Assets/img/SVGTickets/RightBg.png";

const VillageImage = ({ props }) => {
  const [imgUrl, setImgUrl] = useState("");

  const getData = async (id, type) => {
    const url = `https://asia-south1-mgmd-356213.cloudfunctions.net/getSignedURLFromCF?url=https://d296j4bbogbdom.cloudfront.net/fit-in/250x160/images/${id}`;
    let data = "";
    await axios.get(url).then(function (response) {
      data = response.data.data;
    });
    console.log(data);
    return data;
  };

  useEffect(() => {
    if (props) {
      getData(props).then((dd) => setImgUrl(dd));
    } else {
      setImgUrl(Default);
    }
    const ticket_vill_name = document.getElementById("village_ticket");
    if (ticket_vill_name) {
      ticket_vill_name.style.textTransform = "uppercase";
    }
  }, []);

  return (
    <>
      <div className="page-image">
        <img
          src={imgUrl ? imgUrl : Default}
          onError={(e) => (e.target.src = Default)}
          alt="..."
        />
      </div>
    </>
  );
};

const VillageImageAvtar = ({ props }) => {
  const [imgUrl, setImgUrl] = useState("");

  const getData = async (id, type) => {
    const url = `https://asia-south1-mgmd-356213.cloudfunctions.net/getSignedURLFromCF?url=https://d296j4bbogbdom.cloudfront.net/100x100/images/${id}`;
    let data = "";
    await axios.get(url).then(function (response) {
      data = response.data.data;
    });
    return data;
  };

  useEffect(() => {
    console.log(props);
    if (props?.thumbnail_name) {
      getData(props?.thumbnail_name).then((dd) => setImgUrl(dd));
    } else {
      setImgUrl(Default);
    }
  }, []);

  return (
    <>
      <div
        className="village_avtar_image text-center rounded-circle leader2 galaxyImg  "
        style={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "50px",
          height: "50px",
          backgroundRepeat: "no-repeat",
          marginRight: "40px",
          backgroundImage: `url(${imgUrl ? imgUrl : Default})`,
        }}
      >
        {/* <img
          className="rounded-circle"
          style={{ width: "100%", height: "100%" }}
          src={imgUrl ? imgUrl : Default}
          onError={(e) => (e.target.src = Default)}
        /> */}
      </div>
    </>
  );
};

const Page = React.forwardRef((props, ref) => {
  return (
    <div className="d-flex page_combine">
      <div className="page" ref={ref}>
        <div
          className="page-content left pass_page_image"
          style={{
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="front-page-left">
            <img
              src={BackgroundLeft}
              alt="BackgroundLeft"
              className="front-page-left"
            />
          </div>
          <div className="passport-end-page">
            <div className="logo_image d-flex flex-column justify-content-center align-items-center">
              <img className="left-dots" src={GeryDots} alt="geryDots" />
              <img className="head_logo" src={MeraGaonTicketLogo} />
              <img className="right-dots" src={GeryDots} alt="right-dots" />
            </div>
            <div className="logo_image part_two d-flex flex-column justify-content-center align-items-center">
              <div className="barcode text-center py-2">
                <img
                  className="image-at-end-pass-cover"
                  src={BarCode}
                  alt="BarCode"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page" ref={ref}>
        <div className="blackDotes">
          <img src={BlackDots} alt="blackDotes" />
        </div>
        <div
          className="page-right right pass_page_image"
          style={{
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="front_page_right">
            <img
              src={RightBg}
              alt="BackgroundRight"
              className="front-page-right"
            />
          </div>
          <div className="passport-right-box">
            <div className="fontTicket">
              <img src={TicketText} alt="TicketText" className="fontTicket" />
              <div className="UserName">
                - {JSON.parse(localStorage.getItem("userdata")).name}
              </div>
            </div>
            <div className="Logo">
              <img
                src={IndiraLogo}
                alt="IndiraLogo"
                className="national-logo"
              />
            </div>
            <div
              className="swipeBox"
              style={{
                backgroundImage: `url(${SwipeBox})`,
              }}
            >
              <div className="open-text">Flip to open</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

const InsidePage = React.forwardRef((props, ref) => {
  return (
    <div className="d-flex page_combine">
      <div className="page" ref={ref}>
        <div
          className="page-content left pass_page_image"
          style={{
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="front-page-left">
            <img
              src={BackgroundLeft}
              alt="BackgroundLeft"
              className="front-page-left"
            />
          </div>
          <div className="passport-end-page">
            <div className="logo_image d-flex flex-column justify-content-center align-items-center">
              <img className="left-dots" src={GeryDots} alt="----" />
              <img
                className="head_logo"
                src={MeraGaonTicketLogo}
                alt="MeraGaonTicketLogo"
              />
              <img className="right-dots" src={GeryDots} alt="----" />
            </div>
            <div className="logo_image part_two d-flex flex-column justify-content-center align-items-center">
              <div className="barcode text-center py-2">
                <img
                  className="image-at-end-pass-cover"
                  src={BarCode}
                  alt="BarCode"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page" ref={ref}>
        <div className="blackDotes">
          <img src={BlackDots} alt="BlackDots" />
        </div>
        <div
          className="page-right right pass_page_image"
          style={{
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="front_page_right">
            <img
              src={RightBg}
              alt="BackgroundRight"
              className="front-page-right"
            />
          </div>
          <div className="passport-right-box">
            <div className="row">
              <div className="col-md-12 attach-content d-flex justify-content-center">
                <div className="village-image d-flex justify-content-center">
                  <VillageImage props={props?.village?.thumbnail_name} />
                </div>
              </div>

              <div className="col-md-12">
                <div className="village-details-passport mx-2">
                  <div className="">
                    <span className="details-name text-center">
                      Village Name:
                    </span>
                    <span id="village_ticket">
                      {""} {props.village?.village_name}
                    </span>
                  </div>
                  {/* <div>Tehsil: </div> */}
                  {/* <div>District: {props?.village?.district_name}</div> */}
                  <div className="">
                    State:{""} {props.village?.state_name}{" "}
                  </div>
                  {/* <div>Visited On:</div>  */}
                  {/* <div className="status">Status:{""} Ticket Approved</div> */}
                </div>
              </div>
            </div>
            <div className="logo-inside">
              <img src={MeraGaonLogo} alt="MeraGaonLogo" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

const LastPage = React.forwardRef((props, ref) => {
  return (
    <div className="d-flex page_combine">
      <div className="page" ref={ref}>
        <div
          className="page-content left pass_page_image"
          style={{
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="front-page-left">
            <img
              src={BackgroundLeft}
              alt="BackgroundLeft"
              className="front-page-left"
            />
          </div>
          <div className="passport-end-page">
            <div className="logo_image d-flex flex-column justify-content-center align-items-center">
              <img className="left-dots" src={GeryDots} alt="geryDots" />
              <img className="head_logo" src={MeraGaonTicketLogo} />
              <img className="right-dots" src={GeryDots} alt="right-dots" />
            </div>
            <div className="logo_image part_two d-flex flex-column justify-content-center align-items-center">
              <div className="barcode text-center py-2">
                <img
                  className="image-at-end-pass-cover"
                  src={BarCode}
                  alt="BarCode"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page" ref={ref}>
        <div className="blackDotes">
          <img src={BlackDots} alt="blackDotes" />
        </div>
        <div
          className="page-right right pass_page_image"
          style={{
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="front_page_right">
            <img
              src={RightBg}
              alt="BackgroundRight"
              className="front-page-right"
            />
          </div>
          <div className="passport-right-box">
            <div className="fontTicket">
              <img src={TicketText} alt="TicketText" className="fontTicket" />
              <div className="UserName">
                - {JSON.parse(localStorage.getItem("userdata")).name}
              </div>
            </div>
            <div className="Logo">
              <img
                src={IndiraLogo}
                alt="IndiraLogo"
                className="national-logo"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
const UserPassport = () => {
  const [villageList, setvillageList] = useState([]);
  const [allVillageList, setallVillageList] = useState();
  const [isVillages, setIsVillages] = useState(false);
  const [loading, setLoading] = useState(false);
  const [doneFetch, setDoneFetch] = useState(false);
  const history = useHistory();
  const [flipPageWidth, setFlipPageWidth] = useState(200);
  const [flipPageHeight, setFlipPageHeight] = useState(100);
  const [newArr, setNewArr] = useState([]);
  const [seeAllData, setseeAllData] = useState(false);

  const flipBookRef = useRef(null);

  const clickOnLoad = () => {
    setseeAllData(true);
  };
  useEffect(() => {
    // setwidth
    if (window.innerWidth >= 450) {
      setFlipPageWidth(190);
    } else {
      setFlipPageWidth(100);
    }

    // set height
    if (window.innerWidth > 1276) {
      setFlipPageHeight(300);
    }
    if (window.innerWidth <= 1276 && window.innerWidth > 1025) {
      setFlipPageHeight(250);
    }
    if (window.innerWidth <= 1025 && window.innerWidth >= 1000) {
      setFlipPageHeight(250);
    }
    if (window.innerWidth < 1000 && window.innerWidth >= 450) {
      setFlipPageHeight(300);
    }
    if (window.innerWidth < 450) {
      setFlipPageHeight(150);
    }
  }, []);

  useEffect(() => {
    if (isVillages) {
      setallVillageList(villageList);
      setDoneFetch(true);
    }
  }, [isVillages]);

  useEffect(() => {
    setNewArr([]);
    if (localStorage.getItem("user")) {
      setLoading(true);
      firebase
        .firestore()
        .collection("user_data")
        .doc(
          localStorage.getItem("user") &&
            JSON.parse(localStorage.getItem("user")).uid
        )
        .get()
        .then(async (doc) => {
          if (doc.data()?.villages && doc.data().villages?.length > 0) {
            setIsVillages(false);
            let d_v = doc.data().keys;

            let count = 0;
            await doc.data().keys?.map(async (v_key, index) => {
              await firebase
                .firestore()
                .collection("large_village_detail")
                .doc(v_key)
                .get()
                .then((doc) => {
                  let data = doc.data();
                  if (data && data != undefined && data?.data) {
                    data.data.thumbnail_image_id = data?.thumbnail_image_id;
                    data.data.thumbnail_name = data?.thumbnail_name;
                    setvillageList((p) => [...p, data?.data]);

                    setNewArr((p) => [...p, data?.data].slice(0, 6));
                  }
                  if (doc) {
                    count++;
                    if (d_v?.length == count) {
                      setIsVillages(true);
                    }
                  }
                });
            });
          } else {
            setDoneFetch(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      history.push("/explore");
    }
    setLoading(false);
  }, []);

  const numberPostion = (n) => {
    return ["st", "nd", "rd"][((((n + 90) % 100) - 10) % 10) - 1] || "th";
  };

  const awesomePlaceholder = (
    <>
      <div className="d-flex col-12 my-awesome-placeholder my-2">
        <div className="col-12 mx-3">
          <div className="loadtxt">Loading....</div>
        </div>
      </div>
    </>
  );

  return (
    <>
      <MyLayout>
        <ReactPlaceholder
          ready={allVillageList?.length > 0 || doneFetch}
          customPlaceholder={awesomePlaceholder}
        >
          {villageList?.length <= 0 ? (
            <div className="w-100 ">
              <div className="col-md-12">
                <h5> Your Passports </h5>
              </div>
              <div className="col-md-12 mt-3 w-100 py-5 my-4 ">
                <div
                  style={{
                    backgroundImage: `url(${PassportImg})`,
                    backgroundPosition: "center",
                    height: "230px",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    zIndex: 1,
                  }}
                ></div>
                <div className="text-center my-4">
                  <h4 className="th-16">No Passports achieved yet.</h4>
                  <h6 className="th-12">Visit village to earn Passports.</h6>
                  <Link to="/explore">
                    <div className="mt-3 th-12">
                      <button className="th-bg-green th-white border-0 th-br-3 px-3 py-2 rounded">
                        <span>
                          <img src={ExploreImg} width="15px" alt="..." />
                        </span>
                        <span className="px-2">Explore</span>
                      </button>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="col-md-12 pb-2 pt-2"></div>
              <h5 className="d-flex justify-content-center">My Tickets</h5>
              <div className="col-md-12 th-passport-height-scroll pb-3">
                <div>
                  <div className="row pb-3 main_passport_book d-flex justify-content-center align-items-center">
                    <div
                      className=" col-lg-6 col-md-12 sub_book"
                      style={{
                        justifyContent: "start",
                        alignItems: "center",
                      }}
                    >
                      {isVillages && allVillageList && allVillageList.length ? (
                        <HTMLFlipBook
                          ref={flipBookRef}
                          width={flipPageWidth}
                          height={flipPageHeight}
                          maxShadowOpacity={0}
                          drawShadow={false}
                          style={{ pointerEvents: "none" }}
                        >
                          <Page style={{ pointerEvents: "none" }} />
                          {allVillageList
                            ?.slice(0, 50)
                            ?.map((village, index) => (
                              <InsidePage
                                className="page page-turn-animation"
                                number={index}
                                village={village}
                                key={`village_${index}`}
                                animation={false}
                                style={{ pointerEvents: "none" }}
                              />
                            ))}
                          <LastPage />
                        </HTMLFlipBook>
                      ) : (
                        ""
                      )}
                      <div className="row d-flex justify-content-center mt-2">
                        <div className="col-6 d-flex justify-content-end ">
                          <button
                            className="load-more-button my-auto mx-3"
                            onClick={() => {
                              flipBookRef.current.pageFlip().turnToPrevPage();
                            }}
                          >
                            Prev
                          </button>
                        </div>
                        <div className="col-6 d-flex justify-content-start ">
                          <button
                            className="load-more-button my-auto mx-3"
                            onClick={() => {
                              flipBookRef.current.pageFlip().turnToNextPage();
                            }}
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-12 sub_book village_list_table  ">
                      {!seeAllData
                        ? newArr.length &&
                          newArr.map((item, i) => {
                            return (
                              <div
                                key={item.key}
                                className={`row mb-2 th-bg-white th-br-12 m-0`}
                              >
                                <div className="d-flex py-2 px-1 justify-content-between align-items-center leaderboard-design ">
                                  <div className="art-one-desk d-flex align-items-center part-one-desk">
                                    <div>
                                      <div className="d-flex flex-column rank-village">
                                        <div
                                          className="th-primary th-fw-600 txtGalaxy"
                                          style={{
                                            lineHeight: "normal",
                                            fontSize: "14px",
                                            paddingLeft: "10px",
                                            paddingRight: "15px",
                                            paddingTop: "5px",
                                          }}
                                        >
                                          {i + 1}
                                          {numberPostion(i + 1)}
                                        </div>
                                      </div>
                                    </div>
                                    <VillageImageAvtar props={item} />

                                    <div className="text-left th-14 th-fw-500 table-content">
                                      <span>
                                        {" "}
                                        {item?.village_name}
                                        {window.innerWidth > 768
                                          ? ","
                                          : ""}{" "}
                                      </span>
                                      <span className="state_name">
                                        {" "}
                                        {item?.state_name}{" "}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="d-flex part-two-desk align-items-center">
                                    <div className="text-left txtGalaxy">
                                      {"MGMD Ticket"}
                                    </div>
                                    <div>
                                      <img
                                        src={smallTicket}
                                        className="imgGalaxy"
                                        style={{
                                          width: "50px",
                                          height: "50px",
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        : villageList.map((item, i) => {
                            return (
                              <div
                                key={item.key}
                                className={`row mb-2 th-bg-white th-br-12 m-0`}
                              >
                                <div className="d-flex py-2 px-1 justify-content-between align-items-center leaderboard-design ">
                                  <div className="art-one-desk d-flex align-items-center part-one-desk">
                                    <div>
                                      <div className="d-flex flex-column rank-village">
                                        <div
                                          className="th-primary th-fw-600 txtGalaxy"
                                          style={{
                                            lineHeight: "normal",
                                            fontSize: "14px",
                                            paddingLeft: "10px",
                                            paddingRight: "15px",
                                            paddingTop: "5px",
                                          }}
                                        >
                                          {i + 1}
                                          {numberPostion(i + 1)}
                                        </div>
                                      </div>
                                    </div>
                                    <VillageImageAvtar props={item} />

                                    <div className="text-left th-14 th-fw-500 table-content">
                                      <span>
                                        {" "}
                                        {item?.village_name}
                                        {window.innerWidth > 768
                                          ? ","
                                          : ""}{" "}
                                      </span>
                                      <span className="state_name">
                                        {" "}
                                        {item?.state_name}{" "}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="d-flex part-two-desk align-items-center">
                                    <div className="text-left txtGalaxy">
                                      {"MGMD Ticket"}
                                    </div>
                                    <div>
                                      <img
                                        src={smallTicket}
                                        className="imgGalaxy"
                                        style={{
                                          width: "50px",
                                          height: "50px",
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      {villageList.length > 10 && (
                        <div
                          className="d-flex justify-content-center mt-2"
                          style={{
                            marginBottom: window.innerWidth < 676 ? "50px" : "",
                          }}
                        >
                          {!seeAllData && (
                            <button
                              className="load-more-button my-auto mx-3"
                              onClick={() => clickOnLoad()}
                            >
                              {" "}
                              Load More
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </ReactPlaceholder>
      </MyLayout>
    </>
  );
};
export default UserPassport;
