import firebase from "firebase/compat/app";
import "firebase/compat/database";
import "firebase/compat/auth";
import "firebase/compat/storage";
import "firebase/compat/firestore";
import "firebase/compat/analytics";
import "firebase/compat/functions";
import "firebase/compat/app-check";
const { getFunctions } = require("firebase/functions");

const {
  initializeAppCheck,
  ReCaptchaV3Provider,
} = require("firebase/app-check");

// const config = {
//   apiKey: process.env.REACT_APP_FIRE_BASE_KEY,
//   authDomain: process.env.REACT_APP_FIRE_BASE_AUTH_DOMAIN,
//   databaseURL: process.env.REACT_APP_FIRE_BASE_DB_URL,
//   projectId: process.env.REACT_APP_FIRE_BASE_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIRE_BASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIRE_BASE_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_FIRE_BASE_APP_ID,
//   measurementId: process.env.REACT_APP_FIRE_BASE_MEASURMENT_ID,
// };

// const firebaseConfig = {
//   apiKey: "AIzaSyBXCCXK5_rOqZEAJ1JoQ3Sblxs5dNxlap0",
//   authDomain: "mgmd-a8d2c.firebaseapp.com",
//   projectId: "mgmd-a8d2c",
//   storageBucket: "mgmd-a8d2c.appspot.com",
//   messagingSenderId: "337203342194",
//   appId: "1:337203342194:web:37d3cd0545047f35dacbeb",
//   measurementId: "G-XF5X4Y8XNB",
// };

// production
// const firebaseConfig = {
//   apiKey: "AIzaSyDLHlBcQVDePIjhi_gWY-J8qs5kwGIBLJ4",
//   authDomain: "mgmd-356213.firebaseapp.com",
//   projectId: "mgmd-356213",MenuSelect
//   storageBucket: "mgmd-user-data",
//   messagingSenderId: "125919552297",
//   appId: "1:125919552297:web:51ef243bd6dcb31c180d38",
//   measurementId: "G-FY10YH5TE4",
// };

const firebaseConfig = {
  apiKey: "AIzaSyDLHlBcQVDePIjhi_gWY-J8qs5kwGIBLJ4",
  authDomain: "mgmd-356213.firebaseapp.com",
  projectId: "mgmd-356213",
  storageBucket: "mgmd-356213.appspot.com",
  messagingSenderId: "125919552297",
  appId: "1:125919552297:web:51ef243bd6dcb31c180d38",
  measurementId: "G-FY10YH5TE4",
};
const app = firebase.initializeApp(firebaseConfig);
firebase.database();
firebase.storage();
firebase.firestore();
firebase.analytics();

// if (window.location.hostname == "localhost") {
//   window.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
// }

export const functions = getFunctions(app);

// const appCheck = initializeAppCheck(app, {
//   provider: new ReCaptchaV3Provider("6Lcr6GEiAAAAADlxJb-ypTIr-OlNLxL7K-BJWsUD"),
//   isTokenAutoRefreshEnabled: true,
// });

export default firebase;
