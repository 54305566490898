import { useEffect, useState } from "react";
import MyDesktop from "../../Components/MyComponents/MyDesktop";
import MyMobile from "../../Components/MyComponents/MyMobile";
import MyLayout from "../../Components/MyLayout/index";
import firebase from "../../../../src/firebase";
import { useHistory, useParams, withRouter, Link } from "react-router-dom";
import styled from "styled-components";
import ArtPopup from "./ArtPopup";

import IndibadgesImg from "../../Assets/img/General/indibadges.svg";
import AvatarDefault from "../../Assets/img/General/AvatarDefault.jpg";
import "./index.scss";
import axios from "axios";

const ViewProfile = () => {
  const [userInfo, setUserInfo] = useState();
  const [mediaData, setMediaData] = useState();
  const [popupMedia, setPopupMedia] = useState();
  const [loading, setLoading] = useState(false);
  const { artistId } = useParams();
  const [modalShow, setModalShow] = useState(false);

  const history = useHistory();

  useEffect(() => {
    setMediaData();
    setUserInfo();
    getUserInfo();
    getMediaData();
  }, [artistId]);

  const getUserInfo = async () => {
    await firebase
      .firestore()
      .collection("users")
      .doc(artistId)
      .get()
      .then((doc) => {
        setUserInfo(doc.data());
      })
      .catch((err) => {
        console.log("err in getting user: ", err);
      });
  };

  const getMediaData = async () => {
    await firebase
      .firestore()
      .collection("artist_media")
      .get()
      .then((doc) => {
        let data = [];
        doc.docs?.map((item) => {
          if (item?.data().moderation == true) {
            data.push(item.data());
          }
        });
        data = data.sort((a, b) => b.created - a.created);

        setMediaData(data);
        setLoading(true);
      })
      .catch((err) => {
        console.log("err in getting media: ", err);
      });
  };

  const ViewCard = ({ image, description, name, type, item }) => {
    console.log(image);
    const [imageData, setImageData] = useState("");
    useEffect(() => {
      const fetchData = async () => {
        try {
          const url = `https://asia-south1-mgmd-356213.cloudfunctions.net/getSignedURLFromCF?url=https://d296j4bbogbdom.cloudfront.net/images/${image[0]}`;
          const response = await axios.get(url);
          const data = response?.data?.data;

          setImageData(data);
        } catch (error) {
          console.error("Error fetching image data:", error);
        }
      };

      fetchData();
    }, [image[0]]);
    // to disable right click context menu on profile click
    const handleContextMenu = (event) => {
      event.preventDefault();
      const img = document.getElementById("my-image");
      // to disable copy form web
      img.addEventListener("mousedown", (event) => {
        if (event.ctrlKey || event.metaKey) {
          event.preventDefault();
          // Optionally, you can display an error message or take other actions to prevent the user from accessing the image.
        }
      });
    };

    const handleImageError = () => {
      setImageData(AvatarDefault);
    };

    return (
      <MediaCardDiv
        role="button"
        className="gold th-br-12 overflow-hidden position-relative media-card-content"
        onClick={() => {
          setPopupMedia(item);
          setModalShow(true);
        }}
      >
        <div
          className="media-main-image"
          id="my-image"
          style={{
            backgroundImage: `url(${!imageData && AvatarDefault})`,
            backgroundSize: "contain",
            backgroundPosition: "top",
            height: "250px",
            width: "100%",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            backgroundColor: "#fff",
          }}
          onContextMenu={handleContextMenu}
        >
          <img
            src={imageData ? imageData : AvatarDefault}
            style={{
              width: "auto",
              height: "100%",
            }}
            onError={(e) => (e.target.src = AvatarDefault)}
          />
        </div>
        {/* <div
          style={{
            // backgroundImage: `url(${imageData})`,
            backgroundSize: "cover",
            backgroundPosition: "top",
            height: "250px",
            width: "100%",
            backgroundColor:
              imageData == AvatarDefault ? "rgb(186 186 186)" : "#fff",
          }}
          className="media-main-image"
          id="my-image"
          onContextMenu={handleContextMenu}
        ></div> */}
        <div className="th-bg-white  py-2 px-3">
          <div className="row align-items-center">
            <div className="col-12">
              <div className="name-text th-fw-600 text-truncate text-capitalize text-lowercase">
                {name ? name : ""}
              </div>
              <span
                data-toggle="tooltip"
                data-placement="top"
                title={description}
                className="Tool-tip"
              >
                <div className="th-grey-2 description-text ">
                  {description ? description : ""}
                </div>
              </span>
              <div className="name-text th-fw-600 text-truncate text-capitalize text-lowercase ">
                {type ? type : ""}
              </div>
            </div>
          </div>
        </div>
      </MediaCardDiv>
    );
  };
  return (
    <>
      <MyLayout>
        <MyDesktop>
          <div>
            <div className="container-fluid">
              <div className="row">
                <div className="col-6 justify-content-end d-flex">
                  <div>
                    <Link
                      className="load-more-button-disabled  ml-4 btn px-3 py-2 shadow shadow-sm border"
                      to={
                        localStorage.getItem("user")
                          ? "/artist-explore"
                          : "/login"
                      }
                    >
                      Artist
                    </Link>
                  </div>
                </div>
                <div className="col-6">
                  <Link
                    className="load-more-button  ml-4 btn px-3 py-2 shadow shadow-sm border"
                    to={
                      localStorage.getItem("user") ? "/art-gallery" : "/login"
                    }
                  >
                    Artist Gallery
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="view-artist-card">
            {!loading ? (
              <div className="col-md-12">
                <div className="recentLoading media">Loading....</div>
              </div>
            ) : (
              <>
                <div className="new_section m-3">
                  {!loading ? (
                    <div className="col-md-12">
                      <div className="recentLoading media">Loading....</div>
                    </div>
                  ) : (
                    <div className="row py-3 d-flex">
                      {mediaData && mediaData.length > 0 ? (
                        mediaData.map((hit, index) => (
                          <div
                            className="col-lg-3 col-md-12 pb-3 px-2 custom artistcard"
                            key={index}
                          >
                            <ViewCard
                              image={hit?.avatar}
                              description={hit?.values?.description}
                              name={hit?.values?.title}
                              type={hit?.values?.type}
                              item={hit}
                            />
                          </div>
                        ))
                      ) : (
                        <div className="row g-0 d-flex bg-white th-br-16 justify-content-center">
                          <div className="bg-white th-br-16 p-3">
                            <div className="col-md-12 col-lg-12 p-md-5 p-3 align-self-center">
                              <div className="text-center">
                                <div className="w-50 mx-auto">
                                  <img
                                    src={IndibadgesImg}
                                    className="edit-media-image"
                                    alt="media-image"
                                  />
                                </div>
                                <h4 className="pt-3 th-14">No Media found.</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </MyDesktop>
        <MyMobile>
          <div>
            <div className="container-fluid">
              <div className="row">
                <div className="col-6 justify-content-center d-flex">
                  <div>
                    <Link
                      className="load-more-button-disabled  ml-4 btn px-3 py-2 shadow shadow-sm border"
                      to={
                        localStorage.getItem("user")
                          ? "/artist-explore"
                          : "/login"
                      }
                    >
                      Artist
                    </Link>
                  </div>
                </div>
                <div className="col-6">
                  <Link
                    className="load-more-button ml-4 btn px-3 py-2 shadow shadow-sm border"
                    to={
                      localStorage.getItem("user") ? "/art-gallery" : "/login"
                    }
                  >
                    Artist Gallery
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="view-artist-card mobile-theme">
            {!loading ? (
              <div className="col-md-12">
                <div className="recentLoading media">Loading....</div>
              </div>
            ) : (
              <>
                <div className="new_section m-3">
                  {!loading ? (
                    <div className="col-md-12">
                      <div className="recentLoading media">Loading....</div>
                    </div>
                  ) : (
                    <div
                      className="row py-3 justify-content-center align-items-center d-flex"
                      style={{ marginBottom: "120px" }}
                    >
                      {mediaData && mediaData.length > 0 ? (
                        mediaData.map((hit, index) => (
                          <div
                            className="col-lg-3 col-md-12 pb-3 px-2 custom artistcard"
                            key={index}
                          >
                            <ViewCard
                              image={hit?.avatar}
                              description={hit?.values?.description}
                              name={hit?.values?.title}
                              type={hit?.values?.type}
                              item={hit}
                            />
                          </div>
                        ))
                      ) : (
                        <div className="row d-flex g-0 justify-content-center">
                          <div className="bg-white th-br-16 p-3">
                            <div className="col-md-12 col-lg-12 p-md-5 p-3 align-self-center">
                              <div className="text-center">
                                <div className=" mx-auto">
                                  <img
                                    src={IndibadgesImg}
                                    className="edit-media-image"
                                    alt="media-image"
                                  />
                                </div>
                                <h4 className="pt-3 th-14">No Media found.</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </MyMobile>
        {modalShow ? (
          <ArtPopup
            show={modalShow}
            onHide={() => setModalShow(false)}
            item={popupMedia}
            indexForImage={0}
          />
        ) : (
          false
        )}
      </MyLayout>
    </>
  );
};

const MediaCardDiv = styled.div`
  transition: all 0.15s ease;
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  }
`;
export default withRouter(ViewProfile);
