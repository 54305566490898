import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import Router from "./routes";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App/Assets/css/general.css";
import "./App/Assets/css/style.scss";
import { createBrowserHistory } from "history";
import firebase from "./firebase";

if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

const history = createBrowserHistory();

firebase.auth().onAuthStateChanged((user) => {
  ReactDOM.render(
    <BrowserRouter>
      <Router />
    </BrowserRouter>,
    document.getElementById("root")
  );

  // let userSessionTimeout = null;

  // if (user === null && userSessionTimeout) {
  //   clearTimeout(userSessionTimeout);
  //   userSessionTimeout = null;
  // } else {
  //   if (user != null)
  //     user.getIdTokenResult().then((idTokenResult) => {
  //       const authTime = idTokenResult.claims.auth_time * 1000;
  //       const sessionDurationInMilliseconds = 30 * 60 * 1000; // 30 min
  //       const expirationInMilliseconds =
  //         sessionDurationInMilliseconds - (Date.now() - authTime);
  //       userSessionTimeout = setTimeout(() => {
  //         // firebase.auth().signOut();
  //         // localStorage.removeItem("user");
  //         // window.location.href = "/login";
  //       }, expirationInMilliseconds);
  //     });
  // }
});

export { history };
