import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import MyDesktop from "../../../Components/MyComponents/MyDesktop";
import MyMobile from "../../../Components/MyComponents/MyMobile";

import Default from "../../../Assets/img/General/default.png";
import "../index.css";
import { Modal } from "react-bootstrap";
import YoutubeEmbed from "../../VillageDetails/YoutubeEmbed";
import EyeIcon from "../../../Assets/img/LoginSignupPage/eye.svg";
// import EyeIcon from "../../../Assets/img/LoginSignupPage/EyeCross.svg";

const VillageImage = ({ villageData, getData, className }) => {
  const [imgUrl, setImgUrl] = useState("");

  useEffect(() => {
    if (villageData?.thumbnaiId) {
      getData(villageData?.thumbnaiId).then((dd) => setImgUrl(dd));
    } else {
      setImgUrl(Default);
    }

  }, []);

  return (
    <>
      <div
        className={`${className}`}
        style={{
          backgroundImage: `url(${imgUrl})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          width: "100%",
          paddingBottom: "60%",
          position: "relative",
        }}
      >
        <button class="ytp-button" aria-label="Play">
          <svg height="100%" version="1.1" viewBox="0 0 68 48" width="100%">
            <path
              class="ytp-large-play-button-bg"
              d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"
              fill="#f00"
            ></path>
            <path d="M 45,24 27,14 27,34" fill="#fff"></path>
          </svg>
        </button>
      </div>
    </>
  );
};

const VillageCard = (props) => {
  const [open, setOpen] = useState(false);
  const [videoId, setVideoId] = useState("");
  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  const getData = async (id, type) => {
    const url = `https://asia-south1-mgmd-356213.cloudfunctions.net/getSignedUrl?foo=thumbnail/${id}.jpg`;
    let data = "";
    await axios.get(url).then(function (response) {
      data = response.data;
    });
    return data;
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <>
      <div>
        <MyDesktop>
          <div
            onClick={() => {
              setOpen(true);
              setVideoId(props.cover);
            }}
          >
            <VillageCardDiv
              className="gold th-br-12 overflow-hidden"
              style={{ borderRadius: "10px 10px 0px 0px" }}
            >
              <VillageImage getData={getData} villageData={props} />
            </VillageCardDiv>
          </div>
          <div
            className="th-bg-white village-card-main-container"
            style={{ borderRadius: "0px 0px 10px 10px" }}
          >
            <div className="row align-items-center">
              <div className="row">
                <div className="col-6">
                  <div className="th-12 th-fw-600 text-truncate text-capitalize text-lowercase">
                    {props?.village_name}
                  </div>
                  <div className="th-10 th-grey-2">{props?.state}</div>
                </div>
                {props?.categoryName ? (
                  <div className="col-6 position-relative">
                    <div className="th-10 th-grey-2 position-absolute category-name-village-card">
                      {props?.categoryName != undefined
                        ? props?.categoryName
                        : "Loading"}
                    </div>
                  </div>
                ) : null}

                <div
                  className="col-6 d-flex align-items-center"
                  style={{ justifyContent: "end" }}
                >
                  <a href={`/village-details/${props?.village?.village_key}`}>
                    <img height={25} src={EyeIcon} alt="View" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </MyDesktop>
        <MyMobile>
          <div
            onClick={() => {
              console.log("card clicked");
              setOpen(true);
              setVideoId(props.cover);
            }}
          >
            <div className="th-bg-white  p-1 md-2 th-box-shadow">
              <div className="align-items-center">
                <div className="col-12 px-0 ">
                  <VillageImage
                    getData={getData}
                    villageData={props}
                    className="th-bg-lt-pink th-br-5"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-12 px-2 pt-1 th-bg-white village-card-main-container"
            style={{ borderRadius: "0px 0px 10px 10px" }}
          >
            <div className="pl-2">
              <div className="row">
                <div className="col-6">
                  <div
                    className="th-20 th-fw-600 text-capitalize"
                    style={{ wordBreak: "break-word" }}
                  >
                    {props?.village_name}
                  </div>
                  <div className="th-20 th-grey-2 text-truncate  text-capitalize text-lowercase">
                    {props?.state}
                  </div>
                </div>
                {props?.categoryName ? (
                  <div className="col-6 position-relative">
                    <div className="th-20 th-grey-2 text-truncate text-capitalize text-lowercase position-absolute category-name-village-card">
                      {props?.categoryName != undefined
                        ? props?.categoryName
                        : "Loading"}
                    </div>
                  </div>
                ) : null}
                <div
                  className="col-6 d-flex align-items-center"
                  style={{ justifyContent: "end" }}
                >
                  <a href={`/village-details/${props?.village?.village_key}`}>
                    <img height={25} src={EyeIcon} alt="View" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </MyMobile>
      </div>
      <Modal className="myModal" fullscreen={true} show={open}>
        <Modal.Body>
          <div>
            <svg
              onClick={() => setOpen(false)}
              class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv"
              focusable="false"
              aria-hidden="true"
              viewBox="0 0 24 24"
              data-testid="CloseIcon"
              style={{
                width: "30px",
                fill: "white",
                cursor: "pointer",
                position: "absolute",
                transform: "translate(-50%,-50%)",
                top: "5%",
                right: "2%",
              }}
            >
              <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
            </svg>
            <div
              id="text-modal"
              style={{
                position: "absolute",
                transform: "translate(-50%,-50%)",
                top: "50%",
                left: "50%",
                width: width > 796 ? "70%" : "90%",
                height: width < 450 ? "200px" : "80%",
              }}
            >
              {/* <iframe
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "0 0 20p 20px",
                }}
                src="https://www.youtube.com/embed/-1avA4QaIoU?autoplay=1&mute=1&playsinline=1&theme=light&color=red&autohide=0&cc_load_policy=1&modestbranding=1&fs=0&showinfo=0&rel=0&iv_load_policy=3&loop=1"
                allowfullscreen
                frameBorder="0"
                sandbox="allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation"
                title="Embedded youtube"
                allow="encrypted-media"
              ></iframe> */}
              <YoutubeEmbed embedId={videoId} thumbnail={""} />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default VillageCard;

const VillageCardDiv = styled.div`
  transition: all 0.15s ease;
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  }
`;
