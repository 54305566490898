import React, { Component } from "react";

class ArtSelect extends Component {
    onChange = (event) => {
        const value = event.target.value;;
        this.props.onChangeFun_(value, this.props.f_name);
    };

    render() {
        const { passedDownItems, selectedValue, f_name, localValue } = this.props;

        const sortedItems = passedDownItems?.sort(function (a, b) {
            let x = a.value.toLowerCase();
            let y = b.value.toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0;
        });

        return (
            <select
                className={`menu-select ${localValue ? this.props.className : "state_filter_artist_notSelected"}`}
                data-live-search="true"
                onChange={(e) => {
                    this.onChange(e);
                }}
                style={{ marginRight: "5px" }}
                value={localValue ? localValue : selectedValue}
            >
                <option value="select">Select</option>
                {passedDownItems?.map((item, index) => (
                    <option key={index} value={item.value}>
                        {item.value}
                    </option>
                ))}
            </select>
        );
    }
}

export default ArtSelect;
