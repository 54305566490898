import React, { Component } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import MyLayout from "../../Components/MyLayout/index";
import MyMobile from "../../Components/MyComponents/MyMobile";
import MyDesktop from "../../Components/MyComponents/MyDesktop";

import ArrowRight from "../../Assets/img/icons/arrow.svg";
import landing1 from "../../Assets/img/background/landing1.jpg";
import landing2 from "../../Assets/img/background/landing2.jpg";
import landing3 from "../../Assets/img/background/landing3.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PageContainer from "../../Components/Container";

const SliderData = [
  {
    image: landing1,
    captionText: "A PLATFORM THAT LETS YOU TRAVEL",
    line1: "32.87 Lakh Sq",
    line2: "Km Virtually",
  },
  {
    image: landing2,
    captionText: "EXPLORE AND NAVIGATE THROUGH ",
    line1: "Villages Of",
    line2: " Your Choice",
  },
  {
    image: landing3,
    captionText: "COMPLETE YOUR JOURNEY TO",
    line1: "Earn Exciting",
    line2: " Rewards",
  },
];

class Onboarding extends Component {
  state = {
    activeSlide: 0,
  };

  componentDidMount() {
    //forced rendering to tackle image flickering
    SliderData.forEach((picture) => {
      const img = new Image();
      img.src = picture.image;
    });
  }

  render() {
    const { t } = this.props;

    const settings = {
      dots: false,
      vertical: true,
      fade: true,
      arrows: false,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 7000,
      beforeChange: (current, next) => this.setState({ activeSlide: next }),
      pauseOnHover: false,
      useTransform: false,
    };

    return (
      <PageContainer
        title="Explore India's Village Heritage with MGMD | Cultural Mapping Platform"
        description="Discover India's rich village heritage with MGMD. Our platform offers comprehensive cultural mapping and detailed insights into the traditions, art, and culture of villages across India. Explore today!"
        keywords="Gaon Dharohar, Village Mapping, India's Cultural Heritage"
        canonical="https://mgmd.gov.in/"
      >
        <MyLayout
          sideheader={false}
          transparent={true}
          bg={SliderData[this.state.activeSlide].image}
          bg_m={SliderData[this.state.activeSlide].image}
          footer={false}
          hideScroll={true}
        >
          <div className="container">
            <Slider {...settings}>
              {SliderData.map((item, index) => (
                <div key={index}>
                  <div className="container">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="pt-5 mt-5 th-green pb-2">
                          <div className="row px-3 d-flex">
                            <div className="col-md-6">
                              <div
                                className="th-60 th-fw-700 th-white th-fair-play"
                                style={{ width: "100vw", marginBottom: "1rem" }}
                              >
                                Mera Gaon Meri Dharohar
                              </div>
                              <GreenLine className="my-auto" />
                              <div className="text-uppercase th-fw-600 th-14 pl-3 text-nowrap th-white ">
                                {item.captionText}
                              </div>
                            </div>
                          </div>
                        </div>
                        <MyDesktop>
                          <div className="th-40 th-fw-700 th-white th-fair-play">
                            {item.line1}
                            <br />
                            {item.line2}
                          </div>
                          <div className="py-3">
                            <div className="row px-3">
                              <Link to="/explore" style={{ width: "auto" }}>
                                <div
                                  className="d-flex th-btn-green py-2 px-3 th-14 th-pointer text-nowrap"
                                  style={{ width: "150px" }}
                                >
                                  <div className="my-auto th-white">
                                    Get Started
                                  </div>

                                  <img
                                    className="px-2 my-auto"
                                    src={ArrowRight}
                                    height="15px"
                                    alt=">"
                                  />
                                </div>
                              </Link>
                            </div>
                          </div>
                        </MyDesktop>
                        <MyMobile>
                          <div className="th-42 th-fw-700 th-white th-fair-play">
                            {item.line1}
                            <br />
                            {item.line2}
                          </div>
                          <div className="py-3">
                            <div className="row px-3">
                              <Link to="/explore" style={{ width: "auto" }}>
                                <div
                                  className="d-flex th-btn-green py-2 px-3 th-14 th-pointer text-nowrap"
                                  style={{ width: "130px" }}
                                >
                                  <div className="my-auto th-white">
                                    Get Started
                                  </div>

                                  <img
                                    className="px-2 my-auto"
                                    src={ArrowRight}
                                    height="15px"
                                    alt=">"
                                  />
                                </div>
                              </Link>
                            </div>
                          </div>
                        </MyMobile>
                        <div className="py-3"></div>
                      </div>
                      <div className="col-md-6"></div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </MyLayout>
      </PageContainer>
    );
  }
}

export default Onboarding;

const GreenLine = styled.div`
  border: solid 1px #92e3a9;
  height: 0;
  width: 48px;
`;
