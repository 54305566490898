import React from "react";
import { useState, useEffect, useRef } from "react";
import { Alert } from "react-bootstrap";
import MyDesktop from "../../Components/MyComponents/MyDesktop";
import MyMobile from "../../Components/MyComponents/MyMobile";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import firebase from "../../../firebase";

import Upload from "../../Assets/img/icons/Upload.svg";
import "./index.css";

import AWS from "aws-sdk";

function Index(props) {
  const modalCaptionRef = useRef(null);
  const handleClose = () => setShow(false);
  const [show, setShow] = useState(false);
  const [modalCaption, setModalCaption] = useState("");
  const [allpictures, setAllpictures] = useState([]);
  const [village_key, setVillageKey] = useState("");
  const [village_name, setVillageName] = useState("");
  const [blockName, setBlockName] = useState("")
  const [districtName, setDistrictName] = useState("")
  const [state_name, setStateName] = useState("");
  const [jsonField, setJsonField] = useState("");
  const [mediaIndex, setMediaIndex] = useState(null);
  const [title, setTitle] = useState("");
  const [caption, setCaption] = useState("");
  const [alert, setAlert] = useState("");
  const [progress, setProgress] = useState(0);
  const filepick = useRef(null);
  const [file, setFile] = useState(null);
  const [extension, setExtension] = useState(null);
  const [allEditInput, setAllEditInput] = useState();
  const [pictureUpdate, setpictureUpdate] = useState(false);

  const s3 = new AWS.S3();
  AWS.config.update({
    accessKeyId: "AKIA6JXYGZ42T6UQGT4S",
    secretAccessKey: "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",
    region: "ap-south-1",
  });
  useEffect(() => {
    setVillageKey(props.keyy);
    setJsonField(props.jsonField);
    if (props.keyy) {
      firebase
        .firestore()
        .collection("large_village_detail")
        .doc(props.keyy)
        .get()
        .then((doc) => {
          const dat = doc.data()?.data[props?.jsonField];
          if (dat != undefined && dat != null) {
            if (Array.isArray(dat)) {
              const pictures = [];
              dat.forEach(
                (element) =>
                  element.media != null && pictures.push(...element.media)
              );
              setAllpictures(pictures);
            } else {
              if (dat.media != null) setAllpictures(dat.media);
            }
          }
        });
    }
  }, [props.jsonField, props.show]);

  useEffect(() => {
    if (props.keyy) {
      firebase
        .firestore()
        .collection("large_village_detail")
        .doc(props.keyy)
        .get()
        .then((doc) => {
          setVillageName(doc.data()?.data.village_name);
          setStateName(doc.data()?.data.state_name);
          setBlockName(doc.data()?.data.block_name);
          setDistrictName(doc.data()?.data.district_name)
        });
    }
  }, []);

  const handleClearFile = () => {
    setFile(null);
    filepick.current.value = null;
  };

  useEffect(() => {
    if (props?.update) {
      setAllEditInput({ caption: props.item?.caption });
    }
  }, [props?.update]);

  const allEditInputFunc = (e) => {
    let val = e.target.value;
    setAllEditInput((p) => ({ ...p, caption: val }));
  };

  const handleSubmit = () => {
    const fileName = `${Date.now()}.${extension}`;
    if (props?.update) {
      setCaption(allEditInput.caption);
    }
    if (file != null && caption !== "") {
      const params = {
        Bucket: "static.mgmd.gov.in",
        Key: `images/${fileName}`,
        Body: file,
        ContentType: "image/jpg",
      };

      s3.upload(params, (err, data) => {
        if (err) {
          console.error(err);
          // reject(err);
        } else {
          console.log("Image uploaded successfully:");

          setProgress(50);

          let data = {};
          if (jsonField == "culture_profile") {
            data = {
              village_key: village_key,
              village_name: village_name,
              block_name: blockName,
              district_name: districtName,
              type: "image_upload",
              json_field: jsonField,
              timestamp: Date.now(),
              media: [
                {
                  title: title,
                  media_name: fileName,
                  caption: caption,
                },
              ],
              state_name: state_name,
              created_by: JSON.parse(localStorage.getItem("user"))?.displayName,
              status: "no action",
            };
          } else {
            let i_idx = 0;
            let m_idx = 0;
            props?.topicsData?.[props?.selectedTopic]?.map((itm, m_ind) => {
              if (itm?.media && itm?.media?.map((i) => i && i?.id)) {
                if (
                  itm?.media &&
                  itm?.media
                    ?.map((i) => i && i?.id)
                    ?.indexOf(props?.item?.id) >= 0
                ) {
                  i_idx = itm?.media
                    ?.map((i) => i && i?.id)
                    ?.indexOf(props?.item?.id);
                  m_idx = m_ind;
                }
              }
            });
            data = {
              village_key: village_key,
              village_name: village_name,
              block_name: blockName,
              district_name: districtName,
              type: "image_upload",
              json_field: jsonField,
              timestamp: Date.now(),
              media: [
                {
                  title: title,
                  media_name: fileName,
                  caption: caption,
                },
              ],
              main_index: m_idx,
              // image_index: i_idx,
              state_name: state_name,
              created_by: JSON.parse(localStorage.getItem("user"))?.displayName,
              status: "no action",
            };
          }
          setProgress(70);
          firebase
            .firestore()
            .collection("large_village_suggest_edit")
            .add(data)
            .then(() => {
              setProgress(100);
              setTimeout(() => {
                setProgress(0);
                setAlert("success");
                setTimeout(() => {
                  setAlert("");
                  setFile(null);
                  filepick.current.value = null;
                  setTitle("");
                  setCaption("");
                  handleClose();
                  props.handleClose();
                }, 4000);
              }, 1000);
            })
            .catch((err) => {
              console.log(err);
              setAlert("danger");
              setTimeout(() => {
                setAlert("");
              }, 4000);
            });
        }
      });
    } else {
      setAlert("field_error");
      setTimeout(() => {
        setAlert("");
      }, 4000);
    }
  };

  const onFileChange = () => {
    const file = filepick.current.files[0];
    setExtension(file.name.split(".").pop());
    setFile(file);
    if (file != null) {
      setpictureUpdate(true);
      setTimeout(() => {
        setpictureUpdate(false);
      }, 2500);
    }
  };
  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleCaptionChange = (e) => {
    setCaption(e.target.value);
  };

  const handleCaptionEdit = () => {
    const data = {
      caption: modalCaptionRef.current.value,
      timestamp: Date.now(),
      type: "caption_edit",
      imageIndex: mediaIndex || "noId",
      json_field: jsonField,
      village_key: village_key,
    };
    firebase
      .firestore()
      .collection("large_village_suggest_edit")
      .add(data)
      .then((response) => {
        setShow(false);
        setAlert("success");
        setTimeout(() => {
          setAlert("");
          handleClose();
          props.handleClose();
        }, 4000);
      });
  };

  const handleModalCaptionChange = () => {
    setModalCaption(modalCaptionRef.current.value);
  };

  const onFilePickup = () => {
    filepick.current.click();

    if (file != null && file.name) {
      setpictureUpdate(true);
      setTimeout(() => {
        setpictureUpdate(false);
      }, 2500);
    }
  };

  const updatePopupHandle = (device) => {
    return (
      <>
        <Modal.Body>
          <div className="th-leaderboard-height-scroll">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  {device == "mobile" ? (
                    <img
                      style={{
                        width: "300px",
                        height: "200px",
                      }}
                      src={props?.item?.cdn_path}
                      alt="item"
                    />
                  ) : (
                    <img
                      style={{
                        width: "600px",
                        height: "300px",
                      }}
                      src={props?.item?.cdn_path}
                      alt="item"
                    />
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  {alert == "success" && (
                    <div className="alert alert-success" role="alert">
                      Thank you for your Edit.. We will review it and update it
                      soon..
                    </div>
                  )}
                  {alert == "danger" && (
                    <div className="alert alert-danger" role="alert">
                      Error Updating Your Edit.. Please try again..
                    </div>
                  )}
                  {alert == "field_error" && (
                    <div className="alert alert-danger" role="alert">
                      Please Fill All Fields...
                    </div>
                  )}
                  {progress != 0 && (
                    <div className="progress">
                      <div
                        className="progress-bar progress-bar-striped progress-bar-animated"
                        role="progressbar"
                        aria-valuenow={progress}
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ width: progress + "%" }}
                      ></div>
                    </div>
                  )}
                </div>
              </div>
              <div className="row">
                <div
                  className="col-12 text-center my-2 mx-2"
                  style={{ fontSize: "14px" }}
                >
                  {file != null && file.name}
                  {file != null && (
                    <span
                      className="mx-4"
                      role="button"
                      onClick={handleClearFile}
                    >
                      X
                    </span>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-12 text-center">
                  <div>1.select files from local device to upload</div>
                  <div className="my-4">
                    {" "}
                    <input
                      accept="image/*"
                      type="file"
                      name="file"
                      id=""
                      ref={filepick}
                      onChange={onFileChange}
                      hidden
                    />{" "}
                    <span
                      onClick={onFilePickup}
                      className="upload my-5"
                      role="button"
                    >
                      Select Files{" "}
                      <span className="m-2">
                        <img
                          src={Upload}
                          style={{
                            position: "absolute",
                            width: "30px",
                            height: "30px",
                            marginTop: "-3px",
                          }}
                        />
                      </span>{" "}
                    </span>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label htmlFor="exampleFormControlTextarea1">
                      2. Image Title
                    </label>
                    <textarea
                      className="form-control"
                      onChange={handleTitleChange}
                      id="exampleFormControlTextarea1"
                      rows="2"
                    ></textarea>
                  </div>
                  <div className="form-group my-2">
                    <label htmlFor="exampleFormControlTextarea1">
                      3. Image Caption
                    </label>
                    <textarea
                      value={allEditInput?.caption}
                      onChange={(e) => allEditInputFunc(e)}
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows="2"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="row my-2">
                <div className="col-12 text-center">
                  {progress != 0 ? (
                    <div
                      className="btn disabled"
                      style={{ backgroundColor: "rgb(117, 207, 143)" }}
                    >
                      Please Wait..
                    </div>
                  ) : (
                    <div
                      className="btn"
                      onClick={handleSubmit}
                      style={{ backgroundColor: "rgb(117, 207, 143)" }}
                    >
                      Submit for review
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </>
    );
  };

  return (
    <>
      <MyDesktop>
        {show && (
          <Modal dialogClassName="modal-width" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Edit Caption</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <textarea
                className="form-control"
                value={modalCaption}
                id="caption"
                rows="3"
                ref={modalCaptionRef}
                onChange={handleModalCaptionChange}
              ></textarea>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleCaptionEdit}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
        )}
        <Modal
          show={props.show}
          size="lg"
          onHide={props.handleClose}
          backdrop="static"
          keyboard={false}
          className="image-edit-model mx-auto"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          {props?.update ? (
            <div>{updatePopupHandle("desktop")}</div>
          ) : (
            <Modal.Body>
              <div>
                <div className="container">
                  <div className="row">
                    <div className="col-md-12 text-center"> </div>
                  </div>
                  <div className="row">
                    <div>
                      {pictureUpdate && (
                        <Alert
                          key={"success"}
                          variant={"success"}
                          className="mt-3 d-flex text-align-center justify-content-center"
                        >
                          Picture Uploaded Successfully
                        </Alert>
                      )}
                      {alert == "success" && (
                        <div className="alert alert-success" role="alert">
                          Thank you for your Edit.. We will review it and update
                          it soon..
                        </div>
                      )}
                      {alert == "danger" && (
                        <div
                          className="alert alert-danger d-flex text-align-center justify-content-center"
                          role="alert"
                        >
                          Error Updating Your Edit.. Please try again..
                        </div>
                      )}
                      {alert == "field_error" && (
                        <div
                          className="alert alert-danger d-flex text-align-center justify-content-center"
                          role="alert"
                        >
                          Please Fill All Fields...
                        </div>
                      )}
                      {progress != 0 && (
                        <div className="progress">
                          <div
                            className="progress-bar progress-bar-striped progress-bar-animated"
                            role="progressbar"
                            aria-valuenow={progress}
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{ width: progress + "%" }}
                          ></div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 text-center my-2 ">
                      {file != null && file.name}
                      {file != null && (
                        <span
                          className="mx-2 text-secondary"
                          role="button"
                          onClick={handleClearFile}
                        >
                          X
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 text-center">
                      <div>
                        1.select files from local device to upload
                        <span className="text-danger mx-1">*</span>
                      </div>
                      <div className="my-4">
                        {" "}
                        <input
                          accept="image/*"
                          type="file"
                          name="file"
                          id=""
                          ref={filepick}
                          onChange={onFileChange}
                          hidden
                        />{" "}
                        <span onClick={onFilePickup} className="upload my-5">
                          Select Files{" "}
                          <span
                            className="m-2"
                            style={{ paddingRight: "10px" }}
                          >
                            <img
                              src={Upload}
                              style={{
                                position: "absolute",
                                width: "30px",
                                height: "30px",
                                marginTop: "-3px",
                              }}
                            />
                          </span>{" "}
                        </span>
                      </div>
                    </div>
                    <div className="col-10 m-auto">
                      <div className="form-group">
                        <label htmlFor="exampleFormControlTextarea1">
                          2. Image Title
                        </label>
                        <textarea
                          className="form-control"
                          onChange={handleTitleChange}
                          id="exampleFormControlTextarea1"
                          rows="3"
                        ></textarea>
                      </div>
                      <div className="col-12 m-auto form-group my-2">
                        <label htmlFor="exampleFormControlTextarea1">
                          3. Image Caption
                        </label>
                        <span className="text-danger mx-1">*</span>
                        <textarea
                          onChange={handleCaptionChange}
                          className="form-control"
                          id="exampleFormControlTextarea1"
                          rows="3"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="row my-4">
                    <div className="col-12 text-center">
                      {progress != 0 ? (
                        <div
                          className="btn  disabled"
                          style={{ backgroundColor: "rgb(117, 207, 143)" }}
                        >
                          Please Wait..
                        </div>
                      ) : (
                        <div
                          className="btn"
                          style={{ backgroundColor: "rgb(117, 207, 143)" }}
                          onClick={handleSubmit}
                        >
                          Submit for review
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          )}
        </Modal>
      </MyDesktop>

      <MyMobile>
        {show && (
          <Modal
            dialogClassName="modal-width mobile-theme"
            show={show}
            onHide={handleClose}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Edit Caption</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <textarea
                className="form-control"
                value={modalCaption}
                id="caption"
                rows="3"
                ref={modalCaptionRef}
                onChange={handleModalCaptionChange}
              ></textarea>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleCaptionEdit}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
        )}

        <Modal
          show={props.show}
          onHide={props.handleClose}
          backdrop="static"
          keyboard={false}
          className="image-edit-model mx-auto"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          {props?.update ? (
            <div>{updatePopupHandle("mobile")}</div>
          ) : (
            <Modal.Body>
              <div className="th-leaderboard-height-scroll">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12 text-center"> </div>
                  </div>
                  <div className="row">
                    <div>
                      {pictureUpdate && (
                        <Alert
                          key={"success"}
                          variant={"success"}
                          className="mt-3"
                        >
                          Picture Uploaded Successfully
                        </Alert>
                      )}
                      {alert == "success" && (
                        <div
                          className="alert alert-success d-flex text-align-center justify-content-center"
                          role="alert"
                        >
                          Thank you for your Edit.. We will review it and update
                          it soon..
                        </div>
                      )}
                      {alert == "danger" && (
                        <div
                          className="alert alert-danger d-flex text-align-center justify-content-center"
                          role="alert"
                        >
                          Error Updating Your Edit.. Please try again..
                        </div>
                      )}
                      {alert == "field_error" && (
                        <div
                          className="alert alert-danger d-flex text-align-center justify-content-center"
                          role="alert"
                        >
                          Please Fill All Fields...
                        </div>
                      )}
                      {progress != 0 && (
                        <div className="progress">
                          <div
                            className="progress-bar progress-bar-striped progress-bar-animated"
                            role="progressbar"
                            aria-valuenow={progress}
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{ width: progress + "%" }}
                          ></div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className="col-12 text-center my-2 mx-2"
                      style={{ fontSize: "14px" }}
                    >
                      {file != null && file.name}
                      {file != null && (
                        <span
                          className="mx-2 text-secondary"
                          role="button"
                          onClick={handleClearFile}
                        >
                          X
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 text-center">
                      <div>
                        1.select files from local device to upload
                        <span className="text-danger mx-1">*</span>
                      </div>
                      <div className="my-4">
                        {" "}
                        <input
                          accept="image/*"
                          type="file"
                          name="file"
                          id=""
                          ref={filepick}
                          onChange={onFileChange}
                          hidden
                        />{" "}
                        <span
                          onClick={onFilePickup}
                          className="upload my-3 select-file"
                        >
                          <span>Select Files</span>
                          <span className="m-2">
                            <img
                              src={Upload}
                              style={{
                                position: "absolute",
                                width: "30px",
                                height: "30px",
                              }}
                            />
                          </span>{" "}
                        </span>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="exampleFormControlTextarea1">
                          2. Image Title
                        </label>
                        <textarea
                          className="form-control"
                          onChange={handleTitleChange}
                          id="exampleFormControlTextarea1"
                          rows="2"
                        ></textarea>
                      </div>
                      <div className="col-12 form-group">
                        <label htmlFor="exampleFormControlTextarea1">
                          3. Image Caption
                        </label>
                        <span className="text-danger mx-1">*</span>
                        <textarea
                          onChange={handleCaptionChange}
                          className="form-control"
                          id="exampleFormControlTextarea1"
                          rows="2"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="row my-4">
                    <div className="col-12 text-center">
                      {progress != 0 ? (
                        <div
                          className="btn  disabled"
                          style={{ backgroundColor: "rgb(117, 207, 143)" }}
                        >
                          Please Wait..
                        </div>
                      ) : (
                        <div
                          className="btn  "
                          style={{ backgroundColor: "rgb(117, 207, 143)" }}
                          onClick={handleSubmit}
                        >
                          Submit for review
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          )}
        </Modal>
      </MyMobile>
    </>
  );
}

export default Index;
